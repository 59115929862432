import React, { useEffect, useState, Fragment, useCallback } from 'react'
import Banner from '../../components/Banner'
import { get } from '../../helpers/requests'
import News from '../../components/News/News'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import TagsSelector from '../../components/TagsSelector/TagsSelector'
import { dateMonths } from '../../helpers/date'
import useDebounce from '../../hooks/UseDebounce'
import { useIsMobile, useIsTablet, useIsDesktop } from '../../hooks'
import './style.scss'

const NewsPage = () => {
  const { site_news_id } = useParams()
  const [news, setNews] = useState()
  const [newsList, setNewsList] = useState()
  const [filters, setFilters] = useState()
  const { pathname } = useLocation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const refreshNews = useCallback(selectedFilters => {
    get('/news', selectedFilters)
      .then(setNewsList)
      .catch(error => {
        // TODO: handle this aswell
      })
  }, [setNewsList])

  const [selectedFilters, setSelectedFilters, flush] = useDebounce(refreshNews, 1500, { tag: '-', month: '-', page: 0, limit: 6 })

  const handleTagChange = useCallback(tag => setSelectedFilters({ ...selectedFilters, tag }), [setSelectedFilters, selectedFilters])
  const handleMonthChange = useCallback(month => setSelectedFilters({ ...selectedFilters, month }), [setSelectedFilters, selectedFilters])

  const ANew = ({ aNew, preview }) => {
    return <News
      id={aNew.site_news_id}
      title={aNew.title}
      content={aNew.content}
      coverImage={aNew.cover_image}
      tags={aNew.tags}
      created={aNew.created}
      preview={preview}
    />
  }

  useEffect(() => {
    if (site_news_id) {
      get('/news/' + site_news_id)
        .then(setNews)
        .catch(error => {
          // TODO: handle error feedback
        })
    }
  }, [site_news_id])

  useEffect(() => {
    get('/news/filters')
      .then(filters => ({ tags: filters.tags, dates: filters.dates.map(date => ({ name: dateMonths(date), value: date })) }))
      .then(setFilters)
      .then(() => flush())
      .catch(error => {
        // TODO: handle error feedback
      })
  }, [flush])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className="news-page">
      <Banner type="home" className="rf-content-spacing" />
      <div className="rf-content-spacing rp-home-header">
        {news &&
          <div className="-news-container">
            <ANew aNew={news} />
          </div>
        }
        <div className="-news-browser">
          {(isMobile || isTablet) && <div className="-header">Noticias anteriores</div>}
          <div className="-filters">
            {filters &&
              <Fragment>
                <div>
                  <div className="-title">Filtra por categoría</div>
                  <TagsSelector tags={filters.tags} initialValue='-' allLabel='Todas' allValue='-' onChange={handleTagChange} />
                </div>
                <div>
                  <div className="-title">Filtra por mes</div>
                  <TagsSelector tags={filters.dates} initialValue='-' allLabel='Todos' allValue='-' onChange={handleMonthChange} />
                </div>
              </Fragment>
            }
          </div>
          <div className="-news-previews">
            {isDesktop && <div className="-header">Noticias anteriores</div>}
            <div className="-grid">
              {newsList && newsList.map(news =>
                <ANew key={news.site_news_id} aNew={news} preview />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(NewsPage)
import { useSelector } from 'react-redux'

const useSeasonBestPlayers = (season_id, only_ids = false) => {
  const players = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].player_fans_stats)
  if (!players) return []

  const playerList = Object.values(players)
  if (playerList.length === 0) return []
  if (playerList[0].place === playerList[playerList.length - 1].place) return []

  const bestPlayers = []
  let index = 0
  while (playerList[index].place === 1) {
    bestPlayers.push(only_ids ? playerList[index].playerId : playerList[index])
    index++
  }

  return bestPlayers
}

export default useSeasonBestPlayers

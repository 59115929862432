import React from 'react'
import { Link } from 'react-scroll'
import './style.css'

const VerticalMenu = ({ options, dark = false }) => {
  return (
    <div className="rf-verticalmenu" data-dark={dark}>
      {Object.keys(options).map((option, optionKey) => (
        <Link key={optionKey} to={option} spy={true} smooth={true} offset={-30} activeClass="-active" className="rf-verticalmenu-element">
          {options[option]}
        </Link>
      ))}
    </div>
  )
}

export default VerticalMenu

import React, { Fragment } from 'react'
import classNames from 'classnames'
import { EventTypes } from '../../helpers/constants'
import { useIsTablet, useIsDesktop, useSeasonPlayer } from '../../hooks'
import icons from '../../icons'
import './style.css'

const GameEventsTimeline = ({ season_id, game }) => {
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const renderPlayerName = (player) => {
    if (!player) return ""
    return player.firstname + ' ' + player.lastname
  }

  const renderMinute = (event) => {
    return (event.minute + event.minute_extra) + "'"
  }

  const renderAction = (event, visitor, player, playerChange) => {
    switch (event.type) {
      case EventTypes.GOAL: {
        return (
          <div className={classNames("game-events-action", { "-visitor": visitor })}>
            <div className="-action -highligth">
              ¡Gooool!
              <span>{event.scoredTime}</span>
            </div>
            <div className="-name">
              { (isTablet || isDesktop) && <span>Anotado por&nbsp;</span> }
              { renderPlayerName(player) }
              </div>
          </div>
        )
      }
      case EventTypes.YELLOW_CARD: {
        return (
          <div className={classNames("game-events-action", { "-visitor": visitor })}>
            <div className="-action">Amonestación</div>
            <div className="-name">{ renderPlayerName(player) }</div>
          </div>
        )
      }
      case EventTypes.RED_CARD: {
        return (
          <div className={classNames("game-events-action", { "-visitor": visitor })}>
            <div className="-action">Expulsión</div>
            <div className="-name">{ renderPlayerName(player) }</div>
          </div>
        )
      }
      case EventTypes.SUBSTITUTION: {
        return (
          <div className={classNames("game-events-action", { "-visitor": visitor })}>
            <div className="-action">Sustitución</div>
            <div className="-name">{ (isTablet || isDesktop) && "Sale" }&nbsp;{ renderPlayerName(player) }<br/>{ (isTablet || isDesktop) && "Entra"}&nbsp;{ renderPlayerName(playerChange) }</div>
          </div>
        )
      }
      default:
        return null
    }
  }

  const renderActionIcon = (event) => {
    switch (event.type) {
      case EventTypes.GOAL: {
        return <div className="-icon"><icons.Goal /></div>
      }
      case EventTypes.YELLOW_CARD: {
        return <div className="-icon"><icons.Yellow /></div>
      }
      case EventTypes.RED_CARD: {
        return <div className="-icon"><icons.Red /></div>
      }
      case EventTypes.SUBSTITUTION: {
        return <div className="-icon"><icons.Sustitution /></div>
      }
      default:
        return null
    }
  }

  const renderTimeType = (category) => {
    switch (parseInt(category)) {
      case 0: {
        return "INICIO"
      }
      case 1: {
        return "PRIMER TIEMPO EXTRA"
      }
      case 2: {
        return "SEGUNDO TIEMPO EXTRA"
      }
      case 3: {
        return "PENALES"
      }  
      default:
        return null
    }
  }

  const Event = ({ event }) => {
    const player = useSeasonPlayer(season_id, event.player_id)
    const playerChange = useSeasonPlayer(season_id, event.player_in_id)
    const visitor = player.teamId !== game.home_team_id
    return (
      <div className="game-events-row">
        <div className="-action">
          { visitor ? <div className="game-events-action" /> : renderAction(event, visitor, player, playerChange) }
          { visitor ? <div className="-icon" /> : renderActionIcon(event) }
        </div>
        <div className="game-event-timeline -timeline">
          <div className="-line"></div>
          <div className="-minute">{renderMinute(event)}</div>
        </div>
        <div className="-action -right">
          { visitor ? renderActionIcon(event) : <div className="-icon" /> }
          { visitor ? renderAction(event, visitor, player, playerChange) : <div className="game-events-action" /> }
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      { game && game.timeline &&
        <div className="game-events-timeline">
          <div className="-label">Min x Min</div>
          <div className="game-events-category"><div className="-milestone">Final</div></div>
          { Object.keys(game.timeline).reverse().map((timeType) => {
            return (
              <div key={timeType} className="game-events-category">
                <div className="game-events-row">
                  <div></div>
                  <div className="game-event-timeline -timeline -space"><div className="-line"></div></div>
                  <div></div>
                </div>
                { game.timeline[timeType].reverse().map((event, index) => {
                  return <Event key={index} event={event} />
                }) }
                <div className="-milestone">{ renderTimeType(timeType) }</div>
              </div>
            )
          }) }
        </div>
      }
    </Fragment>
  )
}

export default GameEventsTimeline
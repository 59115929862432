import './style.css'

import classNames from 'classnames'
import React, { Fragment, useState } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import Image from '../Image'

const Avatar = ({ src, initials }) => {
  const [avatarSize, setAvatarSize] = useState('-xxxl');
  const [currentInitials, setCurrentInitials] = useState(initials || '');
  return (
    <Fragment>
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={width => {
          if (width >= 123) {
            setAvatarSize('-xxxl');
            setCurrentInitials(initials.substring(0, 2));
          } else if (width >= 75) {
            setAvatarSize('-xxl');
            setCurrentInitials(initials.substring(0, 2));
          } else if (width >= 59) {
            setAvatarSize('-xl');
            setCurrentInitials(initials.substring(0, 2));
          } else if (width >= 51) {
            setAvatarSize('-l');
            setCurrentInitials(initials.substring(0, 2));
          } else if (width >= 35) {
            setAvatarSize('-m');
            setCurrentInitials(initials.substring(0, 2));
          } else if (width >= 27) {
            setAvatarSize('-s');
            setCurrentInitials(initials.substring(0, 2));
          } else if (width >= 19) {
            setAvatarSize('-xs');
            setCurrentInitials(initials.substring(0, 1));
          } else if (width >= 10) {
            setAvatarSize('-xxs');
            setCurrentInitials(initials.substring(0, 1));
          }
        }}
      >
        {() => (
          <div className={'avatar-container'}>
            <Image
              className="avatar-image"
              src={src}
              defaultImage={
                <div className={classNames('avatar-image', '-initials', avatarSize)}>{currentInitials}</div>
              }
            />
          </div>
        )}
      </ReactResizeDetector>
    </Fragment>
  );
};

export default Avatar;

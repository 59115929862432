import './style.css'

import React, { Fragment, useEffect, useState } from 'react'

import GameEventsTable from '../GameEventsTable'
import GameEventsTimeline from '../GameEventsTimeline'
import GameGoalsSummary from '../GameGoalsSummary'
import MatchCard from '../MatchCard'
import Modal from '../Modal'
import { get } from '../../helpers/requests'

const GameDetailsModal = ({ season_id, game_id, journey, isOpen, onRequestClose }) => {
  const [game, setGame] = useState()

  useEffect(() => {
    if (game_id) {
      get('/old_games/' + game_id)
        .then(setGame)
        .catch(error => {
          console.error(error)
        })
    }
  }, [game_id])

  const BeforeGame = () => {
    const beforeGameUrl = process.env.REACT_APP_IMAGES_BASE_URL + '/files/' + game.document_id + '?download'
    return (
      <Fragment>
        { game.document_id &&
          <div className="before-game">
            <div className="-title">Previa del partido</div>
            <a href={beforeGameUrl} className="-download icon-download" target="_blank" rel="noopener noreferrer">
              <span>Descargar previa</span>
            </a>
          </div>
        }
      </Fragment>
    )
  }

  return (
    <Modal onRequestClose={() => onRequestClose()} isOpen={isOpen}>
      { game && game.game_id === game_id &&
        <div className="game-details-modal">
          <div className="team-header flex">
            <div className="-title">Informe del partido</div>
            <button className="modal-close-button" onClick={() => onRequestClose()} ><span className="-description">Cerrar</span><span className="icon-cancel" /></button>
          </div>
          <div className="team-content">
            <MatchCard season_id={season_id} journey={journey} game={game} />
            { game.status === 'finished' &&
              <Fragment>
                <GameGoalsSummary season_id={season_id} game={game} />
                <GameEventsTable season_id={season_id} game={game} />
                <GameEventsTimeline season_id={season_id} game={game} />
              </Fragment>
            }
            <BeforeGame />
          </div>
          <div className="team-footer">
          </div>
        </div>
      }
    </Modal>
  )
}

export default GameDetailsModal
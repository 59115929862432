import { useSelector } from 'react-redux'

const useFanStatsTeam = (season_id, team_id) => {
  const seasonFanStatsTeam = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].teams_fans_stats && 
    state.organization.seasonStats[season_id].teams_fans_stats[team_id])
  return seasonFanStatsTeam || {}
}

export default useFanStatsTeam
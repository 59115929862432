import React, { useMemo, useCallback, useState } from 'react'
import classNames from 'classnames'
import { useParams, Link } from 'react-router-dom'
import JourneysDropdown from '../JourneysDropdown'
import Image from '../Image'
import { makeInitials } from '../../helpers/makeInitials'
import header from '../../images/ball.svg'
import { ReactComponent as RedCard } from './redCard.svg'
import { ReactComponent as DoubleYellowCard } from './doubleYellowCards.svg'
import { useSeasonTeam, useSeasonPlayer, useIsMobile } from '../../hooks'
import { RED_CARD_REASONS, RedCardReasons } from '../../helpers/constants'
import './style.css'

const PlayerBadge = ({ season_id, player_id }) => {
  const { competition_id } = useParams()
  const player = useSeasonPlayer(season_id, player_id)
  const team = useSeasonTeam(season_id, player && player.teamId)

  return (
    <div className='flex-1' style={{ padding: '1rem 0' }}>
      <div className="sent-off-stats-images-container">
        <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
          <Image className={classNames('sent-off-stats-player-image')} src={player.avatar} defaultImage={<span className={classNames("sent-off-stats-player-initials")}>{makeInitials(player.firstname, player.last_name)}</span>} />
          <Image className={classNames('sent-off-stats-team-image')} src={team.logo} defaultImage={<span className="icon-teams sent-off-stats-team-image" />} />
        </Link>
      </div>
      <div className="sent-off-stats-table-player">
        <span className={classNames('sent-off-stats-table-player-name', 'rp-team-name')}>
          {player.firstname}{' '}{player.lastname}
        </span>
        <span className={classNames('sent-off-stats-table-player-info')}>
          <span className="rp-team-name">{ team.name }</span>
        </span>
      </div>
    </div>
  )
}

const Table = ({ newData, season_id, journey }) => {
  const filteredData = useMemo(() => {
    if (!journey) return newData
    return newData.filter(row => row.journey === journey)
  }, [newData, journey])

  return (
    <div className="sent-off-stats-table-table">
      {filteredData.map((row, index) => {
        return (
          <div key={index} className='flex sent-off-stats-table-row'>
            <PlayerBadge season_id={season_id} player_id={row.player_id} />
            <div className={classNames('sent-off-stats-points')}>
              <span className="">{RED_CARD_REASONS[row.card_reason]}</span>
              <div className="-icon">{ row.card_reason === RedCardReasons.DOUBLE_YELLOW_CARD ? <DoubleYellowCard /> : <RedCard /> }</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const SentOffTable = ({ season_id, data, onMore, dark }) => {
  const newData = data.slice(0, 5)
  const moreData = data.length > 5

  return (
    <div className='sent-off-stats-table-container'>
      <div className="sent-off-stats-table-header">
        <span className="sent-off-stats-table-header-title">Expulsiones</span>
        <div className={classNames("sent-off-stats-table-header-image", { '-light': !dark })}>
          <img alt="" src={header} />
          <div className="-overlay"></div>
        </div>
      </div>
      <div>
        <Table newData={newData} season_id={season_id} />
        { moreData &&
          <div className='stats-table-see-more' onClick={onMore}>Ver la lista completa <span className="icon-right-small" /></div>
        }
      </div>
    </div>
  )
}

const SentOffFullTable = ({ season_id, data, onRequestClose }) => {
  const isMobile = useIsMobile()
  const [journeyFilter, setJourneyFilter] = useState(null)
  const journeys = useMemo(() => {
    const uniqueJourneys = Array.from(new Set(data.map(entry => entry.journey)))
    const entries = uniqueJourneys.map(journey => ({ label: journey, value: journey }))
    return [{ label: "Todas las jornadas", value: null }, ...entries]
  }, [data])

  const handleChange = useCallback(journeyOption => {
    setJourneyFilter(journeyOption.value)
  }, [])

  return (
    <div className='full-stats-table-container'>
      <div className="full-stats-table-header">
        <span className="-title">Expulsiones</span>
        {!isMobile &&
          <JourneysDropdown
            journeys={journeys}
            selectedJourneyId={journeyFilter}
            onChange={handleChange}
          />
        }
        <button className="modal-close-button" onClick={onRequestClose} >Cerrar <span className="icon-cancel" /></button>
      </div>
      {isMobile &&
        <JourneysDropdown
          journeys={journeys}
          selectedJourneyId={journeyFilter}
          onChange={handleChange}
        />
      }
      <div>
        <div className='flex full-stats-table-row'>
          <div>Jugadores</div>
          <div>Expulsión</div>
        </div>
        <Table newData={data} season_id={season_id} journey={journeyFilter} />
      </div>
    </div>
  )
}

export { SentOffTable, SentOffFullTable }

import React from 'react'
import Select, { components } from 'react-select'
import classNames from 'classnames'
import './style.css'

const SeasonDropdown = ({title, seasons, defaultValue, placeholder, onChange, dark = false}) => {

  const colourStyles = {
    control: () => ({}),
    option: (styles) => {
      // eslint-disable-next-line
      const { color, backgroundColor, ":active": { backgroundColor: activeBackgroundColor }, ...rest } = styles
      return { ...rest }
    },
    valueContainer: styles => ({ ...styles, overflow: 'visible'})
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img alt="" src="/assets/rf-dropdown-arrows.svg" className="rf-dropdown-icon" />
      </components.DropdownIndicator>
    )
  }

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="rf-season-dropdown__title">{title}</div>
      <div className="rf-season-dropdown__value">{children}</div>
    </components.SingleValue>
  )

  return (
    <Select
      className={classNames('rf-season-dropdown', {'dark': dark})}
      styles={colourStyles}
      components={{ DropdownIndicator, SingleValue }}
      classNamePrefix="rf-season-dropdown"
      options={seasons}
      isClearable={false}
      isSearchable={false}
      value={defaultValue && seasons.find(option => option.value === defaultValue)}
      placeholder={placeholder}
      onChange={(item) => onChange(item.value) } />
  )
}

export default SeasonDropdown

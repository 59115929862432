import React, { Fragment } from 'react'
import { useSeasonPlayer } from '../../hooks'
import Image from '../Image'
import icons from '../../icons'
import { makeInitials } from '../../helpers/makeInitials'
import { EventTypes } from '../../helpers/constants'
import './style.css'

const GameEventsTable = ({ season_id, game }) => {

  const eventType = (type) => {
    switch (type) {
      case EventTypes.GOAL:
        return icons.Goal
      case EventTypes.OWN_GOAL:
        return icons.OwnGoal
      case EventTypes.RED_CARD:
        return icons.Red
      case EventTypes.YELLOW_CARD:
        return icons.Yellow
      case EventTypes.SUBSTITUTION:
        return icons.Sustitution
      case EventTypes.INJURED:
        return icons.Injured
      default:
        return null
    }
  }

  const times = (count, Icon) => {
    var result = []
    for (var i = 0; i < count; i++) {
      result.push(<span key={i} className="-event-icon"><Icon /></span>)
    }
    return result
  }

  const PlayerEvents = ({ player }) => {
    return (
      <div>
        { player && player.events && Object.keys(player.events).map((key, index) => {
          const count = parseInt(player.events[key])
          const Icon = eventType(key)
          return times(count, Icon)
        }) }
      </div>
    )
  }

  const biIterator = (a, b, callback) => {
    const elements = []
    const max = Math.max(a.length, b.length)
    for (let i = 0; i < max; i++) {
      elements.push(callback(a[i], b[i], i))
    }
    return elements
  }

  const formatName = (player) => {
    const a = (player && player.firstname) || ''
    const b = (player && player.lastname) || ''
    return (a + ' ' + b).trim()
  }

  const generateIcon = (player) => {
    const a = (player && player.firstname) || ''
    const b = (player && player.lastname)
    return makeInitials(a, b)
  }

  const PlayersRow = ({ a, b }) => {
    const playerA = useSeasonPlayer(season_id, a && a.player_id)
    const playerB = useSeasonPlayer(season_id, b && b.player_id)
    return (
      <div>
        <div className="game-events-position">{ (playerA.shirtNumber && `(${playerA.shirtNumber})`) || '' }</div>
        <div className="game-events-image">{ playerA.avatar && <Image src={ playerA.avatar } defaultImage={<span className="game-events-image-name">{generateIcon(playerA)}</span>} /> }</div>
        <div className="game-events-name">
          { formatName(playerA) }
          { a && game.best_player_id === a.player_id && <span key="bp" className="-event-icon"><icons.BestPlayer /></span> }
          <PlayerEvents player={a} />
        </div>
        <div className="game-events-name -right">
          <PlayerEvents player={b} />
          { b && game.best_player_id === b.player_id && <span key="bp" className="-event-icon"><icons.BestPlayer /></span> }
          { formatName(playerB) }
        </div>
        <div className="game-events-image -right">{ playerB.avatar && <Image src={ playerB.avatar } defaultImage={<span className="game-events-image-name">{generateIcon(playerB)}</span>} /> }</div>
        <div className="game-events-position -right">{ (playerB.shirtNumber && `(${playerB.shirtNumber})`) || '' }</div>
      </div>
    )
  }

  const TechnicalDirectorRow = ({ home, away }) => {
    return (
      <div>
        <div className="game-events-position">{home && "(D.T.)"}</div>
        <div className="game-events-image"></div>
        <div className="game-events-name">{home}</div>
        <div className="game-events-name -right">{away}</div>
        <div className="game-events-image -right"></div>
        <div className="game-events-position -right">{away && "(D.T.)"}</div>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="game-events-alignment">Alineaciones</div>
      <div className="game-events-people">Directores Técnicos</div>
      { (game.home_head_couch || game.away_head_couch) &&
        <div className="game-events-table">
          <div className="game-events-players">
            <TechnicalDirectorRow home={game.home_head_couch} away={game.away_head_couch} />
          </div>
        </div>
      }      
      <div className="game-events-people">Titulares</div>
      { game.home_initial_players && game.away_initial_players &&
        <div className="game-events-table">
          <div className="game-events-players">
            { biIterator(Object.values(game.home_initial_players), Object.values(game.away_initial_players), (a, b, index) => 
              <PlayersRow key={index} a={a} b={b} />
            ) }
          </div>
        </div>
      }
      <div className="game-events-people">Suplentes</div>
      { game.home_bench_players && game.away_bench_players &&
        <div className="game-events-table">
          <div className="game-events-players">
            { biIterator(Object.values(game.home_bench_players), Object.values(game.away_bench_players), (a, b, index) => 
              <PlayersRow key={index} a={a} b={b} />
            ) }
          </div>
        </div>
      }
    </Fragment>
  )
}

export default GameEventsTable
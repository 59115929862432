import React from 'react'
import { withRouter} from 'react-router-dom'
import './style.css'

const ContactUs = ({ history }) => {

  return (
    <div className="contact-us">
      <div className="-title">Marcas y Patrocinadores</div>
      <div className="-subtitle">¡Conoce nuestros paquetes de publicidad!</div>
      <div className="-button">
        <button onClick={() => history.push("/contacto")}>Ponte en conatacto ahora</button>
      </div>
    </div>
  )
}

export default withRouter(ContactUs)
import classNames from 'classnames'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useParams, useLocation, withRouter } from 'react-router-dom'
import { Element } from 'react-scroll'
import { Sections } from '../../helpers/constants'
import BestMatchesTable from '../../components/BestMatchesTable'
import { CautionStatsTable, CautionStatsFullTable } from '../../components/CautionStatsTable'
import { SentOffTable, SentOffFullTable } from '../../components/SentOffTable'
import ClassificationTable from '../../components/ClassificationTable'
import FansContent from '../../components/FansContent'
import FANStatsPlayerTable from '../../components/FANStatsPlayerTable'
import FANStatsTeamTable from '../../components/FANStatsTeamTable'
import FansVideo from '../../components/FansVideo'
import GameDetailsModal from '../../components/GameDetailsModal'
import GamesResultsTable from '../../components/GamesResultsTable'
import GeneralStatsTable from '../../components/GeneralStatsTable'
import { GoalStatsTable, GoalStatsFullTable } from '../../components/GoalStatsTable'
import Banner from '../../components/Banner'
import JourneysDropdown from '../../components/JourneysDropdown'
import Modal from '../../components/Modal'
import Selector from '../../components/Selector'
import Sidebar from '../../components/Sidebar'
import { FullStatsTable, TopStatsTable } from '../../components/StatsTable'
import TeamDetailsModal from '../../components/TeamDetailsModal'
import TeamsTable from '../../components/TeamsTable'
import TeamDetailsPlayer from '../../components/TeamDetailsPlayer'
import {
  useCompetition,
  useSeason,
  useSeasons,
  useSeasonTeams,
  useSeasonStats,
  useSeasonBestPlayers,
  useConfiguration,
} from '../../hooks'
import './style.scss'
import FANSPointsGuide from '../../components/FANSPointsGuide/FANSPointsGuide'

const containsParam = (params, name) => (new RegExp("[?&]" + name + "=")).test(params)

const CompetitionPage = ({ history, location }) => {
  const { competition_id, season_id, team_id } = useParams()
  const { pathname } = useLocation()
  const competition = useCompetition(competition_id)
  const seasons = useSeasons(competition_id)
  const season = useSeason(competition_id, season_id)
  const teams = useSeasonTeams(season_id)
  const seasonStats = useSeasonStats(season_id, containsParam(location.search, 'update'))
  const bestMatches = (seasonStats && seasonStats.best_matches)
  const generalTeamStats = (seasonStats && seasonStats.team_statistics) || {}
  const gameSchedule = seasonStats && seasonStats.game_schedule
  const classificationTeamPoints = seasonStats && seasonStats.classification_team_points
  const classificationGroups = seasonStats && seasonStats.teams_groups
  const generalStats = (seasonStats && seasonStats.general_statistics) || {}
  const goalers = (seasonStats && seasonStats.scorers_season) || []
  const cautions = (seasonStats && seasonStats.cautioned_players) || {}
  const sentOffs = (seasonStats && seasonStats.sent_off_players) || []
  const [journeyId, setJourneyId] = useState()
  const [gameId, setGameId] = useState()
  const [showGameDetails, setShowGameDetails] = useState(false)
  const [classificationType, setClassificationType] = useState(0)
  const [statsType, setStatsType] = useState(0)
  const [statsDetail, setStatsDetail] = useState()
  const [showStatsDetail, setShowStatsDetail] = useState(false)
  const [transparentBackground, setTransparentBackground] = useState(false)
  const [fansBackground, setFansBackground] = useState(false)
  const fansSectionRef = useRef()
  const showStatsByDefeats = useConfiguration('stats_by_defeat')
  const showStatsByReceivedGoals = useConfiguration('stats_by_received_goals')

  const onSeasonSelected = useCallback((season_id) => {
    history.replace('/competicion/' + competition_id + '/temporada/' + season_id)
  }, [history, competition_id])

  const onTeamClose = () => {
    history.push('/competicion/' + competition_id + '/temporada/' + season_id)
  }

  const onTeamSelected = (teamId) => {
    history.push('/competicion/' + competition_id + '/temporada/' + season_id + '/equipo/' + teamId)
  }

  const changeJourney = journeyOption => {
    setJourneyId(journeyOption.value)
  }

  const showGameDetailsModal = game => {
    setGameId(game.game_id)
    setShowGameDetails(true)
  }

  const hideGameDetailsModal = () => {
    setShowGameDetails(false)
  }

  const showStatsDetailModal = details => {
    setStatsDetail(details)
    setShowStatsDetail(true)
  }

  const hideStatsDetailsModal = () => {
    setShowStatsDetail(false)
  }

  useEffect(() => {
    if (gameSchedule) setJourneyId(Object.keys(gameSchedule)[0])
  }, [gameSchedule])

  useEffect(() => {
    if (seasons && Object.keys(seasons).length > 0 && (!season || !season.season_id)) {
      onSeasonSelected(Object.values(seasons)[0].season_id)
    }
  }, [seasons, season, onSeasonSelected])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const handleScroll = () => {
      const y = window.pageYOffset
      if (y >= 900 && !transparentBackground) {
        setTransparentBackground(true)
      } else if (y < 900 && transparentBackground) {
        setTransparentBackground(false)
      }

      const fansY = fansSectionRef.current.getBoundingClientRect().top
      if (fansY < 200  && !fansBackground) {
        setFansBackground(true)
      } else if (fansY >= 200 && fansBackground) {
        setFansBackground(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [transparentBackground, fansBackground])

  const BestMatches = () => {
    if (!bestMatches || (!bestMatches.best_game && !bestMatches.expected_match)) return null

    return (
      <Element name="BEST_MATCHES" className="flex flex-direction-column">
        <div className="competition-title">
          <h2>Partidos Destacados</h2>
        </div>
        <BestMatchesTable season_id={season_id} bestMatches={bestMatches} />
      </Element>
    )
  }

  const Teams = () => {
    if (!season || !teams || Object.keys(teams).length === 0) return null

    return (
      <Element name="TEAMS" className="flex flex-direction-column">
        <div className="competition-title">
          <h2>Equipos</h2>
        </div>
        <TeamsTable
          teams={teams}
          onClick={team => onTeamSelected(team.team_id)}
        />
      </Element>
    )
  }

  const Calendar = () => {
    if (!gameSchedule) return null

    const journeyOptions = Object.values(gameSchedule).map(aJourney => ({
      label: aJourney.name,
      value: aJourney.journey_id
    }))

    return (
      <Element name="CALENDAR" className="flex flex-direction-column">
        <div className="competition-title">
          <h2>Fechas y Resultados</h2>
          <JourneysDropdown
            journeys={journeyOptions}
            selectedJourneyId={journeyId}
            onChange={changeJourney}
          />
        </div>
        {journeyId && <GamesResultsTable season_id={season_id} journey={gameSchedule[journeyId]} onGameClick={game => showGameDetailsModal(game)} />}
      </Element>
    )
  }

  const Classification = () => {
    if (!classificationTeamPoints || Object.keys(classificationTeamPoints).length === 0) return null
    const justGeneral = !classificationGroups

    const onChangeClassification = index => {
      setClassificationType(index)
    }

    return (
      <Element name="CLASSIFICATION" className="flex flex-direction-column">
        <div>
          <div className="competition-title">
            {justGeneral && <h2>Tabla de Posiciones</h2>}
            {!justGeneral && <h2>Tabla de Posiciones por Grupos</h2>}
            {!justGeneral && (
              <Selector
                options={['General', 'Grupos']}
                selected={classificationType}
                onChange={onChangeClassification}
              />
            )}
          </div>
          <Banner type="leaders" />
          {(classificationType === 0 || justGeneral) && <ClassificationTable data={classificationTeamPoints} teams={teams} />}
          {classificationType === 1 && <ClassificationTable data={classificationTeamPoints} teams={teams} groups={classificationGroups} />}
        </div>
      </Element>
    )
  }

  const Stats = () => {
    if (!season || !season.season_id) return null

    const onChangeStats = index => {
      setStatsType(index)
    }

    return (
      <Element name="STATS" className="flex flex-direction-column">
        <div className="competition-title">
          <h2>Estadísticas</h2>
          <Selector options={['Equipos', 'Jugadores', 'Generales']} selected={statsType} onChange={onChangeStats} />
        </div>
        {statsType === 0 && (
          <div className="flex stats-table">
            <TopStatsTable
              title="Más Victorias"
              data={generalTeamStats.byVictories}
              field='won'
              limit={5}
              season_id={season_id}
              dark={fansBackground}
              onMore={() => showStatsDetailModal(Sections.WINS)}
            />
            <TopStatsTable
              title="Más Empates"
              data={generalTeamStats.byDraws}
              field="drawn"
              limit={5}
              season_id={season_id}
              dark={fansBackground}
              onMore={() => showStatsDetailModal(Sections.DRAWS)}
            />
            { showStatsByDefeats &&
              <TopStatsTable
                title="Menos Derrotas"
                data={generalTeamStats.byDefeats}
                field='lost'
                limit={5}
                season_id={season_id}
                dark={fansBackground}
                onMore={() => showStatsDetailModal(Sections.LOSES)}
              />
            }
            <TopStatsTable
              title="Más Goles Anotados"
              data={generalTeamStats.byScoredGoals}
              field="scored_goals"
              limit={5}
              season_id={season_id}
              dark={fansBackground}
              onMore={() => showStatsDetailModal(Sections.GOALS_SCORED)}
            />
            { showStatsByReceivedGoals &&
              <TopStatsTable
                title="Menos Goles Recibidos"
                data={generalTeamStats.byReceivedGoals}
                field="received_goals"
                limit={5}
                season_id={season_id}
                dark={fansBackground}
                onMore={() => showStatsDetailModal(Sections.GOALS_RECEIVED)}
              />
            }
          </div>
        )}
        {statsType === 1 && (
          <div className="flex stats-table">
            <GoalStatsTable
              season_id={season_id}
              data={goalers}
              dark={fansBackground}
              onMore={() => showStatsDetailModal(Sections.PLAYER_GOALS)}
            />
            <CautionStatsTable
              season_id={season_id}
              data={cautions}
              dark={fansBackground}
              onMore={() => showStatsDetailModal(Sections.CAUTIONS)}
            />
            <SentOffTable
              season_id={season_id}
              data={sentOffs}
              dark={fansBackground}
              onMore={() => showStatsDetailModal(Sections.SENT_OFFS)}
            />
          </div>
        )}
        {statsType === 2 && (
          <div className="flex stats-table">
            <GeneralStatsTable data={generalStats} />
          </div>
        )}
      </Element>
    )
  }

  const FANStatsTeam = ({ fullList = false, dark = true }) => {

    return (
      <div className={classNames({ 'full-stats-table-container': fullList })}>
        <div className="competition-title">
          <div className={classNames('fans-stats-title', { '-dark': dark })}>
            FANStats <span className="-light">por Equipo</span>
          </div>
          { fullList && <button className="modal-close-button" onClick={() => hideStatsDetailsModal()} >Cerrar <span className="icon-cancel" /></button>}
        </div>
        {!fullList && (
          <FANStatsTeamTable
            season_id={season_id}
            dark={dark}
            limit={5}
            onMore={() => showStatsDetailModal(Sections.FANS_TEAMS)}
          />
        )}
        {fullList && <FANStatsTeamTable season_id={season_id} dark={dark} />}
      </div>
    )
  }

  const FANStatsPlayer = ({ fullList = false, dark = true }) => {
    const bestSeasonPlayers = useSeasonBestPlayers(!fullList && season_id)

    return (
      <div className={classNames({ 'full-stats-table-container': fullList })}>
        <div className="competition-title">
          <div className={classNames('fans-stats-title', { '-dark': dark })}>
            FANStats <span className="-light">por Jugador</span>
          </div>
          { fullList && <button className="modal-close-button" onClick={() => hideStatsDetailsModal()} >Cerrar <span className="icon-cancel" /></button>}
        </div>
        {!fullList &&
          <div className="fans-stats-best-players">
            { bestSeasonPlayers.map(player =>
              <div key={player.playerId} className="-player">
                <TeamDetailsPlayer key={player.playerId} season_id={season_id} player_id={player.playerId} bestSeasonPlayer={true} />
              </div>
            ) }
          </div>
        }
        {!fullList && (
          <FANStatsPlayerTable
            season_id={season_id}
            dark={dark}
            limit={10}
            onMore={() => showStatsDetailModal(Sections.FANS_PLAYERS)}
          />
        )}
        {fullList && <FANStatsPlayerTable season_id={season_id} dark={dark} />}
      </div>
    )
  }

  const FANStats = () => {
    if (!season || !season.season_id) return null

    return (
      <Element name="FANSTATS" className="flex flex-direction-column">
        <FANSPointsGuide />
        <FANStatsTeam />
        <FANStatsPlayer />
      </Element>
    )
  }

  return (
    <div className="competition-content">
      <FansContent transparentBackground={transparentBackground}>
        <Sidebar
          season_id={season_id}
          title={competition.name}
          seasons={seasons}
          selectedSeasonId={season_id}
          onSeasonSelected={onSeasonSelected}
          dark={false}
        />
        <div className="rf-pagecontent">
          <BestMatches />
          <Teams />
          <Calendar />
          <Classification />
          <Stats />
        </div>
      </FansContent>
      <div ref={fansSectionRef} className="fans-stats-container">
        <FansContent transparentBackground={true}>
          <Sidebar
            season_id={season_id}
            title={competition.name}
            seasons={seasons}
            selectedSeasonId={season_id}
            onSeasonSelected={onSeasonSelected}
            dark={true}
          />
          <div className="rf-pagecontent">
            <FansVideo />
            <FANStats />
          </div>
        </FansContent>
      </div>
      <GameDetailsModal
        isOpen={showGameDetails}
        season_id={season_id}
        game_id={gameId}
        journey={gameSchedule && gameSchedule[journeyId]}
        onRequestClose={() => hideGameDetailsModal()}
      />
      <TeamDetailsModal
        isOpen={!!team_id}
        onRequestClose={() => onTeamClose()}
        season_id={season_id}
        team_id={team_id}
        onTeamSelected={teamId => onTeamSelected(teamId)}
      />
      <Modal isOpen={showStatsDetail} onRequestClose={() => hideStatsDetailsModal()}>
        {statsDetail === Sections.WINS && (
          <FullStatsTable
            title="Victorias por Equipo"
            nameLabel="Posición / Equipo"
            valueLabel="Victorias"
            data={generalTeamStats.byVictories}
            field="won"
            season_id={season_id}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        {statsDetail === Sections.DRAWS && (
          <FullStatsTable
            title="Empates por Equipo"
            nameLabel="Posición / Equipo"
            valueLabel="Empates"
            data={generalTeamStats.byDraws}
            field="drawn"
            season_id={season_id}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        {statsDetail === Sections.LOSES && (
          <FullStatsTable
            title="Derrotas por Equipo"
            nameLabel="Posición / Equipo"
            valueLabel="Derrotas"
            data={generalTeamStats.byDefeats}
            field='lost'
            season_id={season_id}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        {statsDetail === Sections.GOALS_SCORED && (
          <FullStatsTable
            title="Más Goles Anotados"
            nameLabel="Posición / Equipo"
            valueLabel="Goles"
            data={generalTeamStats.byScoredGoals}
            field="scored_goals"
            season_id={season_id}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        {statsDetail === Sections.GOALS_RECEIVED && (
          <FullStatsTable
            title="Menos Goles Recibidos"
            nameLabel="Posición / Equipo"
            valueLabel="Goles"
            data={generalTeamStats.byReceivedGoals}
            field="received_goals"
            season_id={season_id}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        { statsDetail === Sections.PLAYER_GOALS && (
          <GoalStatsFullTable
            season_id={season_id}
            data={goalers}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        { statsDetail === Sections.CAUTIONS && (
          <CautionStatsFullTable
            season_id={season_id}
            data={cautions}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        { statsDetail === Sections.SENT_OFFS && (
          <SentOffFullTable
            season_id={season_id}
            data={sentOffs}
            onRequestClose={() => hideStatsDetailsModal()}
          />
        )}
        { statsDetail === Sections.FANS_PLAYERS &&
          <FANStatsPlayer
            fullList={true}
            dark={false}
          />
        }
        { statsDetail === Sections.FANS_TEAMS &&
          <FANStatsTeam
            fullList={true}
            dark={false}
          />
        }
      </Modal>
      <div className={classNames("background-underlay", { '-visible': fansBackground })}></div>
    </div>
  )
}

export default withRouter(CompetitionPage)

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import './style.scss'

const TagsSelector = ({ tags, allLabel, allValue, initialValue, onChange }) => {
  const [selectedTag, setSelectedTag] = useState(initialValue)
  const [lastTag, setLastTag] = useState(selectedTag)

  const Tag = ({ name, value, selected }) => {
    return (
      <div
        className={classNames('-tag', { '-selected': selected })}
        onClick={() => setSelectedTag(value || name)}>
        {name}
      </div>
    )
  }

  useEffect(() => {
    if (selectedTag === lastTag) return
    if (onChange) {
      setLastTag(selectedTag)
      onChange(selectedTag)
    }
  }, [onChange, lastTag, selectedTag])

  return (
    <div className="tags-selector">
      {allLabel && <Tag name={allLabel} value={allValue} selected={(allValue || allLabel) === selectedTag} />}
      {tags.map((tag, index) =>
        tag.name ?
          <Tag key={tag.value} name={tag.name} value={tag.value} selected={tag.value === selectedTag} /> :
          <Tag key={index} name={tag} selected={tag === selectedTag} />
      )}
    </div>
  )
}

export default TagsSelector
import React from 'react'
import classNames from 'classnames'
import { useConfiguration } from '../../hooks'
import registedPlayersImage from './registeredPlayers.svg'
import gamesPlayedImage from './gamesPlayed.svg'
import scoredGoalsImage from './scoredGoals.svg'
import ownGoalsImage from './ownGoals.svg'
import reprimandsImage from './reprimands.svg'
import expulsionsImage from './expulsions.svg'
import './style.css'

const Card = ({ number, description, image, className }) => {
  return (
    <div className={classNames("general-stats-card", className)}>
      <div className="-left">
        <div className="-number">{number}</div>
        <div className="-description">{description}</div>
      </div>
      <div className="-image"><img alt="" src={image} /></div>
    </div>
  )
}

const GeneralStatsTable = ({ data }) => {
  const showGeneralStatsOwnGoals = useConfiguration('stats_general_own_goals')

  return (
    <div className="general-stats-table">
      <Card number={data.players} description="Jugadores registrados" image={registedPlayersImage} className="registered-players-card-background" />
      <Card number={data.games} description="Partidos disputados" image={gamesPlayedImage} className="games-played-card-background" />
      <Card number={data.goals} description="Goles anotados" image={scoredGoalsImage} className="scored-goals-card-background" />
      { showGeneralStatsOwnGoals && <Card number={data.own_goals} description="Goles en propia portería" image={ownGoalsImage} className="own-goals-card-background" /> }
      <Card number={data.yellow_cards} description="Amonestaciones" image={reprimandsImage} className="reprimands-card-background" />
      <Card number={data.red_cards} description="Expulsiones" image={expulsionsImage} className="expulsions-card-background" />
    </div>
  )
}

export default GeneralStatsTable
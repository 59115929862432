
export const Sections = {
  WINS: 1,
  DRAWS: 2,
  LOSES: 3,
  GOALS_SCORED: 4,
  GOALS_RECEIVED: 5,
  PLAYER_GOALS: 20,
  CAUTIONS: 21,
  SENT_OFFS: 22,
  SUSPENSIONS: 23,
  FANS_PLAYERS: 100,
  FANS_TEAMS: 101
}

export const EventTypes = {
  GOAL: "goal",
  SUBSTITUTION: "substitution",
  YELLOW_CARD: "yellow_card",
  RED_CARD: "red_card",
  OWN_GOAL: "own_goal",
  PENALTY: "penalty",
  INJURED: "injured"
}

export const RedCardReasons = {
  ROUGH_PLAY: 1,
  VIOLENT_BEHAVIOUR: 2,
  SPIT: 3,
  PREVENT_GOAL_WITH_HAND: 4,
  MANIFEST_OPPORTUNITY: 5,
  OFFENSIVE_LANGUAGE: 6,
  DOUBLE_YELLOW_CARD: 7
}

export const RED_CARD_REASONS = {
  1: "Juego brusco grave",
  2: "Conducta violenta",
  3: "Escupir",
  4: "Impedir gol con la mano",
  5: "Oportunidad manifiesta",
  6: "Lenguaje o gestos ofensivos",
  7: "Segunda amonestación"
}

export const PlayerPositions = {
  1: 'DELANTERA',
  2: 'MEDIA OFENSIVA',
  3: 'MEDIA DE CONTENCIÓN',
  4: 'MEDIA POR LOS COSTADOS',
  5: 'DEFENSA CENTRAL',
  6: 'DEFENSA LATERAL',
  7: 'PORTERÍA'
}

import './style.css'

import { Link, useParams } from 'react-router-dom'
import { useIsDesktop, useIsTablet, useSeasonTeam } from '../../hooks'

import Image from '../Image'
import React from 'react'
import { dateFormat } from '../../helpers/date'

const MatchCard = ({ season_id, game, journey }) => {
  const homeTeam = useSeasonTeam(season_id, game.home_team_id)
  const awayTeam = useSeasonTeam(season_id, game.away_team_id)
  const hasPenalties = (game.home_penalties > 0) || (game.away_penalties > 0)
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()
  const { competition_id } = useParams()

  const LongDate = ({ date }) => {
    return (
      <div className="-date">
        <div className="-small">{ dateFormat(date, "ddd d mmm").toUpperCase() }</div>
        <div className="-big">{ dateFormat(date, "HH:MM").toUpperCase() }</div>
      </div>
    )
  }

  return (
    <div className={"rf-matchcard"}>
      <div className="rf-matchcard-header">
        <div className="rf-matchcard-liga">
          <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + homeTeam.team_id}>
            <Image src={homeTeam.logo} defaultImage={<span className="icon-teams rp-team-badge -big" />} />
          </Link>
        </div>

        <div className="rf-matchcard-score">
          <div className="rf-matchcard-notch">{journey && journey.name}{ game.field_name ? ` - ${game.field_name}` : '' }</div>
          <div className="rf-matchcard-header-content">
            { (isTablet || isDesktop) && <div className="rf-matchcard-name">{homeTeam.name}</div> }
            { game.status === 'finished' &&
              <div className="rf-matchcard-topscore">
                <span>{game.home_score}</span>
                {hasPenalties && <span className="-penalties">({game.home_penalties})</span>}
                <span className="-middle">-</span>
                {hasPenalties && <span className="-penalties -right">({game.away_penalties})</span>}
                <span>{game.away_score}</span>
              </div>
            }
            { game.status !== 'finished' &&
              <div className="rf-matchcard-topscore">
                <LongDate date={game.date} />
              </div>
            }
            { (isTablet || isDesktop) && <div className="rf-matchcard-name -right">{awayTeam.name}</div> }
          </div>
        </div>

        <div className="rf-matchcard-liga -right">
          <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + awayTeam.team_id}>
            <Image src={awayTeam.logo} defaultImage={<span className="icon-teams rp-team-badge -big" />} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MatchCard
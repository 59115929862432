import React, { Fragment } from 'react'
import classNames from 'classnames'
import { useParams, Link } from 'react-router-dom'
import { useIsMobile, useIsTablet, useIsDesktop } from '../../hooks'
import Image from '../Image'
import './style.css'

const ClassificationTable = ({ data, teams, groups }) => {
  const { competition_id, season_id } = useParams()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const TeamBadge = ({ team_stats, position, label, focus }) => {
    const name = (teams[team_stats.team_id] && teams[team_stats.team_id].name) || ''
    const logo = (teams[team_stats.team_id] && teams[team_stats.team_id].logo) || ''
    return (
      <div className={`rp-team-badge flex flex-align-center ${focus ? '-focus' : ''}`}>
        <span>{position}&nbsp;<span className="icon-dot" />&nbsp;</span>
        <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team_stats.team_id}>
          <Image className="rp-team-badge-icon" src={logo} defaultImage={<span className="icon-teams rp-team-badge"></span>} />
        </Link>
        <div>
          {focus && <div className="rp-team-badge-label">{label}</div>}
          <div className="rp-team-name rp-team-badge-name -focus">{name}</div>
        </div>
      </div>
    )
  }

  const TableTeams = ({ group }) => {
    return (
      <div>
        <div className="rp-table-title">
          {isMobile && <div>Pos / Equipo</div>}
          {(isTablet || isDesktop) &&
            <Fragment>
              <div>Posición / Equipo</div>
              <div className="-points">PJ</div>
              <div className="-points">PG</div>
              <div className="-points">PE</div>
              <div className="-points">PP</div>
              <div className="-points">TA</div>
              <div className="-points">TR</div>
              <div className="-points">GF</div>
              <div className="-points">GC</div>
              <div className="-points">DG</div>
            </Fragment>
          }
          <div className="-points">Puntos</div>
        </div>

        <div className="rp-table">
          {group.map((team_id, index) => {
            const team_stats = data[team_id] || { team_id }
            return (
              <div key={index} className="rp-table-row">
                <div>
                  <TeamBadge focus={index === 0} team_stats={team_stats} position={index + 1} />
                </div>
                {(isTablet || isDesktop) &&
                  <Fragment>
                    <div className="-points">{team_stats.played || 0}</div>
                    <div className="-points">{team_stats.won || 0}</div>
                    <div className="-points">{team_stats.drawn || 0}</div>
                    <div className="-points">{team_stats.lost || 0}</div>
                    <div className="-points">{team_stats.yellow_cards || 0}</div>
                    <div className="-points">{team_stats.red_cards || 0}</div>
                    <div className="-points">{team_stats.scored_goals || 0}</div>
                    <div className="-points">{team_stats.received_goals || 0}</div>
                    <div className="-points">{team_stats.goals_difference > 0 ? '+' : ''}{team_stats.goals_difference || 0}</div>
                  </Fragment>
                }
                <div className={classNames("rp-points", { '-lead': index === 0 })}>{team_stats.points || 0}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {groups ? Object.keys(groups).map((groupName, index) =>
        <div key={index}>
          <h4 className="classification-table-title">{groupName}</h4>
          <TableTeams group={groups[groupName]} />
        </div>
      ) :
        <TableTeams group={Object.keys(data)} />
      }
    </Fragment>
  )
}

export default ClassificationTable

import React, { Fragment } from 'react'
import classNames from 'classnames'
import { useSeasonPlayer, usePlayerStatistics, useSeasonTeam } from '../../hooks'
import Avatar from '../Avatar'
import Image from '../Image'
import { makeInitials } from '../../helpers/makeInitials'
import { PlayerPositions } from '../../helpers/constants'
import { ReactComponent as Goal } from './goal.svg'
import { ReactComponent as Medal } from './medal.svg'
import { ReactComponent as OwnGoal } from './owngoal.svg'
import { ReactComponent as RedCard } from './redcard.svg'
import { ReactComponent as YellowCard } from './yellowcard.svg'
import { ReactComponent as YellowCards } from './yellowcards.svg'
import './style.css'

const TeamDetailsPlayer = ({ season_id, player_id, bestTeamPlayer = false, bestSeasonPlayer = false }) => {
  const player = useSeasonPlayer(season_id, player_id)
  const team = useSeasonTeam(season_id, player && player.teamId)
  const playerFanStats = usePlayerStatistics(season_id, player_id)

  const bestPlayer = { 
    '-seasonmvp': bestSeasonPlayer,
    '-teammvp': !bestSeasonPlayer && bestTeamPlayer
  }
  const bestPlayerText = bestSeasonPlayer ? 'JMV TEMPORADA' : bestTeamPlayer ? 'JMV EQUIPO' : false

  return (
    <Fragment>
      <div className={classNames('team-detail-player-main-container')}>
        <div className={classNames('team-detail-player-card-background', bestPlayer)}>
          <div className={'team-detail-player-card-container'}>
            <div className="team-detail-player-avatar-container">
              <div className={classNames('team-detail-player-avatar-image-container ')}>
                <Avatar src={player.avatar} initials={makeInitials(player.firstname, player.lastname)} />
              </div>
              <div className="team-detail-player-avatar-number-container">
                <div className="team-detail-player-avatar-number-mask">{player.shirtNumber}</div>
              </div>
            </div>
            <span className={classNames('team-detail-player-name')}>
              {player.firstname} {player.lastname}
            </span>
            <span className={classNames('team-detail-player-team')}>
              {team.name}
            </span>
            <div className={classNames('team-detail-player-separation-line')} />
            <span className={classNames('team-detail-player-position')}>
              {PlayerPositions[player.positions && player.positions[0]] || 'SP'}
            </span>
            <div className={classNames('team-detail-player-fanstats-info')}>
              <span className="team-detail-player-fanstats-info-title">
                {'FANS'}
                <span className={classNames('team-detail-player-fanstats-info-title', '-nobold')}>{'tats'}</span>
              </span>
              <div className={'team-detail-player-fanstats-info-grid'}>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <div>
                    <Medal />
                  </div>
                  <span className={'team-detail-player-fanstats-info-data-number'}>{playerFanStats.best_player}</span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <div>
                    <Goal />
                  </div>
                  <span className={'team-detail-player-fanstats-info-data-number'}>{playerFanStats.goals}</span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <div>
                    <OwnGoal />
                  </div>
                  <span className={'team-detail-player-fanstats-info-data-number'}>{playerFanStats.own_goals}</span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <div>
                    <YellowCard />
                  </div>
                  <span className={'team-detail-player-fanstats-info-data-number'}>{playerFanStats.yellow_cards}</span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <div>
                    <YellowCards />
                  </div>
                  <span className={'team-detail-player-fanstats-info-data-number'}>{playerFanStats.double_yellow_cards}</span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <div>
                    <RedCard />
                  </div>
                  <span className={'team-detail-player-fanstats-info-data-number'}>{playerFanStats.red_cards}</span>
                </div>
              </div>
              <div className={classNames('team-detail-player-fanstats-info-separation-line')} />
              <div className={'team-detail-player-fanstats-info-grid'}>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <span className={'team-detail-player-fanstats-info-data-general-number'}>
                    {playerFanStats.best_player_points}
                  </span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <span className={'team-detail-player-fanstats-info-data-general-number'}>
                    {playerFanStats.goals_points}
                  </span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <span className={'team-detail-player-fanstats-info-data-general-number'}>
                    {playerFanStats.own_goals_points}
                  </span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <span className={'team-detail-player-fanstats-info-data-general-number'}>
                    {playerFanStats.yellow_cards_points}
                  </span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <span className={'team-detail-player-fanstats-info-data-general-number'}>
                    {playerFanStats.double_yellow_cards_points}
                  </span>
                </div>
                <div className={'team-detail-player-fanstats-info-data-container'}>
                  <span className={'team-detail-player-fanstats-info-data-general-number'}>
                    {playerFanStats.red_cards_points}
                  </span>
                </div>
              </div>
            </div>
            <div className="team-detail-player-fanstats-container">
              {bestPlayerText && (
                <span className={classNames('team-detail-player-mvptitle', bestPlayer)}>
                  {bestPlayerText}
                </span>
              )}
              <div className="team-detail-player-fanstats-data-container">
                {playerFanStats.points !== 0 && <span className="team-detail-player-fanstats-number">{playerFanStats.points}</span>}
                {playerFanStats.points !== 0 && <span className="team-detail-player-fanstats-title">{'FANpoints'}</span>}
              </div>
            </div>
            <div className="team-detail-player-fanstext-container">{'FANS'}</div>
            <div className="team-detail-player-shield-container">
              <Image src={team.logo} defaultImage={<span className="icon-teams rp-team-badge -small" />} />
            </div>
            <div className="team-detail-player-shield-background-container">
              <Image src={team.logo} defaultImage={<span className="icon-teams rp-team-badge -big" />} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TeamDetailsPlayer

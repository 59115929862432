import React, { useEffect } from 'react'
import { useCompetitions, useConfiguration, useIsDesktop, useIsTablet } from '../../hooks'
import { useLocation, withRouter } from 'react-router-dom'

import Banner from '../../components/Banner'
import ContactUs from '../../components/ContactUs'
import InstagramCarousel from '../../components/InstagramCarousel'
import LatestNews from '../../components/LatestNews/LatestNews'
import LeagueSelector from '../../components/LeagueSelector'
import SearchTeams from '../../components/SearchTeams'

const HomePage = ({ organization, history }) => {
  const competitions = useCompetitions()
  const { pathname } = useLocation()
  const hasContactShortcut = useConfiguration('contact_shortcut')
  const hasSearchShortcut = useConfiguration('search_shortcut')
  const instagramGalery = useConfiguration('instagram_galery')

  const handleClick = (competition_id, season_id = false) => {
    if (season_id) {
      history.push('/competicion/' + competition_id + '/temporada/' + season_id)
    } else {
      history.push('/competicion/' + competition_id)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div>
      <Banner type="home" className="rf-content-spacing" />
      <div className="rf-content-spacing home-shortcuts">
          { hasSearchShortcut && <SearchTeams /> }
          { hasContactShortcut && <ContactUs /> }
      </div>

      <div className="rf-content-spacing">
        <LatestNews />
      </div>

      <div className="home-page">
        {competitions && (
          <div>
            <div className="rp-section-title">Nuestras Competiciones</div>
            <LeagueSelector competitions={competitions} onClick={handleClick} />
          </div>
        )}
      </div>
      { instagramGalery && <InstagramCarousel instagram={organization.instagram} token={organization.instagram_token} /> }
    </div>
  )
}

export default withRouter(HomePage)

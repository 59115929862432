import * as React from 'react'

const DefaultDesktopHero = ({ backgroundA, backgroundB, backgroundC, colorA, colorB, colorC }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2560" viewBox="0 0 2560 344">
      <defs>
        <linearGradient id="DefaultDesktopHero__a" x1="827.04" y1="314.35" x2="1728.73" y2="-960.59" gradientTransform="matrix(1 0 0 -1 0 -154.11)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={backgroundA || "#1F5BFF"}/>
          <stop offset=".5" stopColor={backgroundB || "#041C5D"}/>
          <stop offset="1" stopColor={backgroundC || "#790461"}/>
        </linearGradient>
      </defs>
      <path fill="url(#DefaultDesktopHero__a)" d="M0 0h2560v344H0z"/>
    </svg>
    
  )
}

export default DefaultDesktopHero
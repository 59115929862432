import { useSelector } from 'react-redux'

const useSeason = (competition_id, season_id) => {
  const season = useSelector(state => 
    state.organization.organization && 
    state.organization.organization.competitions &&
    state.organization.organization.competitions[competition_id] &&
    state.organization.organization.competitions[competition_id].seasons &&
    state.organization.organization.competitions[competition_id].seasons[season_id])
  return season || {}
}

export default useSeason
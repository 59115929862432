import React from 'react'
import './style.css'

const SelectorElement = ({ name, className = '', ...other }) => {
  return (
    <button className={`rf-selector-element ${className}`} {...other}>
      {name}
    </button>
  )
}

const Selector = ({ className = '', options, selected = 0, onChange }) => {
  return (
    <div className={`rf-selector ${className}`}>
      <div className="rf-selector-active" style={{ transform: `translatex(${selected * 109}px)` }} />

      { options.map((option, index) => (
        <SelectorElement
          className={index === selected ? '-active' : ''}
          key={index}
          name={option}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  )
}

export default Selector

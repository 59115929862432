import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import { useSeasonTeams } from '../../hooks'
import Image from '../Image'
import header from '../../images/ball.svg'
import './style.css'

const TeamBadge = ({ name, position, label, logo, competition_id, season_id, team_id, focus }) => {
  return (
    <div className='flex-1' style={{ padding: '1rem 0' }}>
      <span className='stats-table-light-text'>{position} <span className='icon-dot' /></span>
      <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team_id}>
        <Image className={classNames('rp-team-badge-image', {'-lead': focus})} src={logo} defaultImage={(<span className={classNames("icon-teams", "rp-team-badge", "stats-table-light-text", {'-lead': focus})} />)} />
      </Link>
      <div>
        {focus && <div className="rp-team-badge-label">{label}</div>}
        <span className={classNames('stats-table-team-name', 'rp-team-name', {'-lead': focus})}>{name}</span>
      </div>
    </div>
  )
}

const Table = ({ data, field, teams, competition_id, season_id }) => {
  return (
    <div className="stats-table-table">
      {data.map((row, index) => {
        return (
          <div key={index} className='flex stats-table-row'>
            <TeamBadge
              focus={row.place === 1}
              name={teams[row.team_id] && teams[row.team_id].name}
              logo={teams[row.team_id] && teams[row.team_id].logo}
              position={row.place}
              competition_id={competition_id}
              season_id={season_id}
              team_id={row.team_id}
            />
            <div className={classNames('rp-points', {'-lead': row.place === 1})}>{row[field]}</div>
          </div>
        )
      })}
    </div>
  )
}

const TopStatsTable = ({title, data, field, limit, season_id, onMore, dark}) => {
  const { competition_id } = useParams()
  const teams = useSeasonTeams(season_id)
  const newData = limit ? ((data && data.slice(0, limit)) || []) : data
  const moreData = limit !== false && ((data && data.length) || 0) > limit

  return (
    <Fragment>
      { newData.length >= 0 &&
        <div className='stats-table-container'>
          <div className="stats-table-header">
            <span className="stats-table-header-title">{title}</span>
            <div className={classNames("stats-table-header-image", { '-light': !dark })}>
              <img alt="" src={header} />
              <div className="-overlay"></div>
            </div>
          </div>
          <div>
            <Table data={newData} field={field} teams={teams} competition_id={competition_id} season_id={season_id} />
            { moreData &&
              <div className='stats-table-see-more' onClick={onMore}>Ver la lista completa <span className="icon-right-small" /></div>
            }
          </div>
        </div>
      }
    </Fragment>
  )
}

const FullStatsTable = ({title, nameLabel, valueLabel, data, field, season_id, onRequestClose}) => {
  const { competition_id } = useParams()
  const teams = useSeasonTeams(season_id)

  return (
    <div className='full-stats-table-container'>
      <div className="full-stats-table-header">
        <span className="-title">{title}</span>
        <button className="modal-close-button" onClick={onRequestClose} >Cerrar <span className="icon-cancel" /></button>
      </div>
      <div>
        <div className='flex full-stats-table-row'>
          <div>{nameLabel}</div>
          <div>{valueLabel}</div>
        </div>
        <Table data={data} field={field} teams={teams} competition_id={competition_id} season_id={season_id} />
      </div>
    </div>
  )
}

export {
  TopStatsTable,
  FullStatsTable
}
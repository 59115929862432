import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { dateFormat } from '../../helpers/date'
import './style.scss'

const News = ({ id, title, content, coverImage, tags, preview, created }) => {
  return (
    <div className="news">
      <Link to={"/noticias/" + id}>
        <div className="-aspect">
          <div className="-image">
            <img src={process.env.REACT_APP_IMAGES_BASE_URL + '/news/' + coverImage} alt="" />
          </div>
        </div>
      </Link>
      <div className="-tags">
        {tags.split(',').map((tag, index) =>
          <div key={index}>{tag}</div>
        )}
      </div>
      <div className="-title"><Link to={"/noticias/" + id}>{title}</Link></div>
      <div className="-date">{dateFormat(created, 'dd "de" mmmm')}</div>
      <div className={classNames("-description-wrapper", { "-preview": preview })}>
        <div className="-description" dangerouslySetInnerHTML={{ __html: content }} />
        {preview && <div className="-see-more-overlay"></div>}
      </div>
      {preview && <div className="-see-more"><Link to={"/noticias/" + id}>Ver nota completa <i className="icon-right-arrow"></i></Link></div>}
    </div>
  )
}

export default News
import React from 'react'
import { withRouter } from 'react-router-dom'
import Select, { components } from 'react-select'
import classNames from 'classnames'
import './style.css'

const CompetitionDropdown = ({history, competitions, className = '', dark = false}) => {

  const handleChange = (option) => {
    history.push('/competicion/' + option.value)
  }

  const colourStyles = {
    control: () => ({}),
    placeholder: () => ({}),
    option: (styles) => {
      // eslint-disable-next-line
      const { color, backgroundColor, ":active": { backgroundColor: activeBackgroundColor }, ...rest } = styles
      return { ...rest }
    },
    valueContainer: (styles) => {
      // eslint-disable-next-line
      const { padding, flex, ...rest } = styles
      return { ...rest, overflow: 'visible'}
    },
    dropdownIndicator: (style) => {
      return { ...style, padding: 0 }
    },
    indicatorSeparator: (styles) => {
      // eslint-disable-next-line
      const { padding, ...rest} = styles
      return rest
    }
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="icon-down-open-mini" />
      </components.DropdownIndicator>
    )
  }

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="rf-competition-dropdown__value">Competiciones</div>
    </components.SingleValue>
  )

  return (
    <Select
      className={classNames('rf-competition-dropdown', className, {'dark': dark})}
      styles={colourStyles}
      components={{ DropdownIndicator, SingleValue }}
      classNamePrefix="rf-competition-dropdown"
      options={competitions}
      isClearable={false}
      isSearchable={false}
      placeholder="Competiciones"
      value={""}
      onChange={(option) => handleChange(option)} />
  )
}

export default withRouter(CompetitionDropdown)

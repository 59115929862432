import React from 'react'
import { ReactComponent as Goals } from '../FANStatsPlayerTable/goals-black.svg'
import { ReactComponent as BestPlayer } from '../FANStatsPlayerTable/bestPlayer.svg'
import { ReactComponent as YellowCard } from '../FANStatsPlayerTable/yellowCard.svg'
import { ReactComponent as YellowCards } from '../FANStatsPlayerTable/yellowCards.svg'
import { ReactComponent as RedCard } from '../FANStatsPlayerTable/redCard.svg'
import { ReactComponent as OwnGoals } from '../FANStatsPlayerTable/ownGoals.svg'
import { ReactComponent as AwayWon } from '../FANStatsTeamTable/awayWon.svg'
import { ReactComponent as GoalsKeeper } from '../FANStatsTeamTable/goalsKeeper.svg'
import { ReactComponent as CardsFree } from '../FANStatsTeamTable/cardsFree.svg'
import { ReactComponent as LostByDefault } from '../FANStatsTeamTable/lostByDefault.svg'
import { ReactComponent as FansStatsPlayers } from '../FANStatsTeamTable/fansStatsPlayers.svg'
import './style.scss'

const FANSPointsGuide = () => {

  return (
    <div className="fans-points-guide">
      <div className="-header">
        <div className="-header-title">Asignación de puntos</div>
      </div>
      <div className="-content">
        <div className="-left">
          <div className="-title">Jugadores</div>
          <div className="-item">
            <div className="-icon"><Goals/></div>
            <div className="-description">Gol</div>
            <div className="-points">5 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><BestPlayer/></div>
            <div className="-description">Voto a mejor jugador/a</div>
            <div className="-points">10 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><YellowCard/></div>
            <div className="-description">Amonestación</div>
            <div className="-points">-3 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><YellowCards/></div>
            <div className="-description">Expulsión por dos amonestaciones</div>
            <div className="-points">-8 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><RedCard/></div>
            <div className="-description">Expulsión por tarjeta roja directa</div>
            <div className="-points">-10 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><OwnGoals/></div>
            <div className="-description">Auto-gol</div>
            <div className="-points">-1 pt</div>
          </div>
        </div>
        <div className="-right">
        <div className="-title">Equipos</div>
          <div className="-item">
            <div className="-icon"><AwayWon/></div>
            <div className="-description">Victoria de visitante</div>
            <div className="-points">30 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><GoalsKeeper/></div>
            <div className="-description">Sin goles en contra</div>
            <div className="-points">20 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><CardsFree/></div>
            <div className="-description">FairPlay</div>
            <div className="-points">50 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><LostByDefault/></div>
            <div className="-description">Perder por default</div>
            <div className="-points">-30 pts</div>
          </div>
          <div className="-item">
            <div className="-icon"><FansStatsPlayers/></div>
            <div className="-description">FANpoints del plantel</div>
            <div className="-points">Acumulado</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FANSPointsGuide
import React, { Fragment } from 'react'
import classNames from 'classnames'
import { useParams, Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { useIsTablet, useIsDesktop, usePlayersFansStats } from '../../hooks'
import Image from '../Image'
import { ReactComponent as Goals } from './goals.svg'
import { ReactComponent as GoalsBlack } from './goals-black.svg'
import { ReactComponent as BestPlayer } from './bestPlayer.svg'
import { ReactComponent as YellowCard } from './yellowCard.svg'
import { ReactComponent as YellowCards } from './yellowCards.svg'
import { ReactComponent as RedCard } from './redCard.svg'
import { ReactComponent as OwnGoals } from './ownGoals.svg'
import { makeInitials } from '../../helpers/makeInitials'
import { useSeasonTeam, useSeasonPlayer } from '../../hooks'
import './style.css'

const Tooltip = ({ id }) => {
  return (
    <ReactTooltip
      id={id}
      place='top'
      effect='solid'
      getContent={tip => <div>{tip}</div>}
    />
  )
}

const FANStatsPlayerTable = ({ season_id, limit, onMore, dark = false }) => {
  const { competition_id } = useParams()
  const fansStatsPlayer = usePlayersFansStats(season_id)
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const newData = limit ? fansStatsPlayer.slice(0, limit) : fansStatsPlayer
  const moreData = limit !== false && fansStatsPlayer.length > limit

  const PlayerBadge = ({ id, position, focus }) => {
    const player = useSeasonPlayer(season_id, id)
    const team = useSeasonTeam(season_id, player && player.teamId)

    return (
      <div className='-name flex-1'>
        <span className={classNames('fans-player-table-light-text', {'-dark': dark})}>
          <span className="-number">{position}</span>
          <span className='-dot icon-dot' />
        </span>
        <div>
          <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
            <Image className={classNames('fans-player-player-image', {'-lead': focus, '-dark': dark})} src={player.avatar} defaultImage={<span className={classNames("fans-player-player-initials", {"-lead": focus})}>{makeInitials(player.firstname, player.lastname)}</span>} />
            <Image className={classNames('fans-player-team-image', {'-lead': focus, '-dark': dark})} src={team.logo} defaultImage={<span className="icon-teams fans-player-team-image" />} />
          </Link>
        </div>
        <div className="fans-player-table-player">
          <span className={classNames('fans-player-table-player-name', 'rp-team-name', {'-lead': focus, '-dark': dark})}>{((player.firstname || '') + ' ' + (player.lastname || '')).trim()}</span>
          <span className={classNames('fans-player-table-player-info', {'-lead': focus, '-dark': dark})}>
            <span className="rp-team-name">{ team.name }</span>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames("fans-player-table-container", { "-dark": dark })}>
      <div className={classNames("fans-player-table-header", { "-dark": dark })}>
        <div className="-main">{limit ? `Top ${limit} Jugadores` : 'Jugadores'}</div>
        { (isTablet || isDesktop) &&
          <Fragment>
            <div data-tip="Gol (5 pts)" data-for="stats-player-tooltip">{ dark ? <Goals /> : <GoalsBlack /> }</div>
            <div data-tip="Voto a mejor jugador/a (10 pts)" data-for="stats-player-tooltip"><BestPlayer/></div>
            <div data-tip="Amonestación (-3 pts)" data-for="stats-player-tooltip"><YellowCard/></div>
            <div data-tip="Expulsión por dos amonestaciones (-8 pts)" data-for="stats-player-tooltip"><YellowCards/></div>
            <div data-tip="Expulsión por tarjeta roja directa (-10 pts)" data-for="stats-player-tooltip"><RedCard/></div>
            <div data-tip="Auto-gol (-1 pts)" data-for="stats-player-tooltip"><OwnGoals/></div>
            <Tooltip id="stats-player-tooltip" />
          </Fragment>
        }
        <div className="-total">Puntos</div>
      </div>
      <div className={classNames("fans-player-table-table", { "-dark": dark })}>
        {newData.map((row, index) => {
          return (
            <div key={index} className={classNames("fans-player-table-row", { "-dark": dark })}>
              <PlayerBadge focus={index === 0} id={row.playerId} position={row.place} />
              { (isTablet || isDesktop) &&
                <Fragment>
                  <div className="-points">
                    <div>{row.goals}</div>
                    <div className="-subpoints">({row.goals_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.best_player}</div>
                    <div className="-subpoints">({row.best_player_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.yellow_cards}</div>
                    <div className="-subpoints">({row.yellow_cards_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.double_yellow_cards}</div>
                    <div className="-subpoints">({row.double_yellow_cards_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.red_cards}</div>
                    <div className="-subpoints">({row.red_cards_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.own_goals}</div>
                    <div className="-subpoints">({row.own_goals_points})</div>
                  </div>
                </Fragment>
              }
              <div className="-final-points">{row.points}</div>
            </div>
          )
        })}
      </div>
      { moreData &&
        <div className={classNames("stats-table-see-more", { "-dark": dark })} onClick={onMore}>Ver la lista completa <span className="icon-right-small" /></div>
      }
    </div>
  )
}

export default FANStatsPlayerTable

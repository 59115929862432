import { useIsDesktop, useIsTablet } from '../hooks'

import DefaultDesktopHero from './DefaultDesktopHero'
import DefaultMobileHero from './DefaultMobileHero'
import DefaultTabletHero from './DefaultTabletHero'
import FansFlagsDesktopHero from './FansFlagsDesktopHero'
import FansFlagsMobileHero from './FansFlagsMobileHero'
import FansFlagsTabletHero from './FansFlagsTabletHero'
import Image from '../components/Image'
import React from 'react'
import SoccerBallDesktopHero from './SoccerBallDesktopHero'
import SoccerBallMobileHero from './SoccerBallMobileHero'
import SoccerBallTabletHero from './SoccerBallTabletHero'
import StadiumDesktopHero from './StadiumDesktopHero'
import StadiumMobileHero from './StadiumMobileHero'
import StadiumTabletHero from './StadiumTabletHero'
import StarsDesktopHero from './StarsDesktopHero'
import StarsMobileHero from './StarsMobileHero'
import StarsTabletHero from './StarsTabletHero'

const Hero = ({ name, ...props }) => {
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()
  const attributes = { width: (isDesktop ? "2560px" : isTablet ? "1024px" : "414px"), ...props }

  const component = (() => {
    switch (name) {
      case 'stars':
        return isDesktop ? <StarsDesktopHero {...attributes} /> : isTablet ? <StarsTabletHero {...attributes} />: <StarsMobileHero {...attributes} />
      case 'stadium':
        return isDesktop ? <StadiumDesktopHero {...attributes} /> : isTablet ? <StadiumTabletHero {...attributes} />: <StadiumMobileHero {...attributes} />
      case 'soccer-ball':
        return isDesktop ? <SoccerBallDesktopHero {...attributes} /> : isTablet ? <SoccerBallTabletHero {...attributes} />: <SoccerBallMobileHero {...attributes} />
      case 'flags':
        return isDesktop ? <FansFlagsDesktopHero {...attributes} /> : isTablet ? <FansFlagsTabletHero {...attributes} />: <FansFlagsMobileHero {...attributes} />
      default:
        return isDesktop ? <DefaultDesktopHero {...attributes} /> : isTablet ? <DefaultTabletHero {...attributes} />: <DefaultMobileHero {...attributes} />
    }
  })()

  if (name.startsWith("custom-")) {
    const imageSize = isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
    const imageUrl = 'https://refereeprocm.s3.amazonaws.com/heros/' + name + '-' + imageSize + '.svg'
    return <Image src={imageUrl} defaultImage={<div><DefaultDesktopHero /></div>} className="fans-header-background" />
  } else {
    return (
      <div>
        {component}
      </div>
    )
  }
}

export default Hero
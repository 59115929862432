import { useSelector } from 'react-redux'
import { useSeasonTeam } from './'

const useTeamBestPlayers = (season_id, team_id, only_ids = false) => {
  const players = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].player_fans_stats)
  const team = useSeasonTeam(season_id, team_id)

  if (!players) return []
  if (!team || !team.players || team.players.length === 0) return []

  const playerList = Object.values(players)

  const bestPlayers = []
  let bestPlace = null
  let foundOthers = false
  for (let index = 0; index < playerList.length; index++) {
    const player = playerList[index]
    if (team.players.includes(player.playerId)) {
      if (bestPlace === null) {
        bestPlace = player.place
        bestPlayers.push(only_ids ? player.playerId : player)
      } else if (bestPlace === player.place) {
        bestPlayers.push(only_ids ? player.playerId : player)
      } else {
        foundOthers = true
        break
      }
    }
  }

  if (!foundOthers) return []

  return bestPlayers
}

export default useTeamBestPlayers

import * as React from 'react'

const StarsTabletHero = ({ backgroundA, backgroundB, backgroundC, colorA, colorB, colorC }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1024" viewBox="0 0 1024 435">
      <defs>
        <linearGradient id="StarsTabletHero__b" x1="223.97" y1="-177.95" x2="726.33" y2="495.61" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={backgroundA || "#1F5BFF"}/>
          <stop offset=".48" stopColor={backgroundB || "#041C5D"}/>
          <stop offset="1" stopColor={backgroundC || "#790461"}/>
        </linearGradient>
        <linearGradient id="StarsTabletHero__a" x1="165.34" y1="-167.65" x2="874.79" y2="494.51" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={colorA || "#5CA2FF"}/>
          <stop offset=".48" stopColor={colorB || "#1248DB"}/>
          <stop offset="1" stopColor={colorC || "#DF0FB3"}/>
        </linearGradient>
        <linearGradient id="StarsTabletHero__c" x1="163.65" y1="-165.84" x2="873.11" y2="496.32" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__d" x1="167.23" y1="-169.68" x2="876.69" y2="492.49" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__e" x1="168.51" y1="-171.05" x2="877.97" y2="491.11" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__f" x1="166.37" y1="-168.75" x2="875.83" y2="493.41" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__g" x1="-111.61" y1="129.08" x2="597.85" y2="791.24" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__h" x1="-123.13" y1="141.43" x2="586.33" y2="803.59" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__i" x1="-143.39" y1="163.13" x2="566.07" y2="825.29" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__j" x1="-134.13" y1="153.21" x2="575.33" y2="815.37" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__k" x1="428.25" y1="-449.34" x2="1137.71" y2="212.82" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__l" x1="402.23" y1="-421.46" x2="1111.69" y2="240.7" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__m" x1="371.92" y1="-388.99" x2="1081.38" y2="273.17" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__n" x1="437.02" y1="-458.74" x2="1146.48" y2="203.42" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__o" x1="-16.48" y1="27.16" x2="692.98" y2="689.32" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__p" x1="163.78" y1="-165.98" x2="873.23" y2="496.19" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__q" x1="-17.49" y1="28.23" x2="691.97" y2="690.4" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__r" x1="-14.08" y1="24.58" x2="695.38" y2="686.74" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__s" x1="-20.86" y1="31.85" x2="688.6" y2="694.01" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__t" x1="324.85" y1="-338.55" x2="1034.31" y2="323.61" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__u" x1="322.24" y1="-335.76" x2="1031.7" y2="326.4" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__v" x1="162.27" y1="-164.36" x2="871.72" y2="497.8" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__w" x1="314.98" y1="-327.97" x2="1024.43" y2="334.19" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__x" x1="318.53" y1="-331.78" x2="1027.99" y2="330.38" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__y" x1="-85.21" y1="100.8" x2="624.25" y2="762.96" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__z" x1="-97.06" y1="113.49" x2="612.4" y2="775.65" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__A" x1="-73.52" y1="88.27" x2="635.94" y2="750.43" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__B" x1="-24.79" y1="36.06" x2="684.67" y2="698.22" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__C" x1="-83.7" y1="99.18" x2="625.76" y2="761.34" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__D" x1="27.35" y1="-19.8" x2="736.81" y2="642.36" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__E" x1="94.54" y1="-91.79" x2="803.99" y2="570.37" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__F" x1="255.71" y1="-264.47" x2="965.16" y2="397.69" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__G" x1="85.55" y1="-82.17" x2="795.01" y2="579.99" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__H" x1="106.03" y1="-104.1" x2="815.49" y2="558.06" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__I" x1="233.9" y1="-241.11" x2="943.36" y2="421.05" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__J" x1="79.81" y1="-76.01" x2="789.27" y2="586.15" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__K" x1="117.37" y1="-116.25" x2="826.83" y2="545.91" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__L" x1="91.24" y1="-88.26" x2="800.7" y2="573.91" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__M" x1="311.09" y1="-323.81" x2="1020.55" y2="338.35" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__N" x1="219.42" y1="-225.6" x2="928.88" y2="436.56" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__O" x1="213.72" y1="-219.49" x2="923.18" y2="442.67" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__P" x1="209.21" y1="-214.65" x2="918.67" y2="447.51" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__Q" x1="282.83" y1="-293.53" x2="992.29" y2="368.63" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__R" x1="306.04" y1="-318.4" x2="1015.5" y2="343.76" href="#StarsTabletHero__a"/>
        <linearGradient id="StarsTabletHero__S" x1="277.96" y1="-288.32" x2="987.42" y2="373.85" href="#StarsTabletHero__a"/>
      </defs>
      <path fill="url(#StarsTabletHero__b)" d="M0 0v416.48l513.69-110.86L1024 416V0H0z"/>
      <path d="M473.93 162.11l-20.65 72.76 60.77-42.7 60.64 42.7L554 162.11l58.37-46.27-73.56-2.19-24.76-71.39-24.79 71.39-73.57 2.19zm16.8-46.4l23.32-67L537 115l.27.69 69.16 2.06-54.77 43.51 19.46 68.37-56.51-39.81-.53-.41-57 40.22 19.46-68.37-54.78-43.51z" fill="url(#StarsTabletHero__a)"/>
      <path fill="url(#StarsTabletHero__c)" d="M354.39 92.92l94.62 75.23-33.58 118.06 98.62-69.46 98.62 69.46-33.85-118.06 94.62-75.23-119.41-3.57L523.1 0h-1.75l31.61 91.13 115.95 3.44-91.42 72.62-.4.41 32.65 114.63-95.29-67.13-.4-.28-95.96 67.41 32.52-114.08.14-.55-91.83-73.03 115.95-3.44L506.48 0h-1.74L473.8 89.35l-119.41 3.57z"/>
      <path d="M491.26 156.62l-11.73 41.32 34.52-24.3 34.78 24.3-11.73-41.32 33.06-26.36-41.85-1.26-14.13-40.61L500.05 129l-41.84 1.24zm10.53-25.13l12.26-35.28 12.26 35.28 36.38 1.1-28.78 22.93 10.26 36-30.12-21.15-30.12 21.15 10.26-36-28.78-22.93z" fill="url(#StarsTabletHero__d)"/>
      <path fill="url(#StarsTabletHero__e)" d="M500.59 152.77l-6.93 24.44 20.39-14.28 20.39 14.28-6.93-24.44 19.32-15.51-24.65-.69-8.27-23.88-8.26 23.88-24.65.69 19.59 15.51z"/>
      <path d="M483.26 159.09L467.4 215l46.65-32.81L560.69 215l-15.86-55.87 44.78-35.56-56.5-1.64-19.06-54.82L495 121.89l-56.51 1.64zm13.33-35.15l17.46-50.52 17.46 50.66 52.11 1.51-41.19 32.81 14.66 51.48-43-30.2-43 30.2 14.66-51.48-41.18-32.81z" fill="url(#StarsTabletHero__f)"/>
      <path fill="url(#StarsTabletHero__g)" d="M195.13 246.81L0 241.01v.57l193.27 5.78L0 400.98v.84l194.33-154.46.8-.55z"/>
      <path fill="url(#StarsTabletHero__h)" d="M137.43 265.89L0 261.77v.95l135.29 4L0 374.08v.99l136.63-108.63.8-.55z"/>
      <path fill="url(#StarsTabletHero__i)" d="M0 298.35v1.18l31.88.96L0 325.82v1.33l34.94-27.76L0 298.35z"/>
      <path fill="url(#StarsTabletHero__j)" d="M0 281.64v1.3l77.45 2.31L0 346.75v1.3l80.65-64.04L0 281.64z"/>
      <path fill="url(#StarsTabletHero__k)" d="M1024 4.58L921.99 112.41l-.66.55h73.83L1024 59.77v-1.45l-29.24 53.96h-71.84L1024 5.63V4.58z"/>
      <path fill="url(#StarsTabletHero__l)" d="M851.36 140.56L1010.51 0h-1.58L849.76 140.69l-1.07.83 166.72-3.85h.27l8.32-19.28v-2.23l-8.85 20.55-163.79 3.85z"/>
      <path fill="url(#StarsTabletHero__m)" d="M987.38 0h-2.21L770.46 168.42l253.54-8.44v-1.39l-249.41 8.32L987.38 0z"/>
      <path d="M1018.88 32.65l-51.31 55.46h18.66zM968.5 87.7l48.11-52-30.61 52z" fill="url(#StarsTabletHero__n)"/>
      <path fill="url(#StarsTabletHero__o)" d="M31.61 112.96h73.83L0 1.63v1.09l103.84 109.56H32.01L0 53.22v1.52l31.47 58.08.14.14z"/>
      <path d="M390.51 108l73.16 58.21-26 91.43L514.05 204l76.36 53.68-26.25-91.43L637.32 108 545 105.27l-31.09-89.64-31 89.64zm123.54-87.29l29.85 86.35 89 2.61-70.5 56 25.05 88-73.43-51.75-73.57 51.75 25.06-88-70.5-56 89-2.61z" fill="url(#StarsTabletHero__p)"/>
      <path fill="url(#StarsTabletHero__q)" d="M41.59 0h-2.2l212.78 166.91L0 158.5v1.4l256.3 8.52L41.59 0z"/>
      <path d="M59.19 88.11L7.88 32.51 40.54 88v.13zm-49-52.44l48.11 52H40.8z" fill="url(#StarsTabletHero__r)"/>
      <path fill="url(#StarsTabletHero__s)" d="M11.08 137.67l166.99 3.85L17.85 0h-1.6l159.16 140.56-163.79-3.85L0 109.74v2.27l10.95 25.39.13.27z"/>
      <path fill="url(#StarsTabletHero__t)" d="M1024 325.06v-1.55l-28.98-23.02 28.98-.88v-1.18l-32.31.96 32.31 25.67z"/>
      <path fill="url(#StarsTabletHero__u)" d="M1024 345.91v-1.35l-74.69-59.31 74.69-2.23v-1.3l-77.89 2.29 77.89 61.9z"/>
      <path fill="url(#StarsTabletHero__v)" d="M711.56 79.6l-147.8-4.39L537.7 0h-1.18l26.31 75.89.13.42 145.4 4.39-114.88 91.29-.27.28L634.13 316l-119.81-84.29-.27-.27L393.97 316l40.78-143.32.13-.41L319.74 80.7l145.4-4.39L491.58 0h-1.27L464.2 75.07l-147.79 4.4 117.14 93.07-41.58 146.21 122.08-85.94 122.08 85.94-41.72-146.07L711.56 79.6z"/>
      <path fill="url(#StarsTabletHero__w)" d="M832.3 247.36L1024 399.73v-.81L833.5 247.5l190.5-5.7v-.71l-192.5 5.72.8.55z"/>
      <path fill="url(#StarsTabletHero__x)" d="M890.27 266.44L1024 372.76v-.93L891.61 266.58l132.39-3.91v-.81l-134.53 4.03.8.55z"/>
      <path fill="url(#StarsTabletHero__y)" d="M138.27 386.64l86.98-71.05-19.99 56.59 1.07-.23 20.39-57.73.53-1.51-91.12 74.39 2.14-.46z"/>
      <path fill="url(#StarsTabletHero__z)" d="M184.79 376.6l.61-1.71-2.5 2.12 1.89-.41z"/>
      <path fill="url(#StarsTabletHero__A)" d="M77.53 399.75l2.77-.6L272.83 247.5l-41.06 118.96 1.42-.31 42.3-122.63L77.53 399.75z"/>
      <path fill="url(#StarsTabletHero__B)" d="M309.88 214.69l-21.19-16.89L0 191.55v1.38l288.29 6.11 20.12 16.2-43.75 144.12 1.47-.31 43.61-143.95.14-.41z"/>
      <path fill="url(#StarsTabletHero__C)" d="M0 214.11v.55l265.9 7.85-235.92 187.5.87-.19L267.36 222.1 0 214.11z"/>
      <path fill="url(#StarsTabletHero__D)" d="M339.6 204.53L85.72 2.72 177.86 0h-15.58L85.05 2.31h-.67l254.55 202.36-42.08 147.74.72-.15 42.03-147.59v-.14z"/>
      <path fill="url(#StarsTabletHero__E)" d="M369.05 195.19L158.21 27.57l266.01-7.82h.27L431.33 0h-.77l-6.6 19.06-266.68 7.96h-.93l212.03 168.45-42.97 150.78.69-.15 42.82-150.63.13-.28z"/>
      <path fill="url(#StarsTabletHero__F)" d="M813.11 46.52l.8-.55-224.43-6.73L575.87 0h-.93l13.74 39.79.13.27 222.97 6.6-176.32 140.16-.13.14 43.98 154.48.84.18-44.03-154.39L813.11 46.52z"/>
      <path fill="url(#StarsTabletHero__G)" d="M514.05 277.84l-56.73 39.94 1.9-.41 54.83-38.57 55.2 38.83 1.84.4-56.91-40.06-.13-.13z"/>
      <path fill="url(#StarsTabletHero__H)" d="M392.77 186.96L216.19 46.66l222.56-6.6h.27L452.91 0h-.77l-13.66 39.38-223.36 6.59h-.93l177.65 141.4-43.85 154.01.85-.19 43.8-153.96.13-.27z"/>
      <path fill="url(#StarsTabletHero__I)" d="M757.13 64.23l-181.91-5.36L554.82 0h-1.28l20.61 59.56.14.41 179.51 5.36L612 178.03l-.26.28 45.03 158.25 1.38.3-45.08-158L757.13 64.23z"/>
      <path fill="url(#StarsTabletHero__J)" d="M514.05 251.48l-110.53 77.91 2.7-.58 107.83-75.82 108.25 76.12 2.86.62-110.85-77.98-.26-.27z"/>
      <path fill="url(#StarsTabletHero__K)" d="M416.36 178.44L274.16 65.46l179.52-5.35L474.51 0h-1.2l-20.43 59.01-181.92 5.35 144.07 114.5-45.02 157.76 1.43-.3 44.79-157.46.13-.42z"/>
      <path fill="url(#StarsTabletHero__L)" d="M514.05 303.65l-3.88 2.72 1.97-.42 1.91-1.34 2.22 1.56 1.88.41-3.97-2.8-.13-.13z"/>
      <path fill="url(#StarsTabletHero__M)" d="M996.11 409.97l.67.14-236.05-187.6 263.27-7.78v-.53l-264.47 7.9 236.58 187.87z"/>
      <path fill="url(#StarsTabletHero__N)" d="M793.58 366.16l1.42.3-41.06-118.96 192.67 151.76 2.82.61-198.16-156.35 42.31 122.64z"/>
      <path fill="url(#StarsTabletHero__O)" d="M820.44 371.97l1.07.23-20-56.61 87.07 71.12 2.11.46-91.18-74.46 20.93 59.26z"/>
      <path fill="url(#StarsTabletHero__P)" d="M841.99 376.63l1.92.42-2.55-2.16.63 1.74z"/>
      <path fill="url(#StarsTabletHero__Q)" d="M716.89 214.69l43.74 144.34 1.47.32-43.75-144.11 20.13-16.2 285.52-6.06v-1.37l-285.92 6.19-21.19 16.89z"/>
      <path fill="url(#StarsTabletHero__R)" d="M943.85 2.31L866.46 0h-16.13l92.18 2.72-253.74 201.67-.14.14v.14l42.12 147.9.58.12-42.16-148.02L943.85 2.31z"/>
      <path fill="url(#StarsTabletHero__S)" d="M870.95 27.57l.8-.55-267.74-7.96L597.4 0h-.63l6.7 19.34.14.27 266.27 7.96-210.7 167.49-.13.13 43 151.17.71.15-43.05-151.04 211.24-167.9z"/>
    </svg>
    
  )
}

export default StarsTabletHero
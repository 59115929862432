import './style.css'

import React, { Fragment } from 'react'
import { useConfiguration, useIsDesktop, useIsMobile, useIsTablet } from '../../hooks'

import Hero from '../../heros'
import Image from '../Image'
import { Link } from 'react-router-dom'
import SocialBar from '../SocialBar'
import defaultLogo from './default-logo.jpg'

const FansHeader = ({organization}) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()
  const configuration = useConfiguration()

  return (
    <div className="fans-header">
      <div className="fans-header-background-container">
        <Hero
          name={configuration.hero_image || 'default'}
          backgroundA={configuration.style && configuration.style.background_a}
          backgroundB={configuration.style && configuration.style.background_b}
          backgroundC={configuration.style && configuration.style.background_c}
          colorA={configuration.style && configuration.style.color_a}
          colorB={configuration.style && configuration.style.color_b}
          colorC={configuration.style && configuration.style.color_c}
          />
      </div>

      <div className="fans-header-content flex-direction-column">
        <div className="fans-header-league flex flex-align-center">
          <Link to="/">
            {(configuration.logo_vertical && configuration.logo_vertical !== '') ?
              <Fragment>
                {isMobile && <Image className="fans-header-logo" src={process.env.REACT_APP_IMAGES_BASE_URL + '/logos/' + configuration.logo_vertical} defaultImage={defaultLogo} />}
                {(isDesktop || isTablet) && <Image className="fans-header-logo" src={process.env.REACT_APP_IMAGES_BASE_URL + '/logos/' + configuration.logo_horizontal} defaultImage={defaultLogo} />}
              </Fragment>
            :
              <div className="fans-header-logo-container">
                <Image className="fans-header-logo" src={process.env.REACT_APP_IMAGES_BASE_URL + '/logos/' + configuration.logo_horizontal} defaultImage={defaultLogo} />
                <div className="fans-header-title">{configuration.name}</div>
              </div>
            }
          </Link>
          <div className="fans-header-league-text"></div>

          { (isTablet || isDesktop) &&
            <SocialBar
                facebook={organization.facebook}
                instagram={organization.instagram}
                twitter={organization.twitter}
                youtube={organization.youtube}
              />
          }
        </div>
      </div>
    </div>
  )
}

export default FansHeader

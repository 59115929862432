import "./style.css"

import React, { Fragment } from "react"

import Image from "../Image"

const STAFF_TYPES = {
	1: "DIRECTOR TECNICO",
	2: "AUXILIAR TECNICO",
	3: "DOCTOR",
	4: "PREPARADOR FISICO",
	5: "PREPARADOR DE PORTEROS",
	6: "FISIOTERAPEUTA O KINESIOLOGO",
	7: "UTILERO",
	8: "DELEGADO"
}

const TeamDetailsTechnical = ({ technicalDetail, teamDetails }) => {

  return (
    <Fragment>
      <div className="team-detail-technical-grid-container">
        <div className="team-detail-technical-card-background">
          <div className={'team-detail-technical-card-container'}>
            <div className="team-detail-technical-avatar-container">
              <Image
                className="team-detail-technical-avatar-image"
                src={technicalDetail.image}
                defaultImage={
                  <img className="team-detail-technical-avatar-image" src="/assets/playerImagePlaceholder.png" alt="" />
                }
              />
            </div>
            <span className="team-detail-technical-name">
              {technicalDetail.firstname}{' '}{technicalDetail.lastname}
            </span>
            <span className="team-detail-technical-team">{teamDetails.name}</span>
            <div className="team-detail-technical-separation-line" />
            <span className="team-detail-technical-position">{STAFF_TYPES[technicalDetail.type]}</span>
            <div className="team-detail-technical-fanstext-container">{'FANS'}</div>
            <div className="team-detail-technical-shield-container">
              <Image src={teamDetails.logo} defaultImage={<span className="icon-teams rp-team-badge -small" />} />
            </div>
            <div className="team-detail-technical-shield-background-container">
              <Image src={teamDetails.logo} defaultImage={<span className="icon-teams rp-team-badge -big" />} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TeamDetailsTechnical

import React from 'react'
import './style.scss'

const SocialBar = ({ facebook, twitter, instagram, youtube }) => {
  return (
    <div className="social-bar">
      {facebook && facebook.length > 0 && (
        <a href={"https://www.facebook.com/" + facebook} target="_blank" rel="noopener noreferrer" className="-button -facebook">
          <span className="icon-facebook"></span>
        </a>
      )}

      {twitter && twitter.length > 0 && (
        <a href={"https://twitter.com/" + twitter} target="_blank" rel="noopener noreferrer" className="-button -twitter">
          <span className="icon-twitter"></span>
        </a>
      )}

      {instagram && instagram.length > 0 && (
        <a href={"https://www.instagram.com/" + instagram} target="_blank" rel="noopener noreferrer" className="-button -instagram">
          <span className="icon-instagram"></span>
        </a>
      )}

      {youtube && youtube.length > 0 && (
        <a href={"https://www.youtube.com/" + youtube} target="_blank" rel="noopener noreferrer" className="-button -youtube">
          <span className="icon-youtube"></span>
        </a>
      )}
    </div>
  )
}

export default SocialBar

import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import { useIsDesktop, useIsMobile, useIsTablet, useConfiguration } from '../../hooks'
import CompetitionDropdown from '../CompetitionDropdown'
import LeagueSelector from '../LeagueSelector'
import Modal from '../Modal'
import SocialBar from '../SocialBar'
import Image from '../Image'
import defaultLogo from '../FansHeader/default-logo.jpg'
import './style.css'

const FansMainMenu = ({ history, organization, competitions, competitionSelected }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [competitionsMenu, setCompetitionsMenu] = useState(false)
  const shortcutIcon = useConfiguration('shortcut_icon')
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const competitionOptions = Object.values(competitions).map(competition => {
    return {
      label: competition.name,
      value: competition.competition_id
    }
  })

  const handleClick = (competition_id, season_id = false) => {
    setMenuVisible(false)
    if (season_id) {
      history.push('/competicion/' + competition_id + '/temporada/' + season_id)
    } else {
      history.push('/competicion/' + competition_id)
    }
  }

  const toggleCompetitionsMenu = () => {
    setCompetitionsMenu(!competitionsMenu)
  }

  const MobileMenu = () => {
    const [constantMenu, setConstantMenu] = useState()

    useEffect(() => {
      const handleScroll = () => {
        const y = window.pageYOffset
        if (y >= 150) {
          setConstantMenu(true)
        } else if (y < 150) {
          setConstantMenu(false)
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])

    return (
      <div className={classNames("main-menu-header", { '-dark': constantMenu })}>
        <div className={classNames("main-menu-header-title", { '-dark': constantMenu })}>
          <Image className="-logo" src={process.env.REACT_APP_IMAGES_BASE_URL + '/icons/' + shortcutIcon} defaultImage={defaultLogo} />
          <span className="-title">{organization.name}</span>
          <div className="main-menu-button icon-menu" onClick={() => setMenuVisible(true)}></div>
        </div>
      </div>
    )
  }

  const TabletAndDesktopMenu = () => {
    return (
      <div className="rf-content-spacing rf-home-menu-container -bg-dark-blur">
        <nav className="rf-home-menu-subcontainer flex flex-1">
          <div className="rf-home-menu flex-1 flex-align-center">
            <NavLink exact activeClassName="-active" className="rf-home-menu--element" to="/">
              Inicio
            </NavLink>
            <CompetitionDropdown
              className={classNames({ '-active': competitionSelected })}
              competitions={competitionOptions}
            />
            <NavLink exact activeClassName="-active" className="rf-home-menu--element" to="/noticias">
              Noticias
            </NavLink>
            <NavLink exact activeClassName="-active" className="rf-home-menu--element" to="/documentos">
              Documentos
            </NavLink>
            <NavLink exact activeClassName="-active" className="rf-home-menu--element" to="/contacto">
              Quiénes somos
            </NavLink>
          </div>
        </nav>
      </div>
    )
  }

  return (
    <Fragment>
      {(isTablet || isDesktop) && <TabletAndDesktopMenu />}
      {isMobile && <MobileMenu />}
      <Modal isOpen={menuVisible} onRequestClose={() => setMenuVisible(false)} className="-slide-left side-main-menu -custom-modal">
        <div className="side-main-menu-header">
          <Image className="-logo" src={process.env.REACT_APP_IMAGES_BASE_URL + '/icons/' + shortcutIcon} defaultImage={defaultLogo} />
          <span className="-title">{organization.name}</span>
          <div className="-close icon-cancel" onClick={() => setMenuVisible(false)}></div>
        </div>
        <div className="-menu">
          <div
            className="-menu-item"
            onClick={() => {
              setMenuVisible(false)
              history.push('/')
            }}
          >
            Inicio
          </div>
          <div className="-menu-item -dropdown" onClick={() => toggleCompetitionsMenu()}>
            Competiciones <span className="icon-down-open-mini" />
          </div>
          <div className={classNames('side-main-menu-competitions', { '-visible': competitionsMenu })}>
            <LeagueSelector competitions={competitions} onClick={handleClick} className="-dropdown" />
          </div>
          <div
            className="-menu-item"
            onClick={() => {
              setMenuVisible(false)
              history.push('/noticias')
            }}
          >
            Noticias
          </div>
          <div
            className="-menu-item"
            onClick={() => {
              setMenuVisible(false)
              history.push('/documentos')
            }}
          >
            Documentos
          </div>
          <div
            className="-menu-item"
            onClick={() => {
              setMenuVisible(false)
              history.push('/contacto')
            }}>
              Quiénes somos
          </div>

          <div className="-social">
            <SocialBar
              facebook={organization.facebook}
              instagram={organization.instagram}
              twitter={organization.twitter}
              youtube={organization.youtube}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default withRouter(FansMainMenu)

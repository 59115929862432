import React, { useState } from 'react'
import { components } from 'react-select'
import { withRouter } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import Image from '../Image'
import './style.css'

const SearchTeams = ({ history }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  // TODO: Remove this temporal code
  const colourOptions = []

  const searchTeam = (term) => {
    return colourOptions.filter(i =>
      i.label.toLowerCase().includes(term.toLowerCase())
    )
  }

  const handleInputChange = (term) => {
    if (term.length >= 3) {
      setMenuOpen(true)
    } else {
      setMenuOpen(false)
    }
  }

  const loadOptions = (term, callback) => {
    new Promise(() => {
      if (menuOpen) {
        const results = searchTeam(term)
        callback(results)
      } else {
        callback([])
      }
    })
  }

  const colourStyles = {
    option: () => {
      return {}
    },
    valueContainer: (styles) => {
      // eslint-disable-next-line
      const { padding, ...rest } = styles
      return rest
    },
    input: (styles) => {
      // eslint-disable-next-line
      const { paddingBottom, paddingTop, margin, ...rest } = styles
      return rest
    }
  }

  const DropdownIndicator = () => null
  const IndicatorSeparator = () => null

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <div className="search-teams-option" onClick={() => history.push('/competicion/' + props.data.competitionId) }>
            <div className="-team">
              <div className="-icon"><Image src={props.data.teamImage} defaultImage={<span className="icon-teams" />} /></div>
              <div className="-name">{props.data.teamName.toLowerCase()}</div>
            </div>
            <div className="-competition">
              <div className="-icon"><Image src={props.data.competitionImage} defaultImage={<span className="icon-competition" />} /></div>
              <div className="-name">{props.data.competitionName.toLowerCase()}</div>
              <span className="icon-right-open-mini -arrow" />
            </div>
          </div>
        </components.Option>
      </div>
    )
  }

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <div className="-title">Sin resultados</div>
        <div className="-description">Prueba buscando el nombre corto de tu equipo.</div>
      </components.NoOptionsMessage>
    )
  }

  return (
    <div className="search-teams">
      <div className="-title">Busca tu equipo</div>
      <div className="-description">Puedes buscar por nombre del equipo, género de jugadores y/o la categoría o dvisión. Considera que puede haber varios equipos con el mismo nombre jugando en competiciones distintas.</div>
      <div>
        <AsyncSelect
          className="search-teams-select"
          classNamePrefix="search-teams-select"
          styles={colourStyles}
          components={{ DropdownIndicator, Option, IndicatorSeparator, NoOptionsMessage }}
          placeholder="Ejemplo: halcones, masculino"
          menuIsOpen={menuOpen}
          onInputChange={handleInputChange}
          loadOptions={loadOptions} />
        <span className="-search-icon icon-search" />
      </div>
    </div>
  )
}

export default withRouter(SearchTeams)
import * as React from 'react'

const StarsDesktopHero = ({ backgroundA, backgroundB, backgroundC, colorA, colorB, colorC }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2560" viewBox="0 0 2562 570">
      <defs>
        <linearGradient id="StarsDesktopHero__b" x1="635.76" y1="-463.18" x2="2037.27" y2="1161.92" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={backgroundA || "#1F5BFF"}/>
          <stop offset=".48" stopColor={backgroundB || "#041C5D"}/>
          <stop offset="1" stopColor={backgroundC || "#790461"}/>
        </linearGradient>
        <linearGradient id="StarsDesktopHero__a" x1="788.54" y1="-599.97" x2="2250.05" y2="926.98" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={colorA || "#5CA2FF"}/>
          <stop offset=".48" stopColor={colorB || "#1248DB"}/>
          <stop offset="1" stopColor={colorC || "#DF0FB3"}/>
        </linearGradient>
        <linearGradient id="StarsDesktopHero__c" x1="535.19" y1="-357.47" x2="1996.69" y2="1169.47" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__d" x1="553.39" y1="-374.89" x2="2014.9" y2="1152.05" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__e" x1="678.5" y1="-494.64" x2="2140.01" y2="1032.3" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__f" x1="-183.69" y1="330.6" x2="1277.81" y2="1857.54" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__g" x1="551.95" y1="-373.51" x2="2013.45" y2="1153.43" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__h" x1="264.24" y1="-98.13" x2="1725.74" y2="1428.81" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__i" x1="280.5" y1="-113.69" x2="1742" y2="1413.25" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__j" x1="247.51" y1="-82.12" x2="1709.01" y2="1444.82" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__k" x1="674.38" y1="-490.69" x2="2135.88" y2="1036.25" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__l" x1="679.47" y1="-495.57" x2="2140.97" y2="1031.37" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__m" x1="675.81" y1="-492.06" x2="2137.31" y2="1034.88" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__n" x1="675.43" y1="-491.7" x2="2136.93" y2="1035.24" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__o" x1="543.6" y1="-365.52" x2="2005.1" y2="1161.42" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__p" x1="680.98" y1="-497.01" x2="2142.49" y2="1029.93" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__q" x1="677.34" y1="-493.53" x2="2138.84" y2="1033.41" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__r" x1="179.86" y1="-17.36" x2="1641.36" y2="1509.58" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__s" x1="-1.13" y1="155.86" x2="1460.38" y2="1682.8" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__t" x1=".52" y1="154.29" x2="1462.02" y2="1681.23" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__u" x1="-56.06" y1="208.44" x2="1405.44" y2="1735.38" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__v" x1="9.34" y1="145.84" x2="1470.84" y2="1672.78" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__w" x1="-.11" y1="154.89" x2="1461.39" y2="1681.83" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__x" x1="-56.04" y1="208.42" x2="1405.46" y2="1735.37" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__y" x1="75.03" y1="82.97" x2="1536.53" y2="1609.91" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__z" x1="-56.14" y1="208.51" x2="1405.36" y2="1735.46" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__A" x1="1.1" y1="153.73" x2="1462.6" y2="1680.67" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__B" x1="811.34" y1="-621.78" x2="2272.84" y2="905.16" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__C" x1="75.58" y1="82.44" x2="1537.08" y2="1609.39" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__D" x1="23.27" y1="132.51" x2="1484.77" y2="1659.45" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__E" x1="76.36" y1="81.69" x2="1537.86" y2="1608.64" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__F" x1="218.29" y1="-54.15" x2="1679.79" y2="1472.79" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__G" x1="-158.78" y1="306.76" x2="1302.72" y2="1833.7" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__H" x1="-118.42" y1="268.13" x2="1343.08" y2="1795.07" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__I" x1="37.42" y1="118.97" x2="1498.92" y2="1645.91" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__J" x1="-138.53" y1="287.38" x2="1322.97" y2="1814.32" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__K" x1="82.33" y1="75.98" x2="1543.83" y2="1602.92" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__L" x1="1271.17" y1="-1061.9" x2="2732.67" y2="465.04" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__M" x1="1139.39" y1="-935.77" x2="2600.89" y2="591.17" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__N" x1="765.37" y1="-577.78" x2="2226.87" y2="949.16" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__O" x1="1097.87" y1="-896.03" x2="2559.37" y2="630.91" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__P" x1="956.66" y1="-760.87" x2="2418.16" y2="766.07" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__Q" x1="1019.52" y1="-821.04" x2="2481.02" y2="705.9" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__R" x1="773.31" y1="-585.38" x2="2234.81" y2="941.56" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__S" x1="1244.16" y1="-1036.06" x2="2705.66" y2="490.89" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__T" x1="1048.65" y1="-848.92" x2="2510.15" y2="678.02" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__U" x1="783.29" y1="-594.94" x2="2244.79" y2="932" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__V" x1="480.57" y1="-305.19" x2="1942.07" y2="1221.75" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__W" x1="353.5" y1="-183.56" x2="1815" y2="1343.38" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__X" x1="367.74" y1="-197.2" x2="1829.24" y2="1329.75" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__Y" x1="1079.13" y1="-878.1" x2="2540.64" y2="648.84" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__Z" x1="1141.1" y1="-937.42" x2="2602.61" y2="589.53" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__aa" x1="372.49" y1="-201.74" x2="1833.99" y2="1325.2" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ab" x1="1020.35" y1="-821.84" x2="2481.86" y2="705.1" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ac" x1="1050.56" y1="-850.75" x2="2512.06" y2="676.19" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ad" x1="367.51" y1="-196.97" x2="1829.01" y2="1329.97" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ae" x1="371.6" y1="-200.89" x2="1833.1" y2="1326.06" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__af" x1="266.74" y1="-100.52" x2="1728.24" y2="1426.42" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ag" x1="979.81" y1="-783.04" x2="2441.31" y2="743.9" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ah" x1="1074.35" y1="-873.52" x2="2535.85" y2="653.42" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ai" x1="979.81" y1="-783.03" x2="2441.31" y2="743.91" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__aj" x1="1037.15" y1="-837.91" x2="2498.65" y2="689.03" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ak" x1="1072.11" y1="-871.38" x2="2533.61" y2="655.57" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__al" x1="831.27" y1="-640.86" x2="2292.77" y2="886.08" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__am" x1="458.02" y1="-283.61" x2="1919.53" y2="1243.33" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__an" x1="855.74" y1="-664.28" x2="2317.24" y2="862.66" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ao" x1="1035.63" y1="-836.46" x2="2497.13" y2="690.48" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ap" x1="430.11" y1="-256.9" x2="1891.62" y2="1270.05" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__aq" x1="1034.71" y1="-835.59" x2="2496.22" y2="691.36" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ar" x1="1075.59" y1="-874.71" x2="2537.1" y2="652.23" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__as" x1="1142.92" y1="-939.15" x2="2604.42" y2="587.79" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__at" x1="1222.78" y1="-1015.6" x2="2684.29" y2="511.35" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__au" x1="1144.76" y1="-940.92" x2="2606.26" y2="586.03" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__av" x1="979.73" y1="-782.96" x2="2441.23" y2="743.99" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__aw" x1="1059.75" y1="-859.55" x2="2521.25" y2="667.4" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ax" x1="1036.42" y1="-837.22" x2="2497.92" y2="689.72" href="#StarsDesktopHero__a"/>
        <linearGradient id="StarsDesktopHero__ay" x1="1045.43" y1="-845.84" x2="2506.93" y2="681.1" href="#StarsDesktopHero__a"/>
      </defs>
      <path d="M404 570H0V0h2562v570h-402l-877-189.67z" fill="url(#StarsDesktopHero__b)"/>
      <path fill="url(#StarsDesktopHero__a)" d="M1640.25 5.25L1462.01 0h-56.61l229.96 6.86-208.2 165.49-.4.4 72.38 254.33 2.04.44-72.46-253.96L1640.25 5.25z"/>
      <path fill="url(#StarsDesktopHero__c)" d="M1283.33 280.19l-204.58 144.22 3.8-.83 200.78-141.18 201.28 141.53 4.18.91-205.07-144.25-.39-.4z"/>
      <path fill="url(#StarsDesktopHero__d)" d="M1139.9 172.95L931.1 7.06 1167.83 0h-56.33L926.41 5.45l211.53 168.11-72.38 253.69 2.1-.45 72.04-253.24.2-.61z"/>
      <path d="M1172.38 92.32l65.75 52.21-23.29 82 68.49-48.17 68.49 48.17-23.29-82 65.75-52.21-83-2.41-28-80.43-28 80.43zm110.95-73.57L1309 93.13l76.52 2.22-60.52 48.17 21.53 75.59-63.21-44.34-63.2 44.34 21.52-75.59-60.47-48.17 76.52-2.42z" fill="url(#StarsDesktopHero__e)"/>
      <path fill="url(#StarsDesktopHero__f)" d="M0 507.99l32.93 26.17L22.71 570h.78l10.22-35.84v-.2L0 507.17v.82z"/>
      <path fill="url(#StarsDesktopHero__g)" d="M1283.33 356.78l-48.07 33.85 2.83-.61 45.24-31.83 45.58 32.07 2.72.59-48.1-33.86-.2-.21z"/>
      <path fill="url(#StarsDesktopHero__h)" d="M862.22 370.09L694.01 507.42l3.17-.68 162.11-132.42-37.27 105.48 1.59-.34 37.83-107.15.78-2.22z"/>
      <path fill="url(#StarsDesktopHero__i)" d="M606.35 526.34l3.96-.86 318.84-251.14-68 197.02 2.09-.45 69.82-202.41-326.71 257.84z"/>
      <path fill="url(#StarsDesktopHero__j)" d="M766.42 491.8l32.21-27.18-7.91 21.93 1.09-.23 8.97-24.92-36.54 30.87 2.18-.47z"/>
      <path fill="url(#StarsDesktopHero__k)" d="M1165.14 164.28l-61.05 214.68 179.24-126.19 179.24 126.19-61.24-214.47 172-136.67-217.01-6.45L1348.91 0h-1.72l7.76 22.38.2.6 213.48 6.45-168.67 134.05-.4.4 60.08 211.05-175.92-123.77-.39-.4-176.31 124.17 59.88-210.44.2-.61L998.03 29.43l213.49-6.45L1219.48 0h-1.97l-7.36 21.17-217.02 6.45 172.01 136.66z"/>
      <path d="M1249.87 140.9l-17.22 60.68 50.68-35.68 51.07 35.68-17.22-60.68 48.53-38.7-61.44-1.81-20.74-59.67-20.75 59.67-61.44 1.81zm15.46-36.9l18-51.81 18 51.81 53.42 1.61-42.26 33.66 15.06 52.81-44.22-31-44.22 31 15.06-52.81-42.26-33.66z" fill="url(#StarsDesktopHero__l)"/>
      <path fill="url(#StarsDesktopHero__m)" d="M1187.84 157.84l-49.31 173.35 144.8-102 144.8 102-49.7-173.35 138.93-110.47-175.33-5.24L1327.44 0h-2.5l15.53 44.75 170.24 5.04-134.24 106.63-.58.61 47.94 168.31-139.91-98.57-.59-.4-140.89 98.97 47.75-167.5.19-.81-134.82-107.24 170.24-5.04L1241.32 0h-2.5l-14.59 42.13-175.33 5.24 138.94 110.47z"/>
      <path fill="url(#StarsDesktopHero__n)" d="M1101.93 69.55l107.43 85.46-38.16 134.25 112.13-78.82 112.13 78.82-38.55-134.25 107.43-85.46-135.61-4.03L1306.03 0h-2.42l23.55 68.14 130.72 3.83-103.52 82.24 36.79 129.2-107.82-75.99-108.02 75.99 36.79-129.2-103.51-82.24 130.71-3.83L1262.96 0h-2.73l-22.69 65.52-135.61 4.03z"/>
      <path fill="url(#StarsDesktopHero__o)" d="M1283.33 318.89l-125.68 88.49 2.74-.59 122.94-86.49 123.35 86.78 2.68.58-125.83-88.57-.2-.2z"/>
      <path fill="url(#StarsDesktopHero__p)" d="M1263.57 135.26l-10.18 35.88 29.94-20.96 29.94 20.96-10.18-35.88 28.38-22.78-36.2-1.01-12.14-35.07-12.13 35.07-36.2 1.01 28.77 22.78z"/>
      <path fill="url(#StarsDesktopHero__q)" d="M1138.92 81.04l85.51 67.93-30.33 106.83 89.23-62.69 89.04 62.69-30.34-106.83 85.71-67.93-108.01-3.23L1292.71 0h-3.32l27.6 79.83.39 1 101.56 3.03-80.43 63.9 28.57 100.38-82.97-58.46-.78-.6-83.75 59.06 28.57-100.38-80.43-63.9 101.37-3.03L1277.23 0h-3.28l-27.02 77.81-108.01 3.23z"/>
      <path fill="url(#StarsDesktopHero__r)" d="M729.16 302.16l1.18-.81-329.53-9.87L299.56 0h-.88l100.95 292.48.2.41 327.37 9.67-259.08 205.6-.19.2 12.84 45.07 1.2-.25-12.67-44.41 259.86-206.61z"/>
      <path d="M358.54 549.28l-23.28-81.83L401 415.24l-83-2.42-28-80.43-28 80.43-83 2.42 65.75 52-23.29 82 68.49-48.17zM226.65 542l21.53-75.59-60.47-48.18 76.51-2.23 25.64-74.17L315.49 416l76.51 2.26-60.46 48.18L353.06 542l-63.2-44.35z" fill="url(#StarsDesktopHero__s)"/>
      <path fill="url(#StarsDesktopHero__t)" d="M434.46 403.75l-108.01-3.23-36.4-104.81-36.39 104.81-108.02 3.23 85.51 67.93L203.24 570h3.17l28.27-99.33-80.43-63.9 101.36-3.02 34.25-98.57 33.65 97.56.39 1.01 101.56 3.02-80.42 63.9L373.31 570h3.3l-27.85-98.12 85.7-68.13z"/>
      <path fill="url(#StarsDesktopHero__u)" d="M233.38 570h4.06l52.42-36.84L342.14 570h4.48l-56.57-39.87L233.38 570z"/>
      <path fill="url(#StarsDesktopHero__v)" d="M470.86 392.26l-135.61-4.03-45.59-131.63-45.59 131.63-135.61 4.03 107.43 85.47L189.66 570h2.66l26.5-93.08-103.51-82.24 130.71-3.83 44.03-126.79 43.83 126.79 130.72 3.83-103.52 82.24L387.59 570h2.36l-26.32-92.27 107.23-85.47z"/>
      <path d="M323.51 463.62l48.92-38.71L311 423.1l-20.74-59.66-20.75 59.66-61.45 1.81 48.53 38.71-17.22 60.67 50.68-35.68 50.68 35.68zm-77.68 51.19L260.9 462l-42.27-33.66 53.42-1.61 18-51.81 18 52 53.43 1.61-42.27 33.67 15.07 52.8-44.23-31z" fill="url(#StarsDesktopHero__w)"/>
      <path fill="url(#StarsDesktopHero__x)" d="M290.05 549.08L260.31 570h4.14l25.41-17.9 25.4 17.9h4.5l-29.12-20.52-.59-.4z"/>
      <path fill="url(#StarsDesktopHero__y)" d="M131.55 223.75L209.1 0h-.52l-77.23 222.94L0 226.87v.76l131.35-3.88h.2z"/>
      <path fill="url(#StarsDesktopHero__z)" d="M289.86 511.99L207.6 570h5.34l77.11-54.18 76.7 54.18h5.36l-81.47-57.4-.78-.61z"/>
      <path fill="url(#StarsDesktopHero__A)" d="M309.42 458.58l28.77-22.78-36.2-1.01-12.13-35.07-12.14 35.07-36.2 1.01 28.77 22.78-10.18 35.87 29.94-21.16 29.55 21.16-10.18-35.87z"/>
      <path fill="url(#StarsDesktopHero__B)" d="M1696.33 0h-1.71L1461.6 185.25l-.2.2 70.83 248.78 1.25.27-70.91-248.65L1696.33 0z"/>
      <path fill="url(#StarsDesktopHero__C)" d="M158.16 262.45L249.14 0h-.92l-90.84 261.64L0 266.34v.91l157.77-4.8h.39z"/>
      <path fill="url(#StarsDesktopHero__D)" d="M523.89 370.29l-175.33-5.24-58.9-170.13-58.9 170.13-175.33 5.24 138.93 110.46L168.97 570h2.64l25.49-89.45.2-.81L62.48 372.51l170.24-5.04 57.33-165.29 57.34 165.29 170.24 5.04-134.24 106.63-.58.6 25.34 89.36 2.02-.43-25.21-87.92 138.93-110.46z"/>
      <path fill="url(#StarsDesktopHero__E)" d="M179.69 292.48L281.37 0h-1.3L178.91 291.68 0 296.95v.84l179.3-5.31h.39z"/>
      <path fill="url(#StarsDesktopHero__F)" d="M813.89 274.14l1.18-.81-393.13-11.69L331.3 0h-1.07l90.93 262.05.19.4 390.98 11.69-309.38 245.91-.19.21 7.63 26.78.81-.17-7.46-26.21 310.15-246.52z"/>
      <path fill="url(#StarsDesktopHero__G)" d="M0 460.49l75.98 60.37L61.97 570h.81l13.98-49.14.19-.4L0 459.28v1.21z"/>
      <path fill="url(#StarsDesktopHero__H)" d="M0 381.46l144.47 114.81L123.45 570h1.8l20.98-73.73.19-.6L0 379.33v2.13z"/>
      <path fill="url(#StarsDesktopHero__I)" d="M579.86 350.54l-217.01-6.46-72.99-210.64-73.19 210.64L0 350.52v.28l171.86 136.4-23.55 82.8h1.61l23.51-82.6.19-.6L4.55 352.35l213.49-6.45 71.82-207.21 71.62 206.4.19.61 213.69 6.45-168.68 134.04-.39.4 22.23 78.12 1.43-.31-22.1-77.2 172.01-136.66z"/>
      <path fill="url(#StarsDesktopHero__J)" d="M0 420.88l110.61 87.89L93.18 570h.74l17.47-61.43.2-.41L0 419.43v1.45z"/>
      <path fill="url(#StarsDesktopHero__K)" d="M646.98 327.96l-267.11-7.86-89.82-259.22-90.01 259.42L0 326.19v1.72l201.21-6 88.84-255.79 88.45 255.19.2.6 263.58 7.86-208.4 165.49-.39.41 18.22 64.04 1.53-.33-17.99-63.31 211.73-168.11z"/>
      <path fill="url(#StarsDesktopHero__L)" d="M2433.54 222.94L2356.32 0h-.91l77.55 223.75h.19l128.85 3.81v-.77l-128.46-3.85z"/>
      <path fill="url(#StarsDesktopHero__M)" d="M2530.8 534.16l10.22 35.84h1.16l-10.21-35.84 30.03-23.87v-1.13l-31.2 24.8v.2z"/>
      <path fill="url(#StarsDesktopHero__N)" d="M1772.87 486.28l1.1.23-7.89-21.89 32.17 27.14 2.23.49-36.56-30.85 8.95 24.88z"/>
      <path fill="url(#StarsDesktopHero__O)" d="M1976.63 40.32L2000.37 0h-.74l-23.39 39.72h-25.64L1987.32 0h-.79l-37.3 40.32h27.4z"/>
      <path fill="url(#StarsDesktopHero__P)" d="M1612.86 203.19l475.11-10.08h.59L2158.2 0h-2.08l-68.93 191.09-474.92 10.28-31.11 24.8 70.91 233.98 2.16.47-70.92-233.65 29.55-23.78z"/>
      <path fill="url(#StarsDesktopHero__Q)" d="M1645.54 237.66l470.81-13.91v-.2L2193.86 0h-.61l-77.3 222.94-472.17 14.11 374.14 297.11-1.37 4.82.37.08 1.39-4.9v-.2l-.2-.2-372.57-296.1z"/>
      <path fill="url(#StarsDesktopHero__R)" d="M1741.07 479.4l1.59.34-37.25-105.42 162.13 132.43 3.12.67-168.18-137.33 38.59 109.31z"/>
      <path fill="url(#StarsDesktopHero__S)" d="M2316.29 0h-.77l91.02 262.45h.39l155.07 4.72v-.9l-154.87-4.63L2316.29 0z"/>
      <path fill="url(#StarsDesktopHero__T)" d="M1752.38 274.34l390.97-11.69.2-.4 91-262.25h-1.35l-90.63 261.64-393.13 11.69 1.18.81 310.15 246.52-7.48 26.26 1.07.23 7.59-26.69-.2-.2-309.37-245.92z"/>
      <path fill="url(#StarsDesktopHero__U)" d="M1701.44 470.83l2.09.45-67.97-196.94 318.92 251.21 4.07.88-326.91-257.93 69.8 202.33z"/>
      <path fill="url(#StarsDesktopHero__V)" d="M1105.26 185.45L871.85 0h-1.71l233.75 186.06-70.67 248.17 1.26-.27 70.58-248.11.2-.4z"/>
      <path fill="url(#StarsDesktopHero__W)" d="M983.55 226.17l-31.12-24.8-474.92-10.28L408.58 0h-2.06l69.43 192.5.19.61 475.71 10.08 29.54 23.78-70.95 233.75 2.16-.46 70.75-233.49.2-.6z"/>
      <path fill="url(#StarsDesktopHero__X)" d="M544.83 113.09l245.19 5.64L655.59 0h-2.33L786.1 117.32l-240.49-5.65L497.51 0h-1.47l48.59 112.68.2.41z"/>
      <path fill="url(#StarsDesktopHero__Y)" d="M1881.33 76.8h108.41L2031.39 0h-1.16l-41.08 75.8h-105.47L1955.52 0h-1.32l-71.89 76-.98.8z"/>
      <path fill="url(#StarsDesktopHero__Z)" d="M2487.94 520.86l13.97 49.14h.62l-14-49.14 73.47-58.37v-1.06l-74.25 59.03.19.4z"/>
      <path fill="url(#StarsDesktopHero__aa)" d="M516.85 145.34l388.03 12.9L703.16 0h-3.24l198.9 156.02-380.6-12.7L461.25 0h-2.11l57.51 144.73.2.61z"/>
      <path fill="url(#StarsDesktopHero__ab)" d="M2047.27 145.34h.59L2105.58 0h-2.12l-56.97 143.32-380.6 12.7L1864.78 0h-3.23l-201.73 158.24 387.45-12.9z"/>
      <path fill="url(#StarsDesktopHero__ac)" d="M1774.69 118.73l244.79-5.64h.39L2068.65 0h-1.46l-48.1 111.67-240.49 5.65L1911.44 0h-2.23l-132.96 117.52-1.56 1.21z"/>
      <path fill="url(#StarsDesktopHero__ad)" d="M574.96 76.8h108.41L610.63 0h-1.45l71.84 75.8H575.55L534.47 0h-1.21l41.51 76.6.19.2z"/>
      <path fill="url(#StarsDesktopHero__ae)" d="M588.08 40.32h27.39L578.26 0h-.88l36.72 39.72h-25.63L565.07 0h-.61l23.62 40.12v.2z"/>
      <path fill="url(#StarsDesktopHero__af)" d="M921.12 237.05l-472.18-14.11L371.65 0h-1l77.51 223.55v.2l470.81 13.91-372.58 296.1-.19.2v.2l1.38 4.86.55-.12-1.34-4.74 374.33-297.11z"/>
      <path fill="url(#StarsDesktopHero__ag)" d="M2274.26 549.48L2245.14 570h4.3l25.41-17.9 25.41 17.9h4.33l-29.74-20.92-.59.4z"/>
      <path fill="url(#StarsDesktopHero__ah)" d="M2274.65 133.44l-72.99 210.64-217.01 6.46 172.01 136.66-22.06 77.31 1.73.37 22.28-78.29-.39-.4-168.68-134.04 213.49-6.45.2-.61 71.62-206.4 71.81 207.21 213.49 6.45-169.07 134.45.2.6 23.5 82.6h1.61l-23.55-82.8L2562 352.8v-2.35l-214.16-6.37-73.19-210.64z"/>
      <path fill="url(#StarsDesktopHero__ai)" d="M2274.06 512.6l-81.47 57.4h5.55l76.71-54.18 77.12 54.18h5.14l-82.26-58.01-.79.61z"/>
      <path fill="url(#StarsDesktopHero__aj)" d="M2294.61 458.58l28.77-22.78-36.2-1.01-12.14-35.07-12.13 35.07-36.2 1.01 28.77 22.78-10.18 35.87 29.55-21.16 29.94 21.16-10.18-35.87z"/>
      <path fill="url(#StarsDesktopHero__ak)" d="M1837.7 302.36l327.37-9.68.2-.4L2266.16 0h-1l-101.07 291.48-329.53 9.87 1.18.81 259.86 206.61-12.71 44.55 1.02.22 12.87-45.18-.2-.2-258.88-205.8z"/>
      <path fill="url(#StarsDesktopHero__al)" d="M1746.25 0h-1.56l-248.26 197.34-.2.2 69.38 243.91 1.07.23-69.47-243.73L1746.25 0z"/>
      <path fill="url(#StarsDesktopHero__am)" d="M1070.43 197.54L821.96 0h-1.68l249.17 197.95-69.38 243.43 1.03-.22 69.13-243.21.2-.41z"/>
      <path fill="url(#StarsDesktopHero__an)" d="M1806.59 0h-1.16l-265.56 211.05-.2.2v.2l68.09 239.12.85.18-68.15-239.3L1806.59 0z"/>
      <path d="M2325.53 524.29l-17.22-60.67 48.53-38.71-61.45-1.81-20.74-59.66-20.74 59.66-61.44 1.81 48.92 38.71-17.22 60.67 50.68-35.68zm-95.1-9.27l15.06-52.82-42.26-33.66 53.42-1.61 18-52 18 51.81 53.43 1.61-42.27 33.65 15.07 52.81-44.23-30.81z" fill="url(#StarsDesktopHero__ao)"/>
      <path fill="url(#StarsDesktopHero__ap)" d="M1027.18 211.25L761.42 0h-1.21l266 211.45-68.07 238.98 1.06-.23 67.98-238.75v-.2z"/>
      <path d="M2343.34 549.28l-23.29-82 65.75-52-83-2.42-28-80.43-28 80.43-83 2.42 65.75 52.21-23.29 81.83 68.49-48.17zm-110.17-83l-60.47-48.18 76.51-2.22 25.64-74.17 25.63 74.17 76.51 2.22-60.46 48.18 21.52 75.58-63.2-44.34-63.21 44.34z" fill="url(#StarsDesktopHero__aq)"/>
      <path fill="url(#StarsDesktopHero__ar)" d="M2274.65 60.88l-89.82 259.22-267.1 7.86 211.53 168.11-18.01 63.39 1.71.37 18.26-64.16-.39-.41-208.4-165.49 263.58-7.86.19-.6 88.45-255.19 88.65 255.79 198.7 5.93v-1.73l-197.33-5.81-90.02-259.42z"/>
      <path fill="url(#StarsDesktopHero__as)" d="M2453.31 508.57l17.47 61.43h.95l-17.44-61.23L2562 423.12v-1.47l-108.89 86.51.2.41z"/>
      <path fill="url(#StarsDesktopHero__at)" d="M2284.64 0h-1.05l101.43 292.48h.39l176.59 5.23v-.83l-176.2-5.2L2284.64 0z"/>
      <path fill="url(#StarsDesktopHero__au)" d="M2418.28 496.27l20.97 73.73h1.95l-20.96-73.73L2562 383.61v-2.29l-143.91 114.35.19.6z"/>
      <path fill="url(#StarsDesktopHero__av)" d="M2218.09 570h4.48l52.28-36.84 52.41 36.84h4.06l-56.67-39.87-56.56 39.87z"/>
      <path fill="url(#StarsDesktopHero__aw)" d="M2509.27 370.29l-175.33-5.24-58.9-170.13-58.9 170.13-175.33 5.24 138.94 110.46-25.25 88.06 2.19.47 25.4-89.54-.58-.6-134.24-106.63 170.24-5.04 57.34-165.29 57.33 165.29 170.24 5.04-134.82 107.23.2.81 25.5 89.45h2.43l-25.39-89.25 138.93-110.46z"/>
      <path fill="url(#StarsDesktopHero__ax)" d="M2419.26 403.75l-108.02-3.23-36.39-104.81-36.4 104.81-108.02 3.23 85.71 68.13-27.86 98.12h3.12l28.27-99.33-80.43-63.9 101.56-3.02.39-1.01 33.66-97.56 34.24 98.57 101.36 3.02-80.42 63.9L2358.3 570h3.36l-27.91-98.32 85.51-67.93z"/>
      <path fill="url(#StarsDesktopHero__ay)" d="M2456.24 392.26l-135.6-4.03-45.6-131.63-45.59 131.63-135.61 4.03 107.43 85.47-26.46 92.27h2.31l26.5-93.08-103.52-82.24 130.72-3.83 43.83-126.79 44.03 126.79 130.72 3.83-103.52 82.24 26.5 93.08h2.66l-26.23-92.27 107.43-85.47z"/>
    </svg>
    
  )
}

export default StarsDesktopHero
import React from 'react'
import classNames from 'classnames'
import { useIsDesktop, useLastUpdated } from '../../hooks'
import SeasonDropdown from '../SeasonDropdown'
import VerticalMenu from '../VerticalMenu'
import Banner from '../Banner'
import { dateFormat } from '../../helpers/date'
import './style.css'

const Sidebar = ({season_id, title, seasons, selectedSeasonId, onSeasonSelected, dark = false}) => {
  const isDesktop = useIsDesktop()
  const lastUpdated = useLastUpdated(season_id)

  const seasonOptions = Object.keys(seasons).map(id => {
    return {
      label: seasons[id].name,
      value: id
    }
  })

  return (
    <div className="sidebar-container">
      <div className="sidebar-content">
        <h2 className={classNames("sidebar-competition", { "-dark": dark })}>{title && title.toLowerCase()}</h2>
        { Object.keys(seasons).length > 0 && <SeasonDropdown
          dark={dark}
          title="Torneo"
          seasons={seasonOptions}
          defaultValue={selectedSeasonId}
          placeholder="Selecciona un torneo"
          onChange={onSeasonSelected}
        /> }
        { lastUpdated && <div className="sidebar-last-updated">Actualizado el {dateFormat(lastUpdated, 'd "de" mmmm "a las" HH:MM')}</div> }
        { isDesktop && seasons[selectedSeasonId] && <VerticalMenu
          dark={dark}
          options={{
            BEST_MATCHES: 'Portada',
            TEAMS: 'Equipos',
            CALENDAR: 'Fechas y Resultados',
            CLASSIFICATION: 'Tabla de Posiciones',
            STATS: 'Estadísticas',
            FANSTATS: 'FANStats'
          }}
        /> }
        { isDesktop &&
          <Banner type="sidebar" />
        }
      </div>
    </div>
  )
}

export default Sidebar

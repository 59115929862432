import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createStore } from "redux"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import 'core-js/proposals/promise-all-settled'
import 'core-js/features/object/values'
import 'core-js/features/object/assign'
import 'core-js/features/object/entries'
import * as serviceWorker from './serviceWorker'
import './helpers/i18n'
import Application from './views/Application'
import reducers from './reducers'
import Loading from './components/Loading'
import './index.css'
import './fonts/refereepro/css/refereepro.css'

Sentry.init({dsn: "https://e641730a36f948f5b6f3b2e4bd8ac52a@sentry.io/5188346"});

const store = createStore(reducers)

const AppWrapper = (
  <Provider store={store}>
    <Router className="app">
      <Suspense fallback={<Loading/>}>
        <Application />
      </Suspense>
    </Router>
  </Provider>
)

ReactDOM.render(AppWrapper, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import df from 'dateformat'

const dayNames = [
  'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab',
  'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'
]

const monthNames = [
  'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
]

const timeNames = [
  'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
]

df.i18n = { dayNames, monthNames, timeNames }

const currentYear = new Date().getFullYear()

const dateFormat = (date, format) => {
  const parts = date.split(/[ T:.-]+/)
  const newDate = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5])
  return df(newDate, format)
}

const dateMonths = (yearMonth) => {
  const parts = yearMonth.split('-')
  const month = monthNames[11 + parseInt(parts[1])]
  return (parts[0] === currentYear.toString()) ? month : month + ' ' + parts[0]
}

export { dateFormat, dateMonths }
import { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"

const useOrganization = () => {
  const organization = useSelector(state => state.organization.organization)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!organization) {
      fetch(process.env.REACT_APP_API_URL + '/organization')
        .then(response => response.json())
        .then(response => {
          dispatch({ type: 'SET_ORGANIZATION', organization: response })
        })
        .catch(error => {
          console.error(error)
          // TODO: redirect to an error page
        })
    }
  }, [organization, dispatch])

  return organization || {}
}

export default useOrganization
import * as React from 'react'

const DefaultMobileHero = ({ backgroundA, backgroundB, backgroundC, colorA, colorB, colorC }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="414" viewBox="0 0 414 344">
      <defs>
        <linearGradient id="DefaultMobileHero__a" x1="37.91" y1="-122.61" x2="376.09" y2="-529.61" gradientTransform="matrix(1 0 0 -1 0 -154.11)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={backgroundA || "#1F5BFF"}/>
          <stop offset=".48" stopColor={backgroundB || "#041C5D"}/>
          <stop offset="1" stopColor={backgroundC || "#790461"}/>
        </linearGradient>
      </defs>
      <path fill="url(#DefaultMobileHero__a)" d="M0 0h414v344H0z"/>
    </svg>
    
  )
}

export default DefaultMobileHero
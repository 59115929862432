import { useSelector } from 'react-redux'

const useSeasonPlayer = (season_id, player_id) => {
  const player = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].players && 
    state.organization.seasonStats[season_id].players[player_id])
  return player || {}
}

export default useSeasonPlayer

const request = (url, options = {}) => {
  return new Promise(async (resolve, reject) => {
    const base = (process.env.REACT_APP_API_V2_URL.endsWith('/')) ?
      process.env.REACT_APP_API_V2_URL.slice(0, -1) :
      process.env.REACT_APP_API_V2_URL
    const uri = base + url
    fetch(uri, { credentials: 'include', ...options })
      .then(result => {
        result.json().then(response => {
          if (response.success) resolve(response.data)
          else if (response.code) reject({ code: response.code, error: response.error, data: response.data })
          else reject({ code: 'UNKNOWN_RESPONSE', error: 'Unknown response from the server' })
        }).catch(error => {
          reject({ code: 'INVALID_RESPONSE', error: 'Invalid response from the server' })
        })
      })
      .catch(result => {
        reject({ code: 'INTERNET_FAILURE', error: 'Internet Failure', data: result })
      })
  })
}

const get = (url, params = false) => {
  if (params instanceof Object) return request(url + '?' + (new URLSearchParams(params).toString()))
  return request(url)
}

const post = (url, data) => {
  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers:{
      'Content-Type': 'application/json'
    }
  })
}

export { get, post }
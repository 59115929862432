import { useState, useEffect, useRef, useCallback } from 'react'

const getValue = (value) => {
  return (value instanceof Function) ? value() : value
}

const useDebounce = (func, delay, initialValue) => {
  const [value, setValue] = useState(getValue(initialValue))
  const timer = useRef(null)

  const clear = useCallback(() => {
    clearTimeout(timer.current)
  }, [timer])

  const flush = useCallback(() => {
    clearTimeout(timer.current)
    func(getValue(value))
  }, [timer, func, value])

  useEffect(() => {
    if (timer.current === null) {
      timer.current = false
      return
    }
    timer.current = setTimeout(() => func(getValue(value)), delay)
    return () => {
      clearTimeout(timer.current)
    }
  }, [timer, func, value, delay])

  return [value, setValue, flush, clear]
}

export default useDebounce
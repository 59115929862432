import React, { Fragment, useCallback } from 'react'
import { useIsDesktop, useIsTablet } from '../../hooks'

import Image from '../Image'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

const Banner = ({type, title, className}) => {
  const banners = useSelector(state => state.organization && state.organization.banners && state.organization.banners[type])
  const banner = banners && banners.length > 0 && banners[Math.floor(Math.random() * banners.length)]
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const size = isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
  const source = banner && (banner[size] || banner['tablet'] || banner['desktop'] || banner['mobile'])

  const handleClick = useCallback(() => {
    if (typeof window.ga === 'function' && banner && banner.link) {
      window.ga('send', 'event', 'Banner', 'click', banner.link)
    }
    return true
  }, [banner])

  return (
    <Fragment>
      { source &&
        <div className={classNames("banner" + type, className, 'banner')}>
          { title && <div className='-title'>{title}</div> }
          <a href={banner.link} onClick={handleClick} target="_blank" rel="noopener noreferrer">
            <Image src={process.env.REACT_APP_IMAGES_BASE_URL + '/banner/' + source} />
          </a>
        </div>
      }
    </Fragment>
  )
}

export default Banner
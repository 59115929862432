import React, { Fragment, useEffect } from 'react'
import { Route, Switch, withRouter, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get } from '../helpers/requests'
import FansHeader from '../components/FansHeader'
import FansMainMenu from '../components/FansMainMenu'
import { useCompetitions, useOrganization, useConfiguration } from '../hooks'
import CompetitionPage from '../pages/CompetitionPage/CompetitionPage'
import HomePage from '../pages/HomePage/HomePage'
import NewsPage from '../pages/NewsPage/NewsPage'
import DocumentsPage from '../pages/DocumentsPage/DocumentsPage'
import ContactPage from '../pages/ContactPage/ContactPage'
import './style.scss'
import { selectBestColor, colorLightness } from '../helpers/colors'

const Application = () => {
  const { pathname } = useLocation()
  const bannersDate = useSelector(state => state.banners && state.banners.last_update)
  const dispatch = useDispatch()
  const organization = useOrganization()
  const competitions = useCompetitions()
  const configuration = useConfiguration()
  const competitionSelected = pathname && pathname.startsWith('/competicion')

  const changeVariable = (name, value) => {
    if (value) document.documentElement.style.setProperty(name, value)
  }

  useEffect(() => {
    if (configuration && configuration.style && configuration.style.primary_color) {
      const LIGHTNESS_AMOUNT = 32
      const color = configuration.style.primary_color
      const ligthColor = colorLightness(color, LIGHTNESS_AMOUNT)
      const darkColor = colorLightness(color, -LIGHTNESS_AMOUNT)

      changeVariable('--buttonsColor', color)
      changeVariable('--buttonsTextColor', selectBestColor(color, '#141414', '#ffffff'))
      changeVariable('--buttonsLightColor', ligthColor)
      changeVariable(
        '--buttonsTextLightColor',
        selectBestColor(ligthColor, color, '#141414', '#ffffff')
      )
      changeVariable('--buttonsDarkColor', darkColor)
      changeVariable(
        '--buttonsTextDarkColor',
        darkColor,
        color,
        '#141414',
        '#ffffff'
      )
    }
  }, [configuration])

  useEffect(() => {
    if (!bannersDate) {
      get('/banners')
        .then(banners => {
          dispatch({ type: 'SET_BANNERS', banners })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [bannersDate, dispatch])

  return (
    <Fragment>
      <FansHeader organization={organization} />
      <FansMainMenu organization={organization} competitions={competitions} competitionSelected={competitionSelected} />
      <Switch>
        <Route exact path="/">
          <HomePage organization={organization} />
        </Route>
        <Route exact path="/contacto">
          <ContactPage />
        </Route>
        <Route exact path="/noticias/:site_news_id">
          <NewsPage />
        </Route>
        <Route exact path="/noticias">
          <NewsPage />
        </Route>
        <Route exact path="/documentos">
          <DocumentsPage />
        </Route>
        <Route exact path="/competicion/:competition_id/temporada/:season_id/equipo/:team_id">
          <CompetitionPage organization={organization} />
        </Route>
        <Route exact path="/competicion/:competition_id/temporada/:season_id">
          <CompetitionPage organization={organization} />
        </Route>
        <Route path="/competicion/:competition_id">
          <CompetitionPage />
        </Route>
      </Switch>
    </Fragment>
  )
}

export default withRouter(Application)

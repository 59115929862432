import classNames from 'classnames'
import React, { Fragment, useEffect } from 'react'
import { scroller, Element } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { useIsMobile, useSeasonTeam, useSeasonTeams, useTeamClassification, useFanStatsTeam, useTeamBestPlayers, useSeasonBestPlayers } from '../../hooks'
import Image from '../Image'
import TeamDetailsPlayer from '../TeamDetailsPlayer'
import TeamDetailsTechnical from '../TeamDetailsTechnical'
import './style.css'

const addBodyClass = className => document.body.classList.add(className);
const removeBodyClass = className => document.body.classList.remove(className);

const COLUMNS = [
  {
    title: 'Partidos Jugados',
    titleMobile: 'PJ',
    key: "played"
  },
  {
    title: 'Partidos Ganados',
    titleMobile: 'PG',
    key: "won"
  },
  {
    title: 'Partidos Empatados',
    titleMobile: 'PE',
    key: "drawn"
  },
  {
    title: 'Partidos Perdidos',
    titleMobile: 'PP',
    key: "lost"
  },
  {
    title: 'Amones- taciones',
    titleMobile: 'TA',
    key: "yellow_cards"
  },
  {
    title: 'Expul- siones',
    titleMobile: 'TR',
    key: "red_cards"
  },
  {
    title: 'Goles Anotados',
    titleMobile: 'GA',
    key: "scored_goals"
  },
  {
    title: 'Goles Recibidos',
    titleMobile: 'GR',
    key: "received_goals"
  },
  {
    title: 'Diferencia de Goles',
    titleMobile: 'DG',
    key: "goals_difference"
  },
  {
    title: 'Puntos Totales',
    titleMobile: 'Pts',
    key: "points"
  }
]

const FANS_COLUMNS = [
  {
    title: 'Victorias de Visita',
    keyA: "away_won_points",
    keyB: "away_won"
  },
  {
    title: 'Partidos Sin Recibir Gol',
    keyA: "games_goals_keeper_points",
    keyB: "games_goals_keeper"
  },
  {
    title: 'Partidos Sin Recibir Tarjetas',
    keyA: "cards_free_points",
    keyB: "cards_free"
  },
  {
    title: 'Derrotas x Default',
    keyA: "lost_by_default_points",
    keyB: "lost_by_default"
  },
  {
    title: 'FANpoints de Jugadores',
    keyA: "team_players_fans_stats"
  },
  {
    title: 'FANpoints Totales',
    keyA: "points"
  }
]

const TeamDetailsModal = ({ season_id, team_id, isOpen, onRequestClose, onTeamSelected }) => {
  const team = useSeasonTeam(season_id, team_id)
  const teams = useSeasonTeams(season_id)
  const teamStats = useTeamClassification(season_id, team_id)
  const teamFanStats = useFanStatsTeam(season_id, team_id)
  const bestTeamPlayerIds = useTeamBestPlayers(season_id, team_id, true)
  const bestSeasonPlayerIds = useSeasonBestPlayers(season_id, true)
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const teamIds = Object.keys(teams)
  const teamIndex = teamIds.indexOf(team_id)
  const nextTeam = teams[teamIds[(teamIndex + 1) % teamIds.length]]
  const previousTeam = teams[teamIds[teamIndex === 0 ? teamIds.length - 1 : teamIndex - 1]]

  useEffect(() => {
    if (isOpen) {
      addBodyClass('team-detail-modal-body-no-scroll');
    }
    return (() => {
      removeBodyClass('team-detail-modal-body-no-scroll')
    })
  }, [isOpen]);

  useEffect(() => {
    if (team_id) {
      scroller.scrollTo('team-detail-modal-anchor', {
        containerId: 'team-detail-modal-inner',
        smooth: true,
        duration: 1000
      })
    }
  }, [team_id])

  const generalStatRender = (stat, index) => {
    const value = teamStats[stat.key]
    const isLast = (index === COLUMNS.length - 1) ? '-last' : ''
    return (
      <div className={classNames('team-detail-stats-data-container', isLast)}>
        <span className={classNames('team-detail-stats-data', isLast)}>{value}</span>
        <span className={classNames('team-detail-stats-title', isLast)}>
          {isMobile === true ? stat.titleMobile : stat.title}
        </span>
      </div>
    );
  };

  const fanStatRender = (stat, index) => {
    const isLast = { '-last': index === FANS_COLUMNS.length - 1 }

    if (isMobile) {
      return (
        <div className={classNames('team-detail-fanstats-data-container', isLast)}>
          <span className={classNames('team-detail-fanstats-title', isLast)}>{stat.title}</span>
          <span className="team-detail-fanstats-data">{teamFanStats[stat.keyA]}</span>
        </div>
      );
    } else {
      return (
        <div className={classNames('team-detail-fanstats-data-container', isLast)}>
          <span className={classNames('team-detail-fanstats-data', isLast)}>{teamFanStats[stat.keyA]}</span>
          <span className={classNames('team-detail-fanstats-title', isLast)}>{stat.keyB ? teamFanStats[stat.keyB] + ' ' : ''}{stat.title}</span>
        </div>
      );
    }
  };

  const Body = () => {
    return (
      <Fragment>
        { team && team.team_id &&
          <div>
            <div className={'team-detail-header'}>
              <Image src={team.logo} defaultImage={<span className="icon-teams rp-team-badge -big" />} />
              <div className="team-detail-header-info">
                <span className="team-detail-header-title">{team.name}</span>
                <div className="team-detail-header-place-container">
                  <span className="team-detail-header-team-place">{team.name}</span>
                  {false && <span className="icon-share team-detail-header-team-share" />}
                </div>
              </div>
            </div>
            <span className="team-detail-title">{'Perfil de Equipo'}</span>
            <div className="team-detail-stats-container">
              <span className="team-detail-subtitle">{'Estadísticas Generales'}</span>
              <div className="team-detail-stats-data-grid">
                {COLUMNS.map((stat, index) => {
                  return <div key={index}>{generalStatRender(stat, index)}</div>;
                })}
              </div>
            </div>
            <div className="team-detail-fanstats-container">
              <span className="team-detail-subtitle">{'FANStats'}</span>
              <div className="team-detail-fanstats-data-grid">
                {FANS_COLUMNS.map((stat, index) => {
                  return <div key={index}>{fanStatRender(stat, index)}</div>;
                })}
              </div>
            </div>
            <div className="team-detail-team-players-title-container">
              <span className="team-detail-title">{'Plantel'}</span>
              <span className="team-detail-team-total">{t('playersCount', {count: team.players && team.players.length})}</span>
            </div>
            <div className="team-detail-team-players-grid">
              {team.players && team.players.map((player_id, index) => {
                return (
                  <div key={index}>
                    <TeamDetailsPlayer
                      season_id={season_id}
                      player_id={player_id}
                      bestTeamPlayer={bestTeamPlayerIds.includes(player_id)}
                      bestSeasonPlayer={bestSeasonPlayerIds.includes(player_id)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="team-detail-team-players-title-container">
              <span className="team-detail-title">{'Cuerpo Técnico'}</span>
              <span className="team-detail-team-total">{t('staffCount', {count: team.staff && team.staff.length})}</span>
            </div>
            <div className="team-detail-team-players-grid">
              {team.staff && team.staff.map((technical, index) => {
                return (
                  <div key={index}>
                    <TeamDetailsTechnical technicalDetail={technical} teamDetails={team} />
                  </div>
                );
              })}
            </div>
            <div className="team-detail-modal-inner-footer" />
          </div>
        }
      </Fragment>
    )
  }

  const Footer = () => {
    return (
      <Fragment>
        { previousTeam && nextTeam &&
          <div className="team-detail-modal-footer">
            <div
              className={classNames('team-detail-modal-footer-baseteam', '-pastteam')}
              onClick={() => onTeamSelected(previousTeam.team_id)}>
              <span className="icon-left-small team-detail-modal-footer-arrow" />
              <div className="team-detail-modal-footer-shield">
                <Image src={previousTeam.logo} defaultImage={<span className="icon-teams rp-team-badge -big" />} />
              </div>
              <span className={classNames('team-detail-modal-footer-name', '-pastteam')}>{previousTeam.name}</span>
            </div>
            <div
              className={classNames('team-detail-modal-footer-baseteam', '-nextteam')}
              onClick={() => onTeamSelected(nextTeam.team_id)}>
              <span className={classNames('team-detail-modal-footer-name', '-nextteam')}>{nextTeam.name}</span>
              <div className="team-detail-modal-footer-shield ">
                <Image src={nextTeam.logo} defaultImage={<span className="icon-teams rp-team-badge -lead" />} />
              </div>
              <span className="icon-right-small team-detail-modal-footer-arrow" />
            </div>
          </div>
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={classNames('team-detail-modal-overlay', { '-hidden': !isOpen })} id={'team-detail-modal-inner'}>
        <div className={classNames('team-detail-modal-close', { '-hidden': !isOpen })} onClick={onRequestClose} />
        <div className={classNames('team-detail-modal', '-slide-center', { '-hidden': !isOpen })}>
          <Element name="team-detail-modal-anchor"></Element>
          <div className={'team-detail-modal-inner'}>
            <button className="team-detail-modal-close-button" onClick={() => onRequestClose()}>
              <span className="icon-cancel" />
            </button>
            <Body />
          </div>
          <Footer/>
          <div className="team-detail-modal-header-background" />
        </div>
      </div>
    </Fragment>
  )
}

export default TeamDetailsModal

import React from 'react'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { useParams, Link } from 'react-router-dom'
import Image from '../Image'
import { makeInitials } from '../../helpers/makeInitials'
import header from '../../images/ball.svg'
import { ReactComponent as YellowCard } from './yellow.svg'
import { useSeasonTeam, useSeasonPlayer } from '../../hooks'
import './style.css'

const PlayerBadge = ({ season_id, player_id, position }) => {
  const { competition_id } = useParams()
  const player = useSeasonPlayer(season_id, player_id)
  const team = useSeasonTeam(season_id, player && player.teamId)

  return (
    <div className='flex-1' style={{ padding: '1rem 0' }}>
      <span className='caution-stats-table-light-text'>{position} <span className='icon-dot' /></span>
      <div className="caution-stats-images-container">
        <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
          <Image className={classNames('caution-stats-player-image')} src={player.avatar} defaultImage={<span className={classNames("caution-stats-player-initials")}>{makeInitials(player.firstname, player.last_name)}</span>} />
          <Image className={classNames('caution-stats-team-image')} src={team.logo} defaultImage={<span className="icon-teams caution-stats-team-image" />} />
        </Link>
      </div>
      <div className="caution-stats-table-player">
        <span className={classNames('caution-stats-table-player-name', 'rp-team-name')}>
          {player.firstname}{' '}{player.lastname}
        </span>
        <span className={classNames('caution-stats-table-player-info')}>
          <span className="rp-team-name">{ team.name }</span>
        </span>
      </div>
    </div>
  )
}

const CautionsTable = ({ data, newData, season_id }) => {
  return (
    <div className="caution-stats-table-table">
      {newData.map((player_id, index) => {
        return (
          <div key={index} className='flex caution-stats-table-row'>
            <PlayerBadge season_id={season_id} player_id={player_id} position={index + 1} />
            <div className={classNames('caution-stats-points', {'-lead': index === 0})} data-tip={player_id} data-for="cautions-card-details">
              <YellowCard />
              <span className="-inside">{data[player_id] && data[player_id].count}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Tooltip = ({ data }) => {
  return (
    <ReactTooltip
      id='cautions-card-details'
      className='cautions-tooltip-container'
      place='left'
      effect='solid'
      getContent={(player_id) => { return (
        <div className="cautions-tooltip">
        {data[player_id] && data[player_id].journeys && data[player_id].journeys.map((journey, index) => (
          <div className="-item">
            <YellowCard/>
            <span className="-inside">{index + 1}</span>
            <span>{journey}</span>
          </div>
        ))}
        </div>
      ) }}
    />
  )
}

const CautionStatsTable = ({ season_id, data, onMore, dark }) => {
  const dataKeys = Object.keys(data)
  const newData = dataKeys.slice(0, 5)
  const moreData = dataKeys.length > 5

  return (
    <div className='caution-stats-table-container'>
      <div className="caution-stats-table-header">
        <span className="caution-stats-table-header-title">Amonestaciones Acumuladas</span>
        <div className={classNames("caution-stats-table-header-image", { '-light': !dark })}>
          <img alt="" src={header} />
          <div className="-overlay"></div>
        </div>
      </div>
      <div>
        <CautionsTable data={data} newData={newData} season_id={season_id} />
        { moreData &&
          <div className='stats-table-see-more' onClick={onMore}>Ver la lista completa <span className="icon-right-small" /></div>
        }
      </div>
      <Tooltip data={data} />
    </div>
  )
}

const CautionStatsFullTable = ({ season_id, data, onRequestClose }) => {
  const newData = Object.keys(data)
  return (
    <div className='full-stats-table-container'>
      <div className="full-stats-table-header">
        <span className="-title">Amonestaciones Acumuladas</span>
        <button className="modal-close-button" onClick={onRequestClose} >Cerrar <span className="icon-cancel" /></button>
      </div>
      <div>
        <div className='flex full-stats-table-row'>
          <div>Jugadores</div>
          <div>Amonestaciones</div>
        </div>
        <CautionsTable data={data} newData={newData} season_id={season_id} />
      </div>
      <Tooltip data={data} />
    </div>
  )
}

export { CautionStatsTable, CautionStatsFullTable }

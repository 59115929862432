import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

const useSeasonStats = (season_id, update = false) => {
  const seasonStats = useSelector(state =>
    state.organization.seasonStats &&
    state.organization.seasonStats[season_id])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!seasonStats && season_id) {
      fetch(process.env.REACT_APP_API_URL + '/season/' + season_id + '/statistics' + (update ? '?update=1' : ''))
        .then(response => response.json())
        .then(response => {
          dispatch({ type: 'SET_SEASON_STATS', seasonStats: response, season_id })
        })
        .catch(error => {
          console.error(error)
          // TODO: redirect to an error page
        })
    }
  }, [seasonStats, season_id, update, dispatch])

  return seasonStats || {}
}

export default useSeasonStats

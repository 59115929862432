import './style.css'

import React, { useRef, useState } from 'react'

import Banner from '../Banner'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as Players } from './players.svg'
import classNames from 'classnames'
import { useConfiguration } from '../../hooks'

const FansVideo = () => {
  const [playing, setPlaying] = useState(false)
  const video_url = useConfiguration('fans_video_url') || 'https://refereeprocm.s3.amazonaws.com/videos/fans.mp4'
  const videoRef = useRef()

  const play = () => {
    setPlaying(true)
    videoRef.current.play()
  }

  const restart = () => {
    setPlaying(false)
  }

  return (
    <div className="fans-video">
      <video ref={videoRef} controls={playing} onEnded={() => restart()}>
        <source src={video_url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={classNames("-container", { "-visible": !playing })} onClick={() => play()}>
        <div className="-play"><Play />Reproducir</div>
        <div className="-players"><Players /></div>
      </div>
      <Banner type="fans" title="Auspiciado por" />
    </div>
  )
}

export default FansVideo
import './style.css'

import { Link, useParams } from 'react-router-dom'
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { animated, useSprings } from 'react-spring'
import { useFanStatsTeam, useIsMobile, useSeasonPlayer, useSeasonTeam, useTeamBestPlayers } from '../../hooks'

import Avatar from '../Avatar'
import { ReactComponent as AwayWon } from '../FANStatsTeamTable/awayWon.svg'
import Banner from '../Banner'
import { ReactComponent as CardsFree } from '../FANStatsTeamTable/cardsFree.svg'
import { ReactComponent as FansStatsPlayers } from '../FANStatsTeamTable/fansStatsPlayers.svg'
import { ReactComponent as Goal } from '../FANStatsPlayerTable/goals.svg'
import { ReactComponent as GoalsKeeper } from '../FANStatsTeamTable/goalsKeeper.svg'
import Image from '../Image'
import { ReactComponent as LostByDefault } from '../FANStatsTeamTable/lostByDefault.svg'
import { ReactComponent as Medal } from '../FANStatsPlayerTable/bestPlayer.svg'
import { ReactComponent as OwnGoals } from './ownGoals.svg'
import { ReactComponent as RedCard } from '../FANStatsPlayerTable/redCard.svg'
import { ReactComponent as YellowCard } from '../FANStatsPlayerTable/yellowCard.svg'
import { ReactComponent as YellowCards } from '../FANStatsPlayerTable/yellowCards.svg'
import _ from 'lodash'
import classNames from 'classnames'
import { dateFormat } from '../../helpers/date';
import { makeInitials } from '../../helpers/makeInitials'
import { useGesture } from 'react-use-gesture'

const POSTIONS = {
  '1': 'DELANTERA',
  '2': 'MEDIA OFENSIVA',
  '3': 'MEDIA DE CONTENCIÓN',
  '4': 'MEDIA POR LOS COSTADOS',
  '5': 'DEFENSA CENTRAL',
  '6': 'DEFENSA LATERAL',
  '7': 'PORTERÍA'
}

const TransformSlider = forwardRef((props, ref) => {
  const activeIndex = props.activeIndex || 0
  const children = props.children
  const sliderRef = useRef(null)
  const [isDragging, setDragging] = useState(false)
  const [slide, setSlide] = useState(0)

  const [springProps, setSpringProps] = useSprings(children.length, i => ({
    offset: i * window.innerWidth
  }))

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [xDelta], direction: [xDir], distance, cancel, first }) => {
        if (first && down) {
          setDragging(true)
          if (props.startDragSlider !== undefined) {
            props.startDragSlider()
          }
        }
        if (sliderRef && sliderRef.current && sliderRef.current.parentElement) {
          const { width } = sliderRef.current.parentElement.getBoundingClientRect()
          if (down && distance > width / 2) {
            if (cancel) {
              cancel()
            }
            setSlide(_.clamp(slide + (xDir > 0 ? -1 : 1), 0, children.length - 1))
          }
          setSpringProps(index => {
            const offset = (down ? xDelta : 0) / width + (index - slide)
            return { offset }
          })
        }
      },
      onClick: () => {
        if (isDragging) {
          setDragging(false)
          return
        }
      }
    },
    {
      drag: {
        delay: 200
      }
    }
  )

  // Triggered on slide change
  useEffect(() => {
    // see:  https://github.com/react-spring/react-spring/issues/861
    // @ts-ignore
    setSpringProps(index => ({ offset: index - slide }))
  }, [slide, setSpringProps])

  // Jump to slide index when prop changes
  useEffect(() => {
    setSlide(activeIndex % children.length)
  }, [activeIndex, children.length])

  const nextSlide = () => {
    if (slide === children.length - 1) {
      setSlide(0)
      return
    }
    setSlide(slide + 1)
  }

  const previousSlide = () => {
    if (slide === 0) {
      setSlide(children.length - 1)
      return
    }
    setSlide(slide - 1)
  }

  const goToSlider = index => {
    setSlide(index)
  }

  useImperativeHandle(ref, () => {
    return {
      nextSlide,
      previousSlide,
      goToSlider
    }
  })

  return (
    <div className="bestmatches-info-slider-container" ref={sliderRef}>
      {springProps.map(({ offset }, i) => (
        <animated.div
          key={i}
          {...bind()}
          style={{
            transform: offset.interpolate(offsetX => `translate3d(${offsetX * 100}%, 0, 0)`)
          }}
          className={'bestmatches-info-slide'}
        >
          {children[i]}
        </animated.div>
      ))}
      <div className="bestmatches-info-slider-dot-container">
        {children.map((child, i) => (
          <div
            key={i}
            onClick={() => {
              goToSlider(i)
            }}
            className={classNames('bestmatches-info-slider-dot', slide === i ? '-active' : '-deactive')}
          />
        ))}
      </div>
    </div>
  )
})

const BestMatchesTable = ({ season_id, bestMatches }) => {
  const { competition_id } = useParams()
  const bestGameHomeTeam = useSeasonTeam(season_id, bestMatches.best_game && bestMatches.best_game.home_team_id)
  const bestGameAwayTeam = useSeasonTeam(season_id, bestMatches.best_game && bestMatches.best_game.away_team_id)
  const expectedMatchHomeTeam = useSeasonTeam(season_id, bestMatches.expected_match && bestMatches.expected_match.home_team_id)
  const expectedMatchAwayTeam = useSeasonTeam(season_id, bestMatches.expected_match && bestMatches.expected_match.away_team_id)
  const isMobile = useIsMobile()

  const renderTeamDataSmall = team => {
    return (
      <div className="bestmatches-header-team">
        <div className="bestmatches-header-team-top-color-container">
          <div className={classNames('bestmatches-header-team-top-color', '-small')}></div>
        </div>
        <div className={classNames('bestmatches-header-team-shield', '-small')}>
          <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
            <Image
              src={team.logo}
              defaultImage={<span className="icon-teams rp-team-badge -lead bestmatches-header-team-shield-noteam" />}
            />
          </Link>
        </div>
        <div className="bestmatches-header-team-name">
          <span>{team.shortName}</span>
        </div>
      </div>
    )
  }

  const renderTeamDataBig = team => {
    return (
      <div className="bestmatches-header-team">
        <div className="bestmatches-header-team-top-color-container">
          <div className={classNames('bestmatches-header-team-top-color', '-big')}></div>
        </div>
        <div className={classNames('bestmatches-header-team-shield', '-big')}>
          <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
            <Image
              src={team.logo}
              defaultImage={
                <span
                  className={
                    'icon-teams rp-team-badge -lead bestmatches-header-team-shield-noteam' +
                    (isMobile === true ? '' : '-big')
                  }
                />
              }
            />
          </Link>
        </div>
        {isMobile === true && (
          <div className="bestmatches-header-team-name">
            <span>{team.shortName}</span>
          </div>
        )}
      </div>
    )
  }

  const renderSmallHeaderData = info => {
    const journeyText = info.journey.split(/[ ]+/).map((part) => /[0-9]+/.test(part) ? part : part[0]).join('')
    const hasPenalties = (info.game.home_penalties > 0) || (info.game.away_penalties > 0)
    return (
      <div>
        <div className={classNames('bestmatches-header-data-background', '-small')} />
        <div className={classNames('bestmatches-header-data-container-top', '-small')}>{journeyText}</div>
        <div className={classNames('bestmatches-header-data-subinfo', '-small')}>
          <span>{info.date}</span>
        </div>
        <div className={classNames('bestmatches-header-data-info', '-small')}>
          <span>{info.game.home_score}</span>
          {hasPenalties && <span className="-sub">({info.game.home_penalties})</span>}
          <span className="bestmatches-header-data-info-points-separation">{'-'}</span>
          {hasPenalties && <span className="-sub -right">({info.game.away_penalties})</span>}
          <span>{info.game.away_score}</span>
        </div>
      </div>
    )
  }

  const renderBigHeaderData = info => {
    let journeyText = info.journey
    if (isMobile) {
      journeyText = journeyText.split(/[ ]+/).map((part) => /[0-9]+/.test(part) ? part : part[0]).join('')
    }
    return (
      <div>
        <div className={classNames('bestmatches-header-data-background', '-big')} />
        <div className={classNames('bestmatches-header-data-background-mask', '-big')}>
          <div className={classNames('bestmatches-header-team-shield-background-container', '-left')}>
            <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + info.homeTeam.team_id}>
              <Image
                className="bestmatches-header-team-shield-background"
                src={info.homeTeam.logo}
                defaultImage={
                  <span className="icon-teams rp-team-badge -lead bestmatches-header-team-shield-background -big" />
                }
              />
            </Link>
          </div>
          <div className={classNames('bestmatches-header-team-shield-background-container', '-right')}>
            <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + info.awayTeam.team_id}>
              <Image
                className="bestmatches-header-team-shield-background"
                src={info.awayTeam.logo}
                defaultImage={
                  <span className="icon-teams rp-team-badge -lead bestmatches-header-team-shield-background -big" />
                }
              />
            </Link>
          </div>
        </div>
        <div className={classNames('bestmatches-header-data-container-top', '-big')}>{journeyText}</div>
        <div className="bestmatches-header-big-container">
          {isMobile === false && (
            <div className="bestmatches-header-data-team-info">
              <span>{info.homeTeam.shortName}</span>
            </div>
          )}
          <div>
            <div className={classNames('bestmatches-header-data-subinfo', '-big')}>
              <span>{info.date}</span>
            </div>
            <div className={classNames('bestmatches-header-data-info', '-big')}>
              <span>{info.hour}</span>
            </div>
            <div className={classNames('bestmatches-header-data-note', '-big')}>
              <span>Horario Local</span>
            </div>
          </div>
          {isMobile === false && (
            <div className="bestmatches-header-data-team-info">
              <span>{info.awayTeam.shortName}</span>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderSmallInfoData = (homeTeam, homeStats, awayTeam, awayStats) => {
    return (
      <div className={classNames('bestmatches-info-container', '-small')}>
        <div />
        <div className={classNames('bestmatches-info-center-container', '-small')}>
          <span className={'bestmatches-info-center-container-title'}>{'Resumen'}</span>
          <span className={'bestmatches-info-center-container-subtitle'}>{'Estadísticas'}</span>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.goals}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <Goal />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.goals}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.yellowCards}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <YellowCard />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.yellowCards}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.doubleYellowCards}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <YellowCards />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.doubleYellowCards}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.redCards}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <RedCard />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.redCards}</span>
          </div>
        </div>
        <div />
      </div>
    )
  }

  const SmallFansInfoData = ({homeTeam, awayTeam}) => {
    const homeStats = useFanStatsTeam(season_id, homeTeam.team_id)
    const awayStats = useFanStatsTeam(season_id, awayTeam.team_id)
    return (
      <div className={classNames('bestmatches-info-container', '-small')}>
        <div />
        <div className={classNames('bestmatches-info-center-container', '-small')}>
          <span className={'bestmatches-info-center-container-title'}>{'Resumen'}</span>
          <span className={'bestmatches-info-center-container-subtitle'}>{'FANStats'}</span>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.cards_free_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <CardsFree />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.cards_free_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.games_goals_keeper_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <GoalsKeeper />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.games_goals_keeper_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.away_won_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <AwayWon />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.away_won_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.lost_by_default_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <LostByDefault />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.lost_by_default_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homeStats.team_players_fans_stats_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <FansStatsPlayers />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayStats.team_players_fans_stats_points}</span>
          </div>
        </div>
        <div />
      </div>
    )
  }

  const renderPlayerDetail = player => {
    return (
      <div className="bestmatches-info-center-playerdetail-container">
        <div className="bestmatches-info-center-avatar-container">
          <div className={'bestmatches-info-center-avatar-image-container '}>
            <Avatar src={player.avatar} initials={makeInitials(player.firstname, player.lastname)} />
          </div>
          <div className="bestmatches-info-center-avatar-number-container">
            <div className="bestmatches-info-center-avatar-number-mask">{player.shirtNumber}</div>
          </div>
        </div>
        <span className="bestmatches-info-center-playerdetail-name">{player.firstname}</span>
        <span className="bestmatches-info-center-playerdetail-name">{player.lastname}</span>
        <span className="bestmatches-info-center-playerdetail-pos">{POSTIONS[player.positions && player.positions[0]] || 'Sin posición'}</span>
      </div>
    )
  }

  const BigInfoData = ({homeTeam, awayTeam}) => {
    const homePlayers = useTeamBestPlayers(season_id, homeTeam.team_id)
    const awayPlayers = useTeamBestPlayers(season_id, awayTeam.team_id)
    const homePlayer = homePlayers[0] || {}
    const awayPlayer = awayPlayers[0] || {}
    const homePlayerData = useSeasonPlayer(season_id, homePlayer.playerId)
    const awayPlayerData = useSeasonPlayer(season_id, awayPlayer.playerId)

    const jmvText = isMobile ? 'JMV' : 'Jugadores Más Valiosos'

    return (
      <div className={classNames('bestmatches-info-container', '-big')}>
        <div>
          {renderPlayerDetail(homePlayerData)}
        </div>
        <div className={classNames('bestmatches-info-center-container', '-big')}>
          <span className={'bestmatches-info-center-container-title'}>{'Frente a Frente'}</span>
          <span className={'bestmatches-info-center-container-subtitle'}>{jmvText}</span>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.goals}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <Goal />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.goals}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.best_player}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <Medal />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.best_player}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.yellow_cards}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <YellowCard />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.yellow_cards}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.double_yellow_cards}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <YellowCards />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.double_yellow_cards}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.red_cards}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <RedCard />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.red_cards}</span>
          </div>
        </div>
        <div>
          {renderPlayerDetail(awayPlayerData)}
        </div>
      </div>
    )
  }

  const BigFansInfoData = ({homeTeam, awayTeam}) => {
    const homePlayers = useTeamBestPlayers(season_id, homeTeam.team_id)
    const awayPlayers = useTeamBestPlayers(season_id, awayTeam.team_id)
    const homePlayer = homePlayers[0] || {}
    const awayPlayer = awayPlayers[0] || {}
    const homePlayerData = useSeasonPlayer(season_id, homePlayer.playerId)
    const awayPlayerData = useSeasonPlayer(season_id, awayPlayer.playerId)

    return (
      <div className={classNames('bestmatches-info-container', '-big')}>
        <div>
          {renderPlayerDetail(homePlayerData)}
        </div>
        <div className={classNames('bestmatches-info-center-container', '-big')}>
          <span className={'bestmatches-info-center-container-title'}>{'FANStats'}</span>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.goals_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <Goal />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.goals_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.best_player_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <Medal />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.best_player_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.yellow_cards_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <YellowCard />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.yellow_cards_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.double_yellow_cards_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <YellowCards />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.double_yellow_cards_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.red_cards_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <RedCard />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.red_cards_points}</span>
          </div>
          <div className="bestmatches-info-center-data-container">
            <span className="bestmatches-info-center-data-score ">{homePlayer.own_goals_points}</span>
            <div className="bestmatches-info-center-data-dash" />
            <div className="bestmatches-info-center-data-icon">
              <OwnGoals />
            </div>
            <div className="bestmatches-info-center-data-dash" />
            <span className="bestmatches-info-center-data-score ">{awayPlayer.own_goals_points}</span>
          </div>
        </div>
        <div>
          {renderPlayerDetail(awayPlayerData)}
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      { (bestMatches.best_game || bestMatches.expected_match) &&
        <Fragment>
          <div className={classNames("bestmatches-main-container", { '-full': (!bestMatches.expected_match || !bestMatches.expected_match.game_id) })}>
            { bestMatches.best_game && bestMatches.best_game.game_id &&
              <div className={classNames('bestmatches-background-container', '-left')}>
                <div className={classNames('bestmatches-header-container', '-left', '-small')}>
                  {renderTeamDataSmall(bestGameHomeTeam)}
                  {renderSmallHeaderData({
                    journey: bestMatches.best_game.journey,
                    date: bestMatches.best_game.start_time && dateFormat(bestMatches.best_game.start_time, 'ddd d mmm'),
                    game: bestMatches.best_game
                  })}
                  {renderTeamDataSmall(bestGameAwayTeam)}
                </div>
                <TransformSlider>
                  {renderSmallInfoData(bestGameHomeTeam, bestMatches.best_game.home_stats, bestGameAwayTeam, bestMatches.best_game.away_stats)}
                  <SmallFansInfoData homeTeam={bestGameHomeTeam} awayTeam={bestGameAwayTeam} />
                </TransformSlider>
              </div>
            }
            { bestMatches.expected_match && bestMatches.expected_match.game_id &&
              <div className={classNames('bestmatches-background-container', '-right')}>
                <div className={classNames('bestmatches-header-container', '-right', '-big')}>
                  {renderTeamDataBig(expectedMatchHomeTeam)}
                  {renderBigHeaderData({
                    journey: bestMatches.expected_match.journey,
                    hour: bestMatches.expected_match.start_time && dateFormat(bestMatches.expected_match.start_time, 'HH:MM'),
                    date: bestMatches.expected_match.start_time && dateFormat(bestMatches.expected_match.start_time, 'ddd d mmm'),
                    homeTeam: expectedMatchHomeTeam,
                    awayTeam: expectedMatchAwayTeam
                  })}
                  {renderTeamDataBig(expectedMatchAwayTeam)}
                </div>
                <TransformSlider>
                  <BigInfoData homeTeam={expectedMatchHomeTeam} awayTeam={expectedMatchAwayTeam} />
                  <BigFansInfoData homeTeam={expectedMatchHomeTeam} awayTeam={expectedMatchAwayTeam} />
                </TransformSlider>
              </div>
            }
          </div>
          <Banner type="matches" title="Presentado por" />
        </Fragment>
      }
    </Fragment>
  )
}

export default BestMatchesTable

import React from 'react'
import { useSeasonPlayer } from '../../hooks'
import './style.css'

const GameGoalsSummary = ({ season_id, game }) => {

  const Player = ({event}) => {
    const player = useSeasonPlayer(season_id, event.player_id)
    const minute = (event.minute + event.minute_extra) + "'"
    return (
      <span className="-name">{player.firstname} {player.lastname} ({minute})</span>
    )
  }

  return (
    <div className="game-goals-summary">
      <div className="game-goals-summary-row -left">
        { game.home_goalers && game.home_goalers.map((event, index) => 
          <Player key={index} event={event} />
        ) }
      </div>
      <div className="game-goals-summary-row">
        { game.referee_name && <span className="icon-whistle" /> }
        <span>{game.referee_name}</span>
      </div>
      <div className="game-goals-summary-row -right">
        { game.away_goalers && game.away_goalers.map((event, index) => 
          <Player key={index} event={event} />
        ) }
      </div>
    </div>
  )
}

export default GameGoalsSummary

import { useSelector } from 'react-redux'

const useSeasonTeams = (season_id) => {
  const teams = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].teams)
  return teams || {}
}

export default useSeasonTeams
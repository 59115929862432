import { useSelector } from "react-redux"

const useSeasons = (competition_id) => {
  const seasons = useSelector(state => 
    state.organization.organization && 
    state.organization.organization.competitions &&
    state.organization.organization.competitions[competition_id] &&
    state.organization.organization.competitions[competition_id].seasons)

  return seasons || {}
}

export default useSeasons
import { useSelector } from "react-redux"

const useCompetition = (competition_id) => {
  const competition = useSelector(state => 
    state.organization.organization &&
    state.organization.organization.competitions && 
    state.organization.organization.competitions[competition_id])
  return competition || {}
}

export default useCompetition
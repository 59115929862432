import React, { Fragment, useEffect } from 'react'
import classNames from 'classnames'
import './style.css'

const addBodyClass = className => document.body.classList.add(className)
const removeBodyClass = className => document.body.classList.remove(className)

const Modal = ({ children, className = "-slide-right", isOpen, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      addBodyClass("modal-body-no-scroll")
    } else {
      removeBodyClass("modal-body-no-scroll")
    }
  }, [isOpen])

  return (
    <Fragment>
      <div className={classNames("modal-overlay", { "-hidden": !isOpen })} onClick={onRequestClose} />
      <div className={classNames("modal", className, { "-hidden": !isOpen })}>
        {children}
      </div>
    </Fragment>
  )
}

export default Modal
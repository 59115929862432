import colorContrast from 'color-contrast'

const clamp = (num, min, max) => {
  return num <= min ? min : num >= max ? max : num
}

const colorLightness = (color, amount) => {
  const usePound = (color[0] === "#")
  const col = usePound ? color.slice(1) : color
  const num = parseInt(col, 16)

  const r = clamp((num >> 16) + amount, 0, 255)
  const b = clamp(((num >> 8) & 0x00FF) + amount, 0, 255)
  const g = clamp((num & 0x0000FF) + amount, 0, 255)

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}

const selectBestColor = (baseColor, ...colors) => {
  const [, theColor] = colors.reduce(
    ([bestContrast, bestColor], color) => {
      const contrast = colorContrast(baseColor, color)
      return contrast > bestContrast ? [contrast, color] : [bestContrast, bestColor]
    },
    [0.0, baseColor]
  )
  return theColor
}

export { colorLightness, selectBestColor }
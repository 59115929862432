import React, { Fragment } from 'react'
import Select, { components } from 'react-select'
import classNames from 'classnames'
import Image from '../Image'
import { useIsMobile } from '../../hooks'
import './style.scss'

const colourStyles = {
  control: () => ({}),
  option: (styles) => {
    // eslint-disable-next-line
    const { color, backgroundColor, ":active": { backgroundColor: activeBackgroundColor }, ...rest } = styles
    return { ...rest }
  },
  valueContainer: styles => ({ ...styles, overflow: 'visible'})
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt="" src="/assets/rf-dropdown-arrows.svg" className="rf-dropdown-icon" />
    </components.DropdownIndicator>
  )
}

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className="-league-selector-value">{children}</div>
  </components.SingleValue>
)

const LeagueSelector = ({ competitions, onClick, className }) => {
  const isMobile = useIsMobile()

  const SeasonSelector = ({ competition_id, seasons }) => {
    const seasonValues = Object.keys(seasons).map(season_id => ({
      label: seasons[season_id].name,
      value: seasons[season_id].season_id
    }))

    return (
      <div className="-selectors">
        <Select
          className='-season-selector'
          styles={colourStyles}
          components={{ DropdownIndicator, SingleValue }}
          classNamePrefix="-season-selector"
          options={seasonValues}
          isClearable={false}
          isSearchable={false}
          value={seasonValues[0]}
          onChange={(option) => onClick(competition_id, option.value)}
        />
        <div className="-go" onClick={() => onClick(competition_id)}><span className="icon-right-open-mini" /></div>
      </div>
    )
  }

  const CompetitionCard = ({ competition }) => {
    return (
      <div className="-competition">
        {isMobile ?
          <Fragment>
            <div className="-details">
              <div className="-logo">
                <Image src={competition.logo} defaultImage={<span className="icon-competition" />} />
              </div>
              <h4 className="-title">{competition.name}</h4>
            </div>
            <SeasonSelector competition_id={competition.competition_id} seasons={competition.seasons} />
          </Fragment>
          :
          <Fragment>
            <div className="-logo">
              <Image src={competition.logo} defaultImage={<span className="icon-competition" />} />
            </div>
            <div className="-details">
              <h4 className="-title">{competition.name}</h4>
              <SeasonSelector competition_id={competition.competition_id} seasons={competition.seasons} />
            </div>
          </Fragment>
        }
      </div>
    )
  }

  return (
    <div className={classNames("league-selector", className)}>
      {Object.keys(competitions).map((id, index) => <CompetitionCard key={index} competition={competitions[id]} />)}
    </div>
  )
}

export default LeagueSelector
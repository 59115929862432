import React, { Fragment } from 'react'
import classNames from 'classnames'
import { useParams, Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { useIsTablet, useIsDesktop, useTeamsFanStats, useSeasonTeams } from '../../hooks'
import Image from '../Image'
import { ReactComponent as AwayWon } from './awayWon.svg'
import { ReactComponent as GoalsKeeper } from './goalsKeeper.svg'
import { ReactComponent as CardsFree } from './cardsFree.svg'
import { ReactComponent as LostByDefault } from './lostByDefault.svg'
import { ReactComponent as FansStatsPlayers } from './fansStatsPlayers.svg'
import './style.css'

const Tooltip = ({ id }) => {
  return (
    <ReactTooltip
      id={id}
      place='top'
      effect='solid'
      getContent={tip => <div>{tip}</div>}
    />
  )
}

const FANStatsTeamTable = ({ season_id, limit, onMore, dark = false }) => {
  const { competition_id } = useParams()
  const teamsFanStats = useTeamsFanStats(season_id)
  const teams = useSeasonTeams(season_id)
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const newData = limit ? teamsFanStats.slice(0, limit) : teamsFanStats
  const moreData = limit !== false && teamsFanStats.length > limit

  const TeamBadge = ({ team, stats, focus }) => {
    return (
      <div className='-name flex-1'>
        <span className={classNames('fans-team-table-light-text', {'-dark': dark})}>
          <span className="-number">{stats.place}</span>
          <span className='-dot icon-dot' />
        </span>
        <div>
          <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
            <Image className={classNames('fans-team-team-image', {'-lead': focus, '-dark': dark})} src={team.logo} defaultImage={<span className="icon-teams fans-team-team-image" />} />
          </Link>
        </div>
        <div className="fans-team-table-team">
          <span className={classNames('fans-team-table-team-name', 'rp-team-name', {'-lead': focus, '-dark': dark})}>{team.name}</span>
          <span className={classNames('fans-team-table-team-info', {'-lead': focus, '-dark': dark})}>
            <span className="-info">{ team.players.length } jugadores</span>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames("fans-team-table-container", { "-dark": dark })}>
      <div className={classNames("fans-team-table-header", { "-dark": dark })}>
        <div className="-main">{limit ? `Top ${limit} Equipos` : 'Equipos'}</div>
        { (isTablet || isDesktop) &&
          <Fragment>
            <div data-tip="Victoria de visitante (30 pts)" data-for="stats-team-tooltip"><AwayWon/></div>
            <div data-tip="Sin goles en contra (20 pts)" data-for="stats-team-tooltip"><GoalsKeeper/></div>
            <div data-tip="FairPlay (50 pts)" data-for="stats-team-tooltip"><CardsFree/></div>
            <div data-tip="Perder por default (-30 pts)" data-for="stats-team-tooltip"><LostByDefault/></div>
            <div data-tip="FANpoints del plantel (Acumulado)" data-for="stats-team-tooltip"><FansStatsPlayers/></div>
            <Tooltip id="stats-team-tooltip" />
          </Fragment>
        }
        <div className="-total">Puntos</div>
      </div>
      <div className={classNames("fans-team-table-table", { "-dark": dark })}>
        {newData.map((row, index) => {
          const team = (teams && teams[row.team_id]) || {}
          return (
            <div key={index} className={classNames("fans-team-table-row", { "-dark": dark })}>
              <TeamBadge focus={index === 0} team={team} stats={row} />
              { (isTablet || isDesktop) &&
                <Fragment>
                  <div className="-points">
                    <div>{row.away_won}</div>
                    <div className="-subpoints">({row.away_won_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.games_goals_keeper}</div>
                    <div className="-subpoints">({row.games_goals_keeper_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.cards_free}</div>
                    <div className="-subpoints">({row.cards_free_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.lost_by_default}</div>
                    <div className="-subpoints">({row.lost_by_default_points})</div>
                  </div>
                  <div className="-points">
                    <div>{row.team_players_fans_stats}</div>
                    <div className="-subpoints">({row.team_players_fans_stats_points})</div>
                  </div>
                </Fragment>
              }
              <div className="-final-points">{row.points}</div>
            </div>
          )
        })}
      </div>
      { moreData &&
        <div className={classNames("stats-table-see-more", { "-dark": dark })} onClick={onMore}>Ver la lista completa <span className="icon-right-small" /></div>
      }
    </div>
  )
}

export default FANStatsTeamTable

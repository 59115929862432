import React, { useEffect, useState, Fragment, useCallback } from 'react'
import Banner from '../../components/Banner'
import { get } from '../../helpers/requests'
import { withRouter } from 'react-router-dom'
import TagsSelector from '../../components/TagsSelector/TagsSelector'
import { dateMonths, dateFormat } from '../../helpers/date'
import useDebounce from '../../hooks/UseDebounce'
import { useIsMobile, useIsTablet, useIsDesktop } from '../../hooks'
import { ReactComponent as DocumentImage } from '../../icons/documents/document.svg'
import { ReactComponent as CompressedImage } from '../../icons/documents/compressed.svg'
import { ReactComponent as ImageImage } from '../../icons/documents/image.svg'
import { ReactComponent as PDFImage } from '../../icons/documents/pdf.svg'
import './style.scss'

const EXTENSION_ICONS = {
  'pdf': PDFImage,
  'docx': DocumentImage,
  'doc': DocumentImage,
  'xlsx': DocumentImage,
  'xls': DocumentImage,
  'rtf': DocumentImage,
  'txt': DocumentImage,
  'zip': CompressedImage,
  'tar': CompressedImage,
  'gz': CompressedImage,
  '7z': CompressedImage,
  'png': ImageImage,
  'jpg': ImageImage,
  'jpeg': ImageImage,
  'gif': ImageImage,
  'webp': ImageImage
}

const DEFAULT_EXTENSION = 'file'

const extractExtension = (filename) => {
  if (!filename) return DEFAULT_EXTENSION
  const parts = filename.split('.')
  const extension = parts[parts.length - 1].toLowerCase()
  return EXTENSION_ICONS[extension] ? extension : DEFAULT_EXTENSION
}

const DocumentsPage = () => {
  const [documents, setDocuments] = useState()
  const [filters, setFilters] = useState()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const refreshDocuments = useCallback(selectedFilters => {
    get('/files', selectedFilters)
      .then(setDocuments)
      .catch(error => {
        // TODO: handle this aswell
      })
  }, [setDocuments])

  const [selectedFilters, setSelectedFilters, flush] = useDebounce(refreshDocuments, 1500, { tag: '-', month: '-', page: 0, limit: 6 })

  const handleTagChange = useCallback(tag => setSelectedFilters({ ...selectedFilters, tag }), [setSelectedFilters, selectedFilters])
  const handleMonthChange = useCallback(month => setSelectedFilters({ ...selectedFilters, month }), [setSelectedFilters, selectedFilters])

  const Document = ({ document }) => {
    const extension = extractExtension(document.filename)
    const Icon = EXTENSION_ICONS[extension] || CompressedImage
    return (
      <div className="document">
        <a className="-icon" href={process.env.REACT_APP_API_V2_URL + '/download/' + document.file} target="_new"><Icon /></a>
        <div className="-content">
          <div className="-details">
            <div className="-extension">{extension}</div>
            <div className="-date">{dateFormat(document.created, 'd "de" mmmm, yyyy')}</div>
          </div>
          <div className="-title"><a href={process.env.REACT_APP_API_V2_URL + '/download/' + document.file} target="_new">{document.title}</a></div>
          <div className="-description">{document.description}</div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    get('/files/filters')
      .then(filters => ({ tags: filters.tags, dates: filters.dates.map(date => ({ name: dateMonths(date), value: date })) }))
      .then(setFilters)
      .then(() => flush())
      .catch(error => {
        // TODO: handle error feedback
      })
  }, [flush])

  return (
    <div className="documents-page">
      <Banner type="home" className="rf-content-spacing" />
      <div className="rf-content-spacing rp-home-header">
        <div className="-documents-browser">
          {(isMobile || isTablet) && <div className="-header">Documentos</div>}
          <div className="-filters">
            {filters &&
              <Fragment>
                <div>
                  <div className="-title">Filtra por categoría</div>
                  <TagsSelector tags={filters.tags} initialValue='-' allLabel='Todas' allValue='-' onChange={handleTagChange} />
                </div>
                <div>
                  <div className="-title">Filtra por mes</div>
                  <TagsSelector tags={filters.dates} initialValue='-' allLabel='Todos' allValue='-' onChange={handleMonthChange} />
                </div>
              </Fragment>
            }
          </div>
          <div className="-documents-previews">
            {(isDesktop) && <div className="-header">Documentos</div>}
            <div className="-grid">
              {documents && documents.map(document =>
                <Document key={document.file} document={document} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(DocumentsPage)
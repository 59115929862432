import * as React from 'react'

const StarsMobileHero = ({ backgroundA, backgroundB, backgroundC, colorA, colorB, colorC }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="414" viewBox="0 0 414 354">
      <defs>
        <linearGradient id="StarsMobileHero__b" x1="-122.67" y1="-120.61" x2="423.66" y2="369.79" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={backgroundA || "#1F5BFF"}/>
          <stop offset=".48" stopColor={backgroundB || "#041C5D"}/>
          <stop offset="1" stopColor={backgroundC || "#790461"}/>
        </linearGradient>
        <linearGradient id="StarsMobileHero__a" x1="-231.18" y1="-221.06" x2="474.31" y2="391.95" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={colorA || "#5CA2FF"}/>
          <stop offset=".48" stopColor={colorB || "#1248DB"}/>
          <stop offset="1" stopColor={colorC || "#DF0FB3"}/>
        </linearGradient>
        <linearGradient id="StarsMobileHero__c" x1="-234.56" y1="-217.16" x2="470.93" y2="395.84" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__d" x1="-232.04" y1="-220.07" x2="473.45" y2="392.93" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__e" x1="-233.06" y1="-218.89" x2="472.43" y2="394.11" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__f" x1="-111.86" y1="-358.38" x2="593.63" y2="254.62" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__g" x1="-229.93" y1="-222.5" x2="475.56" y2="390.51" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__h" x1="-221.48" y1="-232.22" x2="484.01" y2="380.79" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__i" x1="-220.79" y1="-233.01" x2="484.7" y2="379.99" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__j" x1="-364.62" y1="-67.49" x2="340.87" y2="545.52" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__k" x1="-236.28" y1="-215.18" x2="469.21" y2="397.82" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__l" x1="-303.45" y1="-137.88" x2="402.04" y2="475.12" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__m" x1="-324" y1="-114.23" x2="381.49" y2="498.77" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__n" x1="-335.95" y1="-100.47" x2="369.54" y2="512.53" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__o" x1="-348.01" y1="-86.59" x2="357.48" y2="526.41" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__p" x1="-221.92" y1="-231.71" x2="483.57" y2="381.3" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__q" x1="-192.09" y1="-266.04" x2="513.4" y2="346.96" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__r" x1="-123.65" y1="-344.81" x2="581.84" y2="268.19" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__s" x1="-96.74" y1="-375.78" x2="608.75" y2="237.22" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__t" x1="-362.93" y1="-69.43" x2="342.56" y2="543.57" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__u" x1="-234.87" y1="-216.81" x2="470.62" y2="396.19" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__v" x1="-186.72" y1="-272.22" x2="518.77" y2="340.78" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__w" x1="-310.85" y1="-129.36" x2="394.64" y2="483.64" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__x" x1="-188.69" y1="-269.96" x2="516.8" y2="343.05" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__y" x1="-185.76" y1="-273.33" x2="519.73" y2="339.68" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__z" x1="-182.74" y1="-276.81" x2="522.75" y2="336.2" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__A" x1="-296.35" y1="-146.05" x2="409.14" y2="466.96" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__B" x1="-222.31" y1="-231.26" x2="483.18" y2="381.74" href="#StarsMobileHero__a"/>
        <linearGradient id="StarsMobileHero__C" x1="-135.11" y1="-331.62" x2="570.38" y2="281.39" href="#StarsMobileHero__a"/>
      </defs>
      <path fill="url(#StarsMobileHero__b)" d="M414 350.08l-205.78-57.15L0 350.63V0h414v350.08z"/>
      <path d="M188.87 163.07l-10.35 36.47L209 178.1l30.69 21.44-10.35-36.47 29.17-23.25-36.93-1.1-12.48-35.85-12.47 35.85-36.93 1.1zm9.29-22.16L209 109.77l10.82 31.14 32.1 1-25.42 20.19 9.06 31.74L209 175.19l-26.6 18.65 9.06-31.74-25.41-20.22z" fill="url(#StarsMobileHero__a)"/>
      <path d="M164.53 171.55l-22.94 80.68L209 204.87l67.38 47.36-23.18-80.68 64.56-51.36-81.5-2.42-27.4-79.11-27.4 79.11-81.46 2.42zm18-52.21L209 43.15l26.34 76.19 78.56 2.3-62.21 49.43 22.11 77.65-64.8-45.67-64.92 45.67 22.11-77.65L104 121.64z" fill="url(#StarsMobileHero__c)"/>
      <path d="M181.81 165.26l-14 49.3L209 185.61l41.16 28.95-14-49.3 39.52-31.38-49.87-1.45L209 84.09l-16.82 48.34-49.86 1.45zm11.76-31L209 89.66l15.4 44.7 46 1.34-36.34 29L247 210.08l-38-26.65-38 26.65 12.94-45.43-36.34-29z" fill="url(#StarsMobileHero__d)"/>
      <path d="M173.58 167.92l-18.23 64.2L209 194.45l53.51 37.67-18.23-64.2 51.51-40.82-64.92-1.94L209 62.17l-21.88 63-64.91 1.94zM188.4 127L209 67.86l20.23 58.51.23.6 61 1.82-48.34 38.4 17.17 60.33-49.86-35.13-.43-.39-50.33 35.49 17.17-60.33-48.34-38.4z" fill="url(#StarsMobileHero__e)"/>
      <path fill="url(#StarsMobileHero__f)" d="M273.92 0h-.57l14.54 41.94.12.24L414 45.95v-.52l-125.64-3.74L273.92 0z"/>
      <path fill="url(#StarsMobileHero__g)" d="M197.1 159.68l-6.11 21.57 17.99-12.6 17.99 12.6-6.11-21.57 17.05-13.69-21.76-.6-7.29-21.08-7.29 21.08-21.76.6 17.29 13.69z"/>
      <path fill="url(#StarsMobileHero__h)" d="M129.95 42.3L144.61 0h-.67l-14.46 41.69L0 45.56v.56l129.72-3.82h.23z"/>
      <path fill="url(#StarsMobileHero__i)" d="M113.84 18.92L120.4 0h-.41l-6.38 18.43L0 21.84v.44l113.72-3.36h.12z"/>
      <path fill="url(#StarsMobileHero__j)" d="M28.82 214.32l-18.7-14.9L0 199.2v1.1l9.76.21 17.76 14.29L0 305.46v3.92l28.7-94.7.12-.36z"/>
      <path fill="url(#StarsMobileHero__k)" d="M316.7 306.14l-36.81-128.89 103.37-82.14-130.42-3.87L221.23 0h-1.05l31.84 91.84.12.37 128.3 3.87-101.37 80.56-.24.24 36.11 126.84-105.73-74.38-.23-.25-105.96 74.63 35.99-126.47.11-.37-101.6-80.8 128.3-3.87L197.78 0h-1.1L165 91.12 34.58 94.99l103.37 82.14-36.69 129.01 107.72-75.83 107.72 75.83z"/>
      <path fill="url(#StarsMobileHero__l)" d="M208.98 270.04l-53.11 37.4 1.91-.53 51.2-36.02 52.26 36.76 1.85.52-53.99-38.01-.12-.12z"/>
      <path fill="url(#StarsMobileHero__m)" d="M122.78 182.34L0 84.78v1.28l121.6 96.64-41.58 145.75 1.28-.35 41.36-145.4.12-.36z"/>
      <path fill="url(#StarsMobileHero__n)" d="M101.96 189.85L0 108.83v.88l101.14 80.5-40.93 143.73.78-.21 40.85-143.64.12-.24z"/>
      <path fill="url(#StarsMobileHero__o)" d="M81.03 197.11L0 132.69v.76l80.44 63.91-40.53 142.21.63-.18 40.37-142.03.12-.25z"/>
      <path fill="url(#StarsMobileHero__p)" d="M142.77 60.23L163.66 0h-.68L142.3 59.62 0 63.82v.63l142.53-4.22h.24z"/>
      <path fill="url(#StarsMobileHero__q)" d="M363.03 205.35v.12l40.34 141.66.52.14-40.39-141.8 50.5-40.12v-.53l-50.85 40.41-.12.12z"/>
      <path fill="url(#StarsMobileHero__r)" d="M254.91 0h-.79l20.71 59.98.12.25L414 64.34v-.69L275.54 59.5 254.91 0z"/>
      <path fill="url(#StarsMobileHero__s)" d="M304.47 18.43L298.08 0h-.37l6.52 18.8v.12L414 22.16v-.45l-109.53-3.28z"/>
      <path fill="url(#StarsMobileHero__t)" d="M55.04 205.35L0 161.6v.59l54.45 43.28-40.22 141.21.65-.18 40.16-141.03v-.12z"/>
      <path d="M68.09 106.87l83.5 66.38L122 277.43l87-61.3 87 61.3-29.87-104.18 83.5-66.38-105.37-3.15-35.4-102.24-35.4 102.24zM208.86 6l34.46 99.33 102.31 3L265 172.4l-.36.37 28.82 101.15-84.09-59.24-.35-.24-84.67 59.48L153 173.25l.12-.48-81-64.45 102.31-3z" fill="url(#StarsMobileHero__u)"/>
      <path fill="url(#StarsMobileHero__v)" d="M387.96 214.32L414 300.23v-3.93l-24.74-81.5 17.76-14.29 6.98-.15v-1.1l-7.34.16-18.7 14.9z"/>
      <path fill="url(#StarsMobileHero__w)" d="M208.98 246.78l-107.37 75.69 2.68-.74 104.69-73.62 105.83 74.42 2.88.8-108.48-76.31-.23-.24z"/>
      <path fill="url(#StarsMobileHero__x)" d="M336.93 197.11l40.63 142.85.65.18-40.7-142.78 76.49-60.8v-.74l-76.95 61.17-.12.12z"/>
      <path fill="url(#StarsMobileHero__y)" d="M316 189.85l41.12 144.43.76.21-41.18-144.4 97.3-77.36v-.82l-97.89 77.81-.11.13z"/>
      <path fill="url(#StarsMobileHero__z)" d="M295.18 182.21l41.67 146.44 1.25.35-41.74-146.3L414 89.09v-1.37l-118.58 94.25-.24.24z"/>
      <path fill="url(#StarsMobileHero__A)" d="M208.98 292.81l-.33.24 1 .27-.55-.38-.12-.13z"/>
      <path fill="url(#StarsMobileHero__B)" d="M182.71 0h-1.07L155 76.94 0 81.51v1.05l155.71-4.65 27-77.91z"/>
      <path fill="url(#StarsMobileHero__C)" d="M236.34 0h-1.12l26.8 77.43.11.36L414 82.32v-1.05l-151.04-4.45L236.34 0z"/>
    </svg>
    
  )
}

export default StarsMobileHero
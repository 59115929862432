import * as React from 'react'

const DefaultTabletHero = ({ backgroundA, backgroundB, backgroundC, colorA, colorB, colorC }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1024" viewBox="0 0 1024 344">
      <defs>
        <linearGradient id="DefaultTabletHero__a" x1="11.95" y1="504.92" x2="1031.52" y2="841.5" gradientTransform="translate(0 -498)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={backgroundA || "#1F5BFF"}/>
          <stop offset=".5" stopColor={backgroundB || "#041C5D"}/>
          <stop offset="1" stopColor={backgroundC || "#790461"}/>
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h1024v344H0z"/>
      <path fill="url(#DefaultTabletHero__a)" d="M0 0h1024v344H0z"/>
    </svg>
    
  )
}

export default DefaultTabletHero
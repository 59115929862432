import { ReactComponent as Ball } from './ball.svg'
import { ReactComponent as OwnGoal } from './ownGoal.svg'
import { ReactComponent as Goal } from './goal.svg'
import { ReactComponent as Red } from './red.svg'
import { ReactComponent as Yellow } from './yellow.svg'
import { ReactComponent as Hand } from './hand.svg'
import { ReactComponent as Sustitution } from './sustitution.svg'
import { ReactComponent as Injured } from './injured.svg'
import { ReactComponent as BestPlayer } from './bestPlayer.svg'

export default {
  Ball,
  OwnGoal,
  Goal,
  Red,
  Yellow,
  Hand,
  Sustitution,
  Injured,
  BestPlayer
}
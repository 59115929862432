import './style.css'

import React, { Fragment, useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

const InstagramCarousel = ({ instagram, token }) => {
  const [instagramImages, setInstagramImages] = useState([])
  const [reset, setReset] = useState(false)

  const { left } = useSpring({
    config: {
      duration: 10000 * instagramImages.length / 4
    },
    from: {
      left: 0
    },
    to: {
      left: instagramImages.length / 4 * -280
    },
    reset: reset,
    onRest: () => {
      setReset(true)
    },
    onStart: () => {
      setReset(false)
    }
  })

  useEffect(() => {
    if (token) {
      fetch('https://api.instagram.com/v1/users/self/media/recent/?access_token=' + token)
        .then(response => response.json())
        .then(jsonResponse => {
          if (jsonResponse.data) {
            const images = jsonResponse.data.map(item => item.images.low_resolution.url)
            if (images.length % 2 === 1) {
              setInstagramImages([...images, images[0], ...images, images[0]])
            } else {
              setInstagramImages([...images, ...images])
            }
          }
        })
        .catch(() => {
          // TODO: hide instagram???
        })
    }
  }, [token])

  return (
    <Fragment>
      <div className="instagram-carousel-main-container">
        <div className="instagram-carousel-moving-container">
          <animated.div style={{transform: left.interpolate(x => `translateX(${x}px)`)}}>
            <div className="instagram-carousel-container">
              {instagramImages.map((image, indexCol) =>
                <div key={indexCol} className="instagram-carousel-image-container">
                  <img alt='' src={image} className="instagram-carousel-image" />
                </div>
              )}
            </div>
          </animated.div>
        </div>
        <div className="instagram-follow-main-container"></div>
        <div
          className="instagram-follow-button-background"
          onClick={() => {
            window.open('https://www.instagram.com/' + instagram + '/')
          }}>
          <div className="instagram-follow-button">
            <div className="instagram-follow-button-icon">
              <div className="instagram-follow-button-icon-gradient" />
            </div>
            <span className="instagram-follow-button-text">{'Síguenos en Instagram'}</span>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default InstagramCarousel

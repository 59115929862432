import { useMediaQuery } from 'react-responsive'

const useIsMobile = () => {
  return useMediaQuery({ maxWidth: 599 })
}

const useIsTablet = () => {
  return useMediaQuery({ minWidth: 600, maxWidth: 1135 })
}

const useIsDesktop = () => {
  return useMediaQuery({ minWidth: 1136 })
}

export {
  useIsMobile,
  useIsTablet,
  useIsDesktop
}
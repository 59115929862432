import './style.css'

import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { animated, useSprings } from 'react-spring'

import _ from 'lodash'
import classNames from 'classnames'
import { useConfiguration } from '../../hooks'
import { useGesture } from 'react-use-gesture'
import { withRouter } from 'react-router-dom'

const GOOGLE_MAPS_KEY = "AIzaSyBiDAKp5TI9V8_rVwV-6roQ65yDvks93yw"

const TransformSlider = forwardRef((props, ref) => {
  const activeIndex = props.activeIndex || 0
  const sliderRef = useRef(null)
  const [isDragging, setDragging] = useState(false)
  const [slide, setSlide] = useState(0)

  const [springProps, setSpringProps] = useSprings(props.children.length, i => ({
    offset: i * window.innerWidth
  }))

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [xDelta], direction: [xDir], distance, cancel, first }) => {
        if (first && down) {
          setDragging(true)
          if (props.startDragSlider !== undefined) {
            props.startDragSlider()
          }
        }
        if (sliderRef && sliderRef.current && sliderRef.current.parentElement) {
          const { width } = sliderRef.current.parentElement.getBoundingClientRect()
          if (down && distance > width / 2) {
            if (cancel) {
              cancel()
            }
            setSlide(_.clamp(slide + (xDir > 0 ? -1 : 1), 0, props.children.length - 1))
          }
          setSpringProps(index => {
            const offset = (down ? xDelta : 0) / width + (index - slide)
            return { offset }
          })
        }
      },
      onClick: () => {
        if (isDragging) {
          setDragging(false)
          return
        }
      }
    },
    {
      drag: {
        delay: 200
      }
    }
  )

  useEffect(() => {
    setSpringProps(index => ({ offset: index - slide }))
  }, [slide, setSpringProps])

  useEffect(() => {
    setSlide(activeIndex % props.children.length)
  }, [activeIndex, props.children.length])

  const nextSlide = () => {
    if (slide === props.children.length - 1) {
      setSlide(0)
      return
    }
    setSlide(slide + 1)
  }

  const previousSlide = () => {
    if (slide === 0) {
      setSlide(props.children.length - 1)
      return
    }
    setSlide(slide - 1)
  }

  const goToSlider = index => {
    setSlide(index)
  }

  useImperativeHandle(ref, () => {
    return {
      nextSlide,
      previousSlide,
      goToSlider
    }
  })

  return (
    <div className="contact-view-slider-container" ref={sliderRef}>
      {springProps.map(({ offset }, i) => (
        <animated.div
          key={i}
          {...bind()}
          style={{
            transform: offset.interpolate(offsetX => `translate3d(${offsetX * 100}%, 0, 0)`)
          }}
          className={'contact-view-slide'}
        >
          {props.children[i]}
        </animated.div>
      ))}
    </div>
  )
})

const extractText = (rule, text) => {
  const result = rule.exec(text)
  return result && result[1]
}

const ContactPage = () => {
  const configuration = useConfiguration()
  const photoSlider = useRef()

  const contact_galery = useMemo(() => {
    return configuration.images &&
      configuration.images
        .filter(image => image.type === 'contact')
        .map(image => process.env.REACT_APP_IMAGES_BASE_URL + '/contact/' + image.image_key)
  }, [configuration])

  return (
    <div>
      <Fragment>
        <div className="rf-content-spacing">
          <div className="contact-view-main-container">
            <div className="contact-view-grid-container">
              <div className="contact-view-info-container">
                <span className="contact-view-greetings-text">Hola <span role="img" aria-label="" aria-labelledby="">👋🏽</span>, somos</span>
                <span className="contact-view-organization-name-text">{configuration.name}</span>
                <span className={classNames('contact-view-organization-text', '-desc-separation')} dangerouslySetInnerHTML={{ __html: configuration.contact && configuration.contact.services_description }} />
                <div className="contact-view-slider-main-container">
                  {contact_galery && contact_galery.length > 0 && (
                    <Fragment>
                      <TransformSlider ref={photoSlider}>
                        {contact_galery.map((source, index) => {
                          return (
                            <div key={index} className="contact-view-photo-container">
                              <img alt="" src={source} className="contact-view-photo-contact" />
                            </div>
                          )
                        })}
                      </TransformSlider>
                      <div className="contact-view-slider-buttons-container">
                        <div
                          className="contact-view-slider-button"
                          onClick={() => {
                            if (photoSlider.current !== undefined) {
                              photoSlider.current.previousSlide()
                            }
                          }}
                        >
                          <span className="icon-left-small contact-view-slider-button-arrow" />
                        </div>
                        <div
                          className="contact-view-slider-button"
                          onClick={() => {
                            if (photoSlider.current !== undefined) {
                              photoSlider.current.nextSlide()
                            }
                          }}
                        >
                          <span className="icon-right-small contact-view-slider-button-arrow" />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="contact-view-contact-data">
                <span className={classNames('contact-view-organization-title', '-contact-us-title-separation')}>
                  ¡Ponte en contacto con nosotros para obtener información detallada de nuestros servicios!
                </span>
                <div className="contact-view-contact-data-container">
                  {configuration.contact.contact_phone && configuration.contact.contact_phone !== '' && (
                    <a className="contact-view-contact-info-container" href={'tel:' + configuration.contact.contact_phone}>
                      <span className="icon-phone contact-view-contact-data-icons" />
                      <span className="contact-view-organization-text">{configuration.contact.contact_phone}</span>
                    </a>
                  )}
                  {configuration.contact.contact_email && configuration.contact.contact_email !== '' && (
                    <a className="contact-view-contact-info-container" href={'mailto:' + configuration.contact.contact_email}>
                      <span className="icon-mail-alt contact-view-contact-data-icons" />
                      <span className="contact-view-organization-text">{configuration.contact.contact_email}</span>
                    </a>
                  )}
                  {configuration.contact.facebook_url && configuration.contact.facebook_url !== '' && (
                    <a
                      className="contact-view-contact-info-container"
                      href={configuration.contact.facebook_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-facebook-squared contact-view-contact-data-icons" />
                      <span className="contact-view-organization-text">{'facebook.com/' + extractText(/facebook\.com\/(.*)$/, configuration.contact.facebook_url)}</span>
                    </a>
                  )}
                  {configuration.contact.twitter_url && configuration.contact.twitter_url !== '' && (
                    <a
                      className="contact-view-contact-info-container"
                      href={configuration.contact.twitter_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-twitter-squared contact-view-contact-data-icons" />
                      <span className="contact-view-organization-text">{'twitter.com/' + extractText(/twitter\.com\/(.*)$/, configuration.contact.twitter_url)}</span>
                    </a>
                  )}
                  {configuration.contact.instagram_url && configuration.contact.instagram_url !== '' && (
                    <a
                      className="contact-view-contact-info-container"
                      href={configuration.contact.instagram_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-instagram contact-view-contact-data-icons" />
                      <span className="contact-view-organization-text">{'instagram.com/' + extractText(/instagram\.com\/(.*)$/, configuration.contact.instagram_url)}</span>
                    </a>
                  )}
                  {configuration.contact.youtube_url && configuration.contact.youtube_url !== '' && (
                    <a
                      className="contact-view-contact-info-container"
                      href={configuration.contact.youtube_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-youtube contact-view-contact-data-icons" />
                      <span className="contact-view-organization-text">{'youtube.com/' + extractText(/youtube\.com\/(.*)$/, configuration.contact.youtube_url)}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <div className="contact-view-map-top-separator" />
      {configuration.contact && configuration.contact.google_maps_place_query && configuration.contact.google_maps_place_query !== '' &&
        <div className="contact-view-map-container">
          <iframe
            title="Ubicación"
            width="100%" height="100%" frameBorder="0"
            src={"https://www.google.com/maps/embed/v1/place?q=" + configuration.contact.google_maps_place_query + "&key=" + GOOGLE_MAPS_KEY} allowFullScreen></iframe>
        </div>
      }
    </div>
  )
}

export default withRouter(ContactPage)

import { useSelector } from 'react-redux'

const useTeamsFanStats = (season_id) => {
  const teams = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].teams_fans_stats)

    return (teams && Object.values(teams)) || []
}

export default useTeamsFanStats
import { useSelector } from 'react-redux'

const useLastUpdated = (season_id) => {
  const lastUpdated = useSelector(state =>
    state.organization.seasonStats &&
    state.organization.seasonStats[season_id] &&
    state.organization.seasonStats[season_id].last_updated)
  return lastUpdated
}

export default useLastUpdated
import React from 'react'
import classNames from 'classnames'
import { useParams, Link } from 'react-router-dom'
import Image from '../Image'
import { makeInitials } from '../../helpers/makeInitials'
import ball from '../../images/ball.svg'
import { ReactComponent as GoldenBoot } from './goldenBoot.svg'
import { useSeasonTeam, useSeasonPlayer } from '../../hooks'
import './style.css'

const PlayerBadge = ({ season_id, player_id, place, focus }) => {
  const { competition_id } = useParams()
  const player = useSeasonPlayer(season_id, player_id)
  const team = useSeasonTeam(season_id, player && player.teamId)

  return (
    <div className='flex-1' style={{ padding: '1rem 0' }}>
      <span className='goal-stats-table-light-text'>{place} <span className='icon-dot' /></span>
      <div className="goal-stats-images-container">
        <Link to={"/competicion/" + competition_id + "/temporada/" + season_id + "/equipo/" + team.team_id}>
          <Image className={classNames('goal-stats-player-image', {'-lead': focus})} src={player.avatar} defaultImage={<span className={classNames("goal-stats-player-initials", {"-lead": focus})}>{makeInitials(player.firstname, player.lastname)}</span>} />
          <Image className={classNames('goal-stats-team-image', {'-lead': focus})} src={team.logo} defaultImage={<span className="icon-teams goal-stats-team-image" />} />
        </Link>
      </div>
      <div className="goal-stats-table-player">
        <span className={classNames('goal-stats-table-player-name', 'rp-team-name', {'-lead': focus})}>{player.firstname} {player.lastname}</span>
        <span className={classNames('goal-stats-table-player-info', {'-lead': focus})}>
          <span className="rp-team-name">{ team.name }</span>
        </span>
      </div>
    </div>
  )
}

const GoalersTable = ({ newData, season_id }) => {
  return (
    <div className="goal-stats-table-table">
      {newData.map((row, index) => {
        return (
          <div key={index} className='flex goal-stats-table-row'>
            <PlayerBadge season_id={season_id} player_id={row.player_id} place={row.place} focus={index === 0} />
            <div className={classNames('rp-points', {'-lead': index === 0})}>
              <span className="-icon">{ index === 0 && <GoldenBoot /> }</span>
              {row.goals}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const GoalStatsTable = ({ season_id, data, onMore, dark }) => {
  const newData = data.slice(0, 5)
  const moreData = data.length > 5

  return (
    <div className='goal-stats-table-container'>
      <div className="goal-stats-table-header">
        <span className="goal-stats-table-header-title">Goleo Individual</span>
        <div className={classNames("goal-stats-table-header-image", { '-light': !dark })}>
          <img src={ball} alt='' />
          <div className="-overlay"></div>
        </div>
      </div>
      <div>
        <div className="goal-stats-table-table">
          <GoalersTable newData={newData} season_id={season_id} />
        </div>
        { moreData &&
          <div className='stats-table-see-more' onClick={onMore}>Ver la lista completa <span className="icon-right-small" /></div>
        }
      </div>
    </div>
  )
}

const GoalStatsFullTable = ({ season_id, data, onRequestClose }) => {
  return (
    <div className='full-stats-table-container'>
      <div className="full-stats-table-header">
        <span className="-title">Goleo Individual</span>
        <button className="modal-close-button" onClick={onRequestClose} >Cerrar <span className="icon-cancel" /></button>
      </div>
      <div>
        <div className='flex full-stats-table-row'>
          <div>Jugadores</div>
          <div>Goles</div>
        </div>
        <GoalersTable newData={data} season_id={season_id} />
      </div>
    </div>
  )
}

export { GoalStatsTable, GoalStatsFullTable }

import { useSelector } from 'react-redux'

const useSeasonTeam = (season_id, team_id) => {
  const seasonTeamData = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].teams && 
    state.organization.seasonStats[season_id].teams[team_id]);
  return seasonTeamData || {};
};

export default useSeasonTeam

import './style.css'

import Image from '../Image'
import React from 'react'
import { dateFormat } from '../../helpers/date'

const ScoreCardNumbers = ({ type = 'FINISHED', game }) => {
  const hasPenalties = (game.homePenalties > 0) || (game.awayPenalties > 0)
  return (
    <React.Fragment>
      <div className="fans-score-numbers">
        <div className={`fans-score-goals`}>{game.homeScore}</div>
        {hasPenalties && <div className={`fans-score-penalties`}>({game.homePenalties})</div>}
        <div className="fans-score-dots">-</div>
        {hasPenalties && <div className={`fans-score-penalties`}>({game.awayPenalties})</div>}
        <div className={`fans-score-goals`}>{game.awayScore}</div>
      </div>
      <div className="fans-score-label">{type === 'XDEFAULT' ? 'x Default' : 'Finalizado'}</div>
    </React.Fragment>
  );
};

const ScoreCardTime = ({ when }) => {
  const time = when && dateFormat(when, 'HH:MM')

  return (
    <React.Fragment>
      <div className="fans-score-numbers -time">
        <div className={`fans-score-smalllabel`}>Empieza</div>
        {when && <div className="fans-score-time">{time}</div>}
        {!when && <div className="fans-score-time -small">Hora por definir</div>}
      </div>
    </React.Fragment>
  );
};

const ScoreCard = ({ game, homeTeam, awayTeam }) => {
  const cardType = () => {
    if (game.status === "finished") {
      return <ScoreCardNumbers game={game} type={game.winner_by_default ? 'XDEFAULT' : 'FINISHED' } />;
    } else {
      return <ScoreCardTime when={game.date} />;
    }
  };

  return (
    <div className={'fans-score'}>
      <Image
        className="fans-score-icon -left"
        src={homeTeam.logo}
        defaultImage={<span className="icon-teams rp-team-badge -small" />}
      />
      {cardType()}
      <Image
        className="fans-score-icon -right"
        src={awayTeam.logo}
        defaultImage={<span className="icon-teams rp-team-badge -small" />}
      />
    </div>
  );
};

export default ScoreCard;

import './style.css'

import React, { Fragment } from 'react'
import { useIsDesktop, useIsMobile, useIsTablet, useSeasonTeam } from '../../hooks'

import Image from '../Image'
import ScoreCard from '../ScoreCard'
import classNames from 'classnames'
import { dateFormat } from '../../helpers/date'

const GamesResultsTable = ({ season_id, journey, onGameClick }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const sortedGames = (journey && journey.games && journey.games.sort((a, b) => {
    if (a.date === b.date) return 0
    if (!a.date) return 1
    if (!b.date) return -1
    return Date.parse(a.date) - Date.parse(b.date)
  })) || []

  const dates = sortedGames.reduce((accumulator, entry) => {
    if (entry.date) {
      const date = dateFormat(entry.date, 'dddd, d mmmm')
      if (!accumulator[date]) accumulator[date] = []
      accumulator[date].push(entry)
    } else {
      if (!accumulator['']) accumulator[''] = []
      accumulator[''].push(entry)
    }
    return accumulator
  }, {})

  const Entry = ({entry}) => {
    const homeTeam = useSeasonTeam(season_id, entry.home_team_id)
    const awayTeam = useSeasonTeam(season_id, entry.away_team_id)
    const time = entry.date && dateFormat(entry.date, 'HH:MM')
    const hasPenalties = entry.status === 'finished' && (entry.homePenalties > 0) || (entry.awayPenalties > 0)
    return (
      <div className="game-results-row" onClick={() => onGameClick(entry)}>
        { (isTablet || isDesktop) &&
          <Fragment>
            <div className="flex-1">
              { entry.field && <span className="icon-location-outline">{entry.field}</span> }
            </div>
            <div className="game-results-team-name rp-team-name flex-2 flex flex-justify-end">
              {homeTeam.name}
            </div>
            <div className="flex-2 flex-justify-center">
              <ScoreCard game={entry} homeTeam={homeTeam} awayTeam={awayTeam} />
            </div>
            <div className="game-results-team-name rp-team-name flex-2">
              {awayTeam.name}
            </div>
            <div className="flex-1 flex-justify-end">
              <span className="icon-right-open-mini" />
            </div>
          </Fragment>
        }
        { isMobile &&
          <Fragment>
            <div className="game-results-teams">
              <div className="game-results-team">
                <div className="game-results-team-image">
                  <Image className="fans-score-icon" src={homeTeam.logo} defaultImage={<span className="icon-teams rp-team-badge" />} />
                </div>
                <div className="game-results-team-name">{homeTeam.name}</div>
                <div className={classNames("game-results-team-score", {'-finished': entry.status === 'finished', '-pending-label': entry.status === 'pending'})}>
                  {entry.status === 'finished' ? <span className="-score">{hasPenalties && <span className="-penalties">({entry.homePenalties})</span>} {entry.homeScore}</span> : time && 'Empieza'}
                </div>
              </div>
              <div className="game-results-team">
                <div className="game-results-team-image">
                  <Image className="fans-score-icon" src={awayTeam.logo} defaultImage={<span className="icon-teams rp-team-badge" />} />
                </div>
                <div className="game-results-team-name">{awayTeam.name}</div>
                <div className={classNames("game-results-team-score", {'-finished': entry.status === 'finished', '-pending-value': entry.status === 'pending'})}>
                  {entry.status === 'finished' ? <span className="-score">{hasPenalties && <span className="-penalties">({entry.awayPenalties})</span>} {entry.awayScore}</span> : time}
                </div>
              </div>
            </div>
            <div className="flex-1 flex-justify-end">
              <span className="icon-right-open-mini" />
            </div>
          </Fragment>
        }
      </div>
    )
  }

  return (
    <div>
      { Object.keys(dates).map((date, index) => {
        return (
          <div key={index}>
            <div className="rp-table-title">{ date }</div>
            <div className="rp-table">
              { dates[date].map((entry, entryIndex) =>
                <Entry entry={entry} key={entryIndex} />
              ) }
            </div>
          </div>
        )
      }) }
    </div>
  )
}

export default GamesResultsTable

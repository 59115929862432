import React from 'react'
import classNames from 'classnames'
import './style.css'

const FansContent = ({ children, transparentBackground = false }) => {
  return (
    <div className={classNames("rf-content-spacing", { "-transparent": transparentBackground })}>
      <div className="rf-content-container">
        <div className="rf-content">
          <div className="rf-fans_content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default FansContent



const makeInitials = (firstName, lastName = null) => {
  if (!firstName) return ''
  const initials = lastName ? firstName.substring(0, 1) + lastName.substring(0, 1) : firstName.substring(0, 2)
  return initials.toUpperCase()
}

const makeInitialsFromName = (name) => {
  return makeInitials(...name.split(/[ ]+/).slice(0, 2))
}

export {
  makeInitials,
  makeInitialsFromName
}
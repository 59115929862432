import { useSelector } from 'react-redux'

const useTeamClassification = (season_id, team_id) => {
  const stats = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].classification_team_points &&
    state.organization.seasonStats[season_id].classification_team_points[team_id]) || {}
  return stats || {}
}

export default useTeamClassification

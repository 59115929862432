const organizationReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_ORGANIZATION": {
      return { ...state, organization: action.organization }
    }
    case "SET_GAME": {
      const gameEvents = state.gameEvents || {}
      return { ...state, gameEvents: { ...gameEvents, [action.game_id]: action.game } }
    }
    case "SET_SEASON_STATS": {
      const seasonStats = state.seasonStats || {}
      const newSeasonStats = { ...action.seasonStats, team_statistics: action.seasonStats.team_statistics }
      return { ...state, seasonStats: { ...seasonStats, [action.season_id]: newSeasonStats } }
    }
    case "SET_BANNERS": {
      return { ...state, banners: { ...action.banners, last_update: new Date().getTime() } }
    }
    default:
      return state
  }
}

export default organizationReducer
import { useSelector } from 'react-redux'

const usePlayerStatistics = (season_id, player_id) => {
  const player = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].player_fans_stats && 
    state.organization.seasonStats[season_id].player_fans_stats[player_id])
    
  return player || {}
}

export default usePlayerStatistics
import React, { useState, Fragment, useEffect } from 'react'
import { get } from '../../helpers/requests'
import './style.scss'
import News from '../News/News'
import { useIsMobile, useIsTablet } from '../../hooks'
import { Link } from 'react-router-dom'

const LatestNews = () => {
  const [news, setNews] = useState()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  useEffect(() => {
    get('/news/latest')
      .then(setNews)
      .catch(() => {
        // TODO: do proper feedback
      })
  }, [])

  const ANew = ({ aNew }) => {
    return <News
      id={aNew.site_news_id}
      title={aNew.title}
      content={aNew.content}
      coverImage={aNew.cover_image}
      tags={aNew.tags}
      created={aNew.created}
      preview
    />
  }

  return (
    <div className="latest-news">
      {news && news.length > 0 &&
        <Fragment>
          <div className="-title-wrapper">
            <div className="-title">Titulares</div>
            {(isMobile || isTablet) && <div className="-link"><Link to="/noticias">Ver todas las noticias <i className="icon-right-arrow"></i></Link></div>}
          </div>
          <div className="-layout">
            <div className="-main">
              <ANew aNew={news[0]} />
            </div>
            {news.length > 1 &&
              <div className="-side">
                {news[1] && <ANew aNew={news[1]} />}
                {news[2] && <ANew aNew={news[2]} />}
              </div>
            }
          </div>
        </Fragment>
      }
    </div>
  )
}

export default LatestNews
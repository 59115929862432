import { useSelector } from 'react-redux'

const usePlayersFansStats = (season_id) => {
  const players = useSelector(state => 
    state.organization.seasonStats && 
    state.organization.seasonStats[season_id] && 
    state.organization.seasonStats[season_id].player_fans_stats)
    
  return (players && Object.values(players)) || []
}

export default usePlayersFansStats
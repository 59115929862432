import React, { useLayoutEffect, useRef, useState } from 'react'
import Image from '../Image'
import './style.css'

const TeamsTable = ({ teams, onClick = () => {}}) => {
  const table = useRef()
  const [teamWidth, setTeamWidth] = useState(false)
  const teamList = Object.keys(teams)

  useLayoutEffect(() => {
    const width = table.current.getBoundingClientRect().width
    let smallestGap = width
    let bestColumnsCount = teamList.length
    for (let i = 1; i <= teamList.length; i++) {
      const columnWidth = width / i
      const rows = Math.ceil(teamList.length / i)
      const gapWidth = (rows * i - teamList.length) * columnWidth
      if (columnWidth >= 100 && columnWidth <= 250) {
        if (gapWidth < smallestGap) {
          smallestGap = gapWidth
          bestColumnsCount = i
        }
      }
    }
    setTeamWidth(width / bestColumnsCount)
  }, [teamList])

  return (
    <div ref={table} className="teams-table">
      {teamWidth && teamList.map((id, index) => {
        return (
          <div key={index} className="teams-table-team-wrapper" style={{width: teamWidth}}>
            <div className="teams-table-team" key={index} onClick={() => onClick(teams[id])}>
              <Image src={teams[id].logo} className="-team-icon" defaultImage={ <span className="icon-teams -team-icon" /> } />
              <div className="-team-name">{teams[id].name.toLowerCase()}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TeamsTable

import React, { useRef, useState, Fragment } from 'react'

const Image = ({ defaultImage, ...rest }) => {
  const invalidSource = !rest.src
  const [renderElement, setRenderElement] = useState(false)
  const image = useRef()

  const onError = () => {
    if (defaultImage) {
      if (React.isValidElement(defaultImage)) {
        setRenderElement(true)
      } else {
        image.current.src = defaultImage
      }
    }
  }

  return (
    <Fragment>
      { invalidSource || renderElement ? (React.isValidElement(defaultImage) ? defaultImage : <img ref={image} alt="" {...rest} src={defaultImage} onError={onError} />) : <img ref={image} alt="" {...rest} onError={onError} /> }
    </Fragment>
  )
}

export default Image